import { EmployeeLocation } from './employee-location.model';

export interface Employee {
  $id: string;
  EmployeeId: number;
  MerchantId: number;
  NameFirst: string;
  NameLast: string;
  AccessType: number;
  StatusType: number;
  LanguageType: number;
  EmployeeLocations: EmployeeLocation[];
}
