import { Component } from '@angular/core';
import { ChooseLoyaltyProgramFormComponent } from '@app/enroll/forms/choose-loyalty-program-form/choose-loyalty-program-form.component';

@Component({
  selector: 'app-merchant-choose-program-step',
  templateUrl: './merchant-choose-program-step.component.html',
  styleUrls: ['./merchant-choose-program-step.component.scss'],
  standalone: true,
  imports: [ChooseLoyaltyProgramFormComponent],
})
export class MerchantChooseProgramStepComponent {}
