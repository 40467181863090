import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestionState } from '@app/resources/ngrx/states';
import * as fromQuestionAdapter from '@app/resources/ngrx/adapters';
import { isNotNullOrUndefined } from '@ep/shared';
import { Dictionary } from '@ngrx/entity';
import { Question } from '@app/resources/services';

export const selectQuestionState = createFeatureSelector<QuestionState>('questions');
export const selectQuestionEntities = createSelector(selectQuestionState, fromQuestionAdapter.selectQuestionEntities);
export const selectQuestions = createSelector(selectQuestionEntities, (questionEntities) => {
  return Object.values(questionEntities).filter(isNotNullOrUndefined);
});

export const getSelectedQuestionId = (state: QuestionState) => state.selectedQuestionId;
export const selectCurrentQuestionId = createSelector(selectQuestionState, getSelectedQuestionId);

export const selectCurrentQuestion = createSelector(
  selectQuestionEntities,
  selectCurrentQuestionId,
  (questionEntities, questionId) => questionEntities[questionId]
);

export const selectQuestionById = createSelector(
  selectQuestionEntities,
  selectCurrentQuestionId,
  (questionEntities: Dictionary<Question>, questionId: number) => questionEntities[questionId]
);
