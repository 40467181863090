<ep-auth-container
  [imageSrc]="
    subdomainType === SubdomainTypes.EagleProcessing
      ? '../../assets/eagle-logo.png'
      : '../../assets/enroll-and-pay-logo.png'
  "
>
  <div *ngIf="!isLinkExpired">
    <div class="mb-4">
      <h5 class="fw-semibold">Set new password</h5>
    </div>

    <form [formGroup]="fgChangePassword" (keyup.enter)="onChangePasswordClicked()">
      <div class="form-group mb-4">
        <div class="flex flex-column gap-2">
          <label class="fw-semibold" htmlFor="NewPasswordA">New Password:</label>
          <p-password
            formControlName="NewPasswordA"
            [toggleMask]="true"
            [feedback]="false"
            [class.is-invalid]="
              fgChangePassword.get('NewPasswordA')?.invalid &&
              (fgChangePassword.get('NewPasswordA')?.dirty || fgChangePassword.get('NewPasswordA')?.touched)
            "
          ></p-password>
          <div class="invalid-feedback" *ngIf="fgChangePassword.get('NewPasswordA')?.errors?.['required']">
            New password is required
          </div>
          <div class="invalid-feedback" *ngIf="fgChangePassword.get('NewPasswordA')?.errors?.['minlength']">
            New password must be at least 8 characters
          </div>
        </div>
      </div>

      <div class="form-group mb-4">
        <div class="flex flex-column gap-2">
          <label class="fw-semibold" htmlFor="NewPasswordB">Reenter New Password:</label>
          <p-password
            formControlName="NewPasswordB"
            [toggleMask]="true"
            [feedback]="false"
            [class.is-invalid]="fgChangePassword.hasError('passwordsNotMatch')"
          ></p-password>
          <div class="invalid-feedback" *ngIf="fgChangePassword.hasError('passwordsNotMatch')">
            Passwords do not match
          </div>
        </div>
      </div>

      <div class="mb-4">
        <p-button label="CHANGE PASSWORD" [loading]="isLoading" (onClick)="onChangePasswordClicked()"></p-button>
      </div>
    </form>
  </div>

  <div *ngIf="isLinkExpired">
    <div class="mb-4">
      <h5 class="fw-semibold">The link is no longer valid</h5>
    </div>
    <div class="mb-2">
      <a class="mr-3" (click)="onReturnToLoginClicked()">RETURN TO LOGIN</a>
    </div>
    <div class="mb-2">
      <a (click)="onResetPasswordClicked()">RESET PASSWORD</a>
    </div>
  </div>
</ep-auth-container>
