import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from '@app/resources/ngrx/states/router.state';

// `router` is used as the default feature name. You can use the feature name
// of your choice by creating a feature selector and pass it to the `getRouterSelectors` function
// export const selectRouter = createFeatureSelector<RouterReducerState>('yourFeatureName');

export const selectRouter = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');
export const selectQueryParams = createSelector(selectRouter, (router) => router.state.queryParams);
export const selectParams = createSelector(selectRouter, (router) => router.state.params);
export const selectUrl = createSelector(selectRouter, (router) => router.state.url);
