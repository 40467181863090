<div class="submission-loader-wrapper">
  <div
    class="circle-loader"
    [ngClass]="{
      'load-complete': !isSubmitting,
      failed: isFailure,
      success: isSuccess
    }"
  >
    <svg
      *ngIf="isSuccess"
      [ngClass]="{ animateElement: true, success: true }"
      class="checkmark addClass"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      data-cy="enrollment-submission-success"
    >
      <path
        [ngClass]="{ animateElement: true, success: true }"
        class="checkmark__check addClass"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
    <svg
      *ngIf="isFailure"
      [ngClass]="{ animateElement: true, failed: true }"
      class="crossmark addClass"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      data-cy="enrollment-submission-failed"
    >
      <path
        [ngClass]="{ animateElement: true, failed: true }"
        class="cross__path cross__path--right addClass"
        fill="none"
        d="M16,16 l20,20"
      />
      <path
        [ngClass]="{ animateElement: true, failed: true }"
        class="cross__path cross__path--left addClass"
        fill="none"
        d="M16,36 l20,-20"
      />
    </svg>
  </div>
  <div *ngIf="isSuccess" class="submission-loader-message">
    <h3>Enrollment Successful</h3>
    <p>Check your email to finish setting up your merchant account.</p>
  </div>
  <div *ngIf="isFailure" class="submission-loader-message">
    <h3>Enrollment Failed</h3>
    <p>
      Something went wrong while trying to create your merchant account. Refresh the page and try submitting again in a
      few minutes.
    </p>
  </div>
</div>
