import { EmployeeState } from '@app/resources/ngrx/states';
import { employeeAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { EmployeeActions } from '@app/resources/ngrx/actions';
import * as fromEmployeeAdapter from '@app/resources/ngrx/adapters';
import _ from 'lodash';

export const initialEmployeeModuleState: EmployeeState = employeeAdapter.getInitialState({
  selectedEmployeeId: 0,
  selectId: 0,
});

export const _employeeReducer = createReducer(
  initialEmployeeModuleState,
  on(EmployeeActions.upsertEmployeeSuccess, (state, { payload }) => {
    return fromEmployeeAdapter.employeeAdapter.upsertOne(payload.employee, state);
  }),
  on(EmployeeActions.upsertEmployeesSuccess, (state, { payload }) => {
    return fromEmployeeAdapter.employeeAdapter.upsertMany(payload.employees, state);
  }),
  on(EmployeeActions.loadEmployeesSuccess, (state, { payload }) => {
    state = fromEmployeeAdapter.employeeAdapter.removeAll({ ...state });
    return fromEmployeeAdapter.employeeAdapter.addMany(payload.employees, state);
  }),
  on(EmployeeActions.employeeById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedEmployeeId: payload.employeeId,
    });
  })
);
