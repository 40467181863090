import { Component, inject, OnInit } from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { EnrollmentLPCashBackFormGroup } from '@app/enroll/services/enrollment-form.service';
import { NgIf } from '@angular/common';
import { WindowService } from '@app/resources/services/window.service';
import { DialogModule } from 'primeng/dialog';
import { PercentageControlComponent, SimpleTextControlComponent } from '@ep/shared';
import { ENROLLMENT_LOYALTY_PROGRAM_NAME_HELP } from '@app/enroll/utils/constants';

type CashBackControlHelpType = 'name' | 'percentage';

@Component({
  selector: 'app-loyalty-program-cashback-form',
  templateUrl: './loyalty-program-cashback-form.component.html',
  styleUrls: ['./loyalty-program-cashback-form.component.scss'],
  imports: [ReactiveFormsModule, NgIf, DialogModule, SimpleTextControlComponent, PercentageControlComponent],
  standalone: true,
})
export class LoyaltyProgramCashbackFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  private readonly windowService = inject(WindowService);
  protected cashBackForm: EnrollmentLPCashBackFormGroup | null = null;
  protected isDialogVisible = false;

  protected nameHelpText = ENROLLMENT_LOYALTY_PROGRAM_NAME_HELP;
  private helpIndex: CashBackControlHelpType = 'name';
  private helpDialog: { [index: string]: string } = {
    name: this.nameHelpText,
    percentage:
      "This is the percentage of the customer's purchase that will be given back to the customer to be spent on future transactions",
  };

  ngOnInit() {
    this.cashBackForm = this.controlContainer.control as EnrollmentLPCashBackFormGroup;
  }

  get isXSmallScreen() {
    return this.windowService.isXSmallScreen;
  }

  protected showHelp(controlHelp: CashBackControlHelpType) {
    this.helpIndex = controlHelp;
    this.isDialogVisible = true;
  }

  protected getHelpDialog() {
    return this.helpDialog[this.helpIndex];
  }
}
