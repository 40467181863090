import { Component, inject } from '@angular/core';
import { MerchantContactFormComponent } from '@app/enroll/forms/merchant-contact-form/merchant-contact-form.component';
import { EnrollmentFormService } from '@app/enroll/services/enrollment-form.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MerchantBusinessFormComponent } from '@app/enroll/forms/merchant-business-form/merchant-business-form.component';

@Component({
  selector: 'app-merchant-account-form',
  templateUrl: './merchant-account-form.component.html',
  styleUrls: ['./merchant-account-form.component.scss'],
  standalone: true,
  imports: [MerchantContactFormComponent, ReactiveFormsModule, MerchantBusinessFormComponent],
})
export class MerchantAccountFormComponent {
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  protected accountForm = this.enrollmentFormService.getAccountForm();
}
