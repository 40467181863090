import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardPanelComponent } from './components/dashboard-panel/dashboard-panel.component';
import { DashboardTodayPanelViewComponent } from './components/dashboard-today-panel-view/dashboard-today-panel-view.component';
import { DashboardWeekPanelViewComponent } from './components/dashboard-week-panel-view/dashboard-week-panel-view.component';
import { DashboardMonthPanelViewComponent } from './components/dashboard-month-panel-view/dashboard-month-panel-view.component';
import { DashboardCustomPanelViewComponent } from './components/dashboard-custom-panel-view/dashboard-custom-panel-view.component';
import { DashboardOverviewPanelComponent } from './components/dashboard-overview-panel/dashboard-overview-panel.component';
import { DashboardLoyaltyProgramPanelComponent } from './components/dashboard-loyalty-program-panel/dashboard-loyalty-program-panel.component';
import { DashboardApiService } from '@app/areas/dashboard/services/dashboard.api.service';
import { ViewCardComponent } from './components/view-card/view-card.component';
import { DashboardOverviewPanelDataComponent } from './components/dashboard-overview-panel-data/dashboard-overview-panel-data.component';
import { DashboardGraphComponent } from './components/dashboard-graph/dashboard-graph.component';
import { DashboardFormService } from '@app/areas/dashboard/services/dashboard.form.service';
import { DashboardCustomSearchComponent } from './components/dashboard-custom-search/dashboard-custom-search.component';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { SharedModule } from 'primeng/api';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    DashboardPanelComponent,
    DashboardTodayPanelViewComponent,
    DashboardWeekPanelViewComponent,
    DashboardMonthPanelViewComponent,
    DashboardCustomPanelViewComponent,
    DashboardOverviewPanelComponent,
    DashboardLoyaltyProgramPanelComponent,
    ViewCardComponent,
    DashboardOverviewPanelDataComponent,
    DashboardGraphComponent,
    DividerModule,
    PanelModule,
    DashboardCustomSearchComponent,
  ],
  providers: [DashboardApiService, DashboardFormService],
})
export class DashboardModule {}
