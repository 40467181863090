<div *ngIf="offerDurationForm" [formGroup]="offerDurationForm" data-cy="offer-duration-form">
  <div class="form-segment-header">
    <h5>Set Offer Duration</h5>
    <p class="segment-subheader">Schedule when your offer is valid</p>
  </div>
  <div style="margin-top: 10px" class="offer-calendar-form">
    <app-offer-date-time-control
      formControlName="StartDate"
      class="full-width"
      label="Start Offer Date"
      [minDate]="minDate"
      [useMinDate]="true"
      [shouldResetStartDay]="true"
      [shouldResetNow]="true"
      data-cy="offer-start-date-control"
    ></app-offer-date-time-control>
    <p
      *ngIf="offerDurationForm.controls.StartDate.dirty && offerDurationForm.controls.StartDate.errors"
      class="offer-form-error-message"
      data-cy="offer-form-start-date-error"
    >
      <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> (Please choose a time for now
      or later than now)
    </p>
  </div>
  <div style="margin-top: 25px" class="offer-calendar-form">
    <app-offer-date-time-control
      formControlName="EndDate"
      class="full-width"
      label="End Offer Date"
      [minDate]="minEndDate$ | async"
      [useMinDate]="true"
      [shouldResetEndDay]="true"
      data-cy="offer-end-date-control"
    ></app-offer-date-time-control>
    <p
      *ngIf="offerDurationForm.controls.EndDate.dirty && offerDurationForm.controls.EndDate.errors"
      class="offer-form-error-message"
      data-cy="offer-form-end-date-error"
    >
      <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> (Please choose a time later
      than start date)
    </p>
  </div>
</div>
