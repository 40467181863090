/**
 * Regular expression for strong password validation.
 *
 * - The password should contain at least 12 characters.
 * - The password should contain at least one uppercase letter.
 * - The password should contain at least one lowercase letter.
 * - The password should contain at least one digit.
 * - The password should contain at least one special character.
 *
 * @type {RegExp}
 */
export const StrongPasswordRegx: RegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/;
