import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { OfferFormComponent } from '@app/areas/offers/components/offer-form/offer-form.component';
import { OfferReviewComponent } from '@app/areas/offers/components/offer-review/offer-review.component';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OfferFormService } from '@app/areas/offers/services/offer.form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferApiService, SpecialOffer } from '@app/areas/offers/services/offer.api.service';
import { catchError, of, take, tap } from 'rxjs';
import { Offer } from '@app/resources/services';
import { isNotNullOrUndefined, LoadingSpinnerDirective } from '@ep/shared';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { TooltipModule } from 'primeng/tooltip';
import moment from 'moment-timezone';
import { CardModule } from 'primeng/card';
import { OfferStatusTypes } from '@shared/enums';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { getDayAbsDiff, isAfterNinePm, isDateWithinSMSWindow } from '@app/areas/offers/utils';
import { ErrorContactCustomerSupportComponent } from '@shared/pages/error-contact-customer-support/error-contact-customer-support.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-offer-form-card',
  templateUrl: './offer-form-card.component.html',
  styleUrls: ['./offer-form-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    OfferFormComponent,
    OfferReviewComponent,
    ButtonModule,
    LoadingSpinnerDirective,
    ConfirmDialogModule,
    TooltipModule,
    CardModule,
    AsyncPipe,
    ErrorContactCustomerSupportComponent,
  ],
})
export class OfferFormCardComponent implements OnInit {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly confirmationService = inject(ConfirmationService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly messageService = inject(MessageService);
  private readonly offerApiService = inject(OfferApiService);
  private readonly offerFormService = inject(OfferFormService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly timezoneService = inject(TimezoneService);

  protected offerForm = this.offerFormService.getOfferForm();
  protected isReview: boolean = false;
  protected isLoaded = false;
  protected isSaving = false;
  protected isFailure = false;
  protected isPublishing = false;
  protected cannotCreateOffer = false;

  private offerId: number | null = null;
  private offers: Offer[] = [];

  @Input() offer: Partial<Offer> | null = null;
  @Input() action: 'add' | 'edit' | 'view' = 'add';
  @Input() cancelFn: (...args: any) => void = () => {
    if (this.offerForm.dirty) {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'offer-confirm',
        accept: () => {
          this.applicationProfileService.applicationProfile$
            .pipe(
              filter(isNotNullOrUndefined),
              take(1),
              tap((profile) => this.router.navigateByUrl(`/${profile.Location.LocationId}/offers`))
            )
            .subscribe();
        },
      });
    } else {
      this.applicationProfileService.applicationProfile$
        .pipe(
          filter(isNotNullOrUndefined),
          take(1),
          tap((profile) => {
            this.router.navigateByUrl(`/${profile.Location.LocationId}/offers`);
          })
        )
        .subscribe();
    }
  };

  ngOnInit() {
    const location = this.applicationProfileService.getApplicationProfile().Location.Address;
    if (this.offer) {
      this.offerId = this.offer.offerId!;
      this.offerFormService.disableForm(false);
      this.offerFormService.initializeForm(location, this.offer);
      if (this.action === 'edit' && this.offer.statusType !== OfferStatusTypes.PendingNoText) {
        this.offerFormService.disableForm(true);
        this.action = 'view';
      }
    } else {
      this.offerFormService.disableForm(false);
      this.offerFormService.initializeForm(location);
    }

    this.offerApiService
      .getOffers(this.applicationProfileService.getApplicationProfile().Location.LocationId)
      .subscribe((offers) => {
        this.offers = offers;
      });

    this.isLoaded = true;
  }

  get isPublished() {
    return !!this.offer?.statusType && this.offer?.statusType !== OfferStatusTypes.PendingApproval;
  }

  protected saveOffer(): void {
    if (this.offerForm) {
      this.isPublishing = true;
      const locationId = this.applicationProfileService.getApplicationProfile().Location.LocationId;
      const merchantId = this.applicationProfileService.getApplicationProfile().Merchant.MerchantId;

      const { startDate, endDate, enqueueDate } = this.getOfferDurationAndSmsDates();
      let updatedEnqueueDate = enqueueDate.clone();

      const { isOfferNow, isOfferReminderNow, isNoTextSent, isTextSentAtNextWindow } =
        this.getConfirmationMessageConditions();
      if (isOfferReminderNow && isTextSentAtNextWindow) {
        if (
          enqueueDate.hour() <= 23 &&
          enqueueDate.minute() <= 59 &&
          (enqueueDate.hour() > 21 || (enqueueDate.hour() == 21 && enqueueDate.minute() > 0))
        ) {
          updatedEnqueueDate = enqueueDate.clone().add(1, 'day');
        }
        updatedEnqueueDate = updatedEnqueueDate.hour(8).minute(0).seconds(0).milliseconds(0);
      }

      const offer: SpecialOffer = {
        offerId: this.offerId,
        name: this.offerFormService.getOfferDetails(this.offerForm).controls.Name.value!,
        discountAmount:
          this.offerFormService.getOfferDetails(this.offerForm).controls.DiscountPercentage.value! / 10000,
        startDateTime: startDate
          .clone()
          .utc()
          .subtract(this.timezoneService.getLocationClientOffset(startDate), 'hours')
          .subtract(this.timezoneService.getDSTTime(startDate), 'hours')
          .format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
        endDateTime: endDate
          .clone()
          .utc()
          .subtract(this.timezoneService.getLocationClientOffset(endDate), 'hours')
          .subtract(this.timezoneService.getDSTTime(endDate), 'hours')
          .format('YYYY-MM-DD[T]HH:mm:ss.SSS'),
        sendTime: !isNoTextSent
          ? updatedEnqueueDate
              .subtract(this.timezoneService.getLocationClientOffset(updatedEnqueueDate), 'hours')
              .format('YYYY-MM-DD[T]HH:mm:ssZ')
          : '0001-01-01T00:00:00.0000000+00:00',
        statusType: isOfferNow ? OfferStatusTypes.Active : OfferStatusTypes.PendingNoText,
        smsBody: this.offerFormService.getOfferScheduledSMS(this.offerForm).controls.SMSBody.value,
      };

      if (this.action === 'add') {
        this.confirmNowOffer(() => this.createOffer(locationId, merchantId, offer));
      } else {
        this.confirmNowOffer(() => this.updateOffer(offer));
      }
    }
  }

  private doesOverlappingOfferExist(startDate: moment.Moment, endDate: moment.Moment) {
    return this.offers
      .filter(
        (offer) =>
          offer.offerId !== this.offerId &&
          offer.statusType !== OfferStatusTypes.Suspended &&
          offer.statusType !== OfferStatusTypes.Deleted
      )
      .some((offer, index) => {
        const start = this.timezoneService
          .moment(offer.startDateTime)
          .add(this.timezoneService.getLocationOffset(), 'hours');
        const end = this.timezoneService
          .moment(offer.endDateTime)
          .add(this.timezoneService.getLocationOffset(), 'hours');

        return (
          this.isDateWithinRange(start, end, startDate) ||
          this.isDateWithinRange(start, end, endDate) ||
          this.isDateWithinRange(startDate, endDate, start) ||
          this.isDateWithinRange(startDate, endDate, end)
        );
      });
  }

  private isDateWithinRange(start: moment.Moment, end: moment.Moment, date: moment.Moment) {
    return start.isSameOrBefore(date) && date.isBefore(end);
  }

  protected reviewOffer() {
    if (this.offerForm.valid) {
      const { startDate, endDate } = this.getOfferDurationAndSmsDates();

      if (this.doesOverlappingOfferExist(startDate, endDate)) {
        this.cannotCreateOffer = true;
        this.confirmationService.confirm({
          message: `You cannot create an offer with this timeframe as it overlaps with another offer's timeframe.`,
          header: 'Invalid Offer',
          icon: 'pi pi-exclamation-triangle',
          key: 'offer-confirm',
          reject: () => {
            this.cannotCreateOffer = false;
            this.isPublishing = false;
          },
        });
        return;
      }

      this.isReview = true;
    }
  }

  protected isReviewButtonDisabled() {
    return (this.action === 'edit' && this.offerForm.invalid) || (this.action === 'add' && !this.offerForm.valid);
  }

  private getOfferDurationAndSmsDates() {
    const startDate = this.timezoneService
      .moment(this.offerFormService.getOfferDuration(this.offerForm).controls.StartDate.value.date!)
      .startOf('minute')
      .seconds(0)
      .milliseconds(0);
    const endDate = this.timezoneService
      .moment(this.offerFormService.getOfferDuration(this.offerForm).controls.EndDate.value.date!)
      .startOf('minute')
      .seconds(0)
      .milliseconds(0);
    let enqueueDate = this.timezoneService
      .moment(this.offerFormService.getOfferScheduledSMS(this.offerForm).controls.SMSDate.value.date!)
      .startOf('minute')
      .seconds(0)
      .milliseconds(0);

    return { startDate, endDate, enqueueDate };
  }

  private confirmNowOffer(func: (...args: any) => void) {
    const { isOfferNow, isOfferReminderNow, isNoTextSent, isTextSentAtNextWindow } =
      this.getConfirmationMessageConditions();

    if (isOfferNow || isOfferReminderNow || isNoTextSent || isTextSentAtNextWindow) {
      let confirmationMessage = isOfferNow
        ? 'You are about to create an offer for right now'
        : 'You have chosen to send an offer reminder right now';
      if (isNoTextSent) {
        confirmationMessage +=
          ', but no text will be sent since the valid window to send texts has passed and the next valid time is after the offer ends';
      } else if (isTextSentAtNextWindow) {
        confirmationMessage +=
          ', but a text will not be sent out until 8 am since the current time is outside the valid window to send texts';
      } else if (isOfferNow && isOfferReminderNow) {
        confirmationMessage += ' and send out a text reminder right now';
      }

      this.confirmationService.confirm({
        message: `${confirmationMessage}. Do you want to proceed?`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'offer-confirm',
        accept: () => {
          func();
        },
        reject: () => {
          this.isPublishing = false;
        },
      });
    } else {
      func();
    }
  }

  private getConfirmationMessageConditions() {
    const now = this.timezoneService
      .moment()
      .add(this.timezoneService.getLocationClientOffset(), 'hours')
      .seconds(0)
      .milliseconds(0);
    const { startDate, endDate, enqueueDate } = this.getOfferDurationAndSmsDates();

    const isOfferNow = startDate.isSameOrBefore(now.toDate());
    const isOfferReminderNow = this.timezoneService
      .moment(this.offerFormService.getOfferScheduledSMS(this.offerForm).controls.SMSDate.value.date!)
      .isSameOrBefore(now.toDate());

    const isNoTextSent = this.getIsNoTextSent(isOfferReminderNow, enqueueDate, endDate);
    const isTextSentAtNextWindow = this.getIsTextSentAtNextWindow(isOfferReminderNow, enqueueDate, endDate);

    return {
      isOfferNow,
      isOfferReminderNow,
      isNoTextSent,
      isTextSentAtNextWindow,
    };
  }

  private getIsNoTextSent(isOfferReminderNow: boolean, enqueue: moment.Moment, end: moment.Moment) {
    const isReminderNowAndOutsideWindow = isOfferReminderNow && !isDateWithinSMSWindow(enqueue.toDate());
    const dayDiff = getDayAbsDiff(enqueue.toDate(), end.toDate());

    // Same day and both enqueue and end before 8am
    const isEnqueueAndEndBeforeSmsWindow =
      dayDiff === 0 && enqueue.toDate().getHours() < 8 && end.toDate().getHours() < 8;

    // Same day and both enqueue and end are after 9pm OR
    // offer end is next day but before the sms window
    const isEnqueueAndEndAfterSmsWindow =
      (dayDiff === 0 && isAfterNinePm(enqueue.toDate()) && isAfterNinePm(end.toDate())) ||
      (dayDiff === 1 && isAfterNinePm(enqueue.toDate()) && end.toDate().getHours() < 8);

    return isReminderNowAndOutsideWindow && (isEnqueueAndEndBeforeSmsWindow || isEnqueueAndEndAfterSmsWindow);
  }

  private getIsTextSentAtNextWindow(isOfferReminderNow: boolean, enqueue: moment.Moment, end: moment.Moment) {
    const isReminderNowAndOutsideWindow = isOfferReminderNow && !isDateWithinSMSWindow(enqueue.toDate());
    const dayDiff = getDayAbsDiff(enqueue.toDate(), end.toDate());

    // Same day and enqueue hour before 8am and end date is within the sms window OR
    // End day is next day and enqueue time is after sms window but end date is after 8 am OR
    // There is more than a day difference between the enqueue date and end date
    const isEnqueueOutsideWindowAndEndInWindow =
      (dayDiff === 0 && enqueue.toDate().getHours() < 8 && end.toDate().getHours() >= 8) ||
      (dayDiff === 1 &&
        (enqueue.toDate().getHours() < 8 || isAfterNinePm(enqueue.toDate())) &&
        end.toDate().getHours() >= 8) ||
      dayDiff > 1;
    return isReminderNowAndOutsideWindow && isEnqueueOutsideWindowAndEndInWindow;
  }

  private createOffer(locationId: number, merchantId: number, offer: SpecialOffer) {
    this.isSaving = true;
    this.offerApiService
      .createOffer(locationId, merchantId, offer)
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'ERROR: Create Offer',
            detail: 'Something went wrong while creating an offer',
          });
          return of({ error: true });
        })
      )
      .subscribe((result: any) => {
        this.isPublishing = false;
        this.isSaving = false;
        if (!result?.error) {
          this.messageService.add({
            severity: 'success',
            summary: 'SUCCESS: Create Offer',
            detail: 'Successfully created the offer',
          });
          this.router.navigate(['../'], { relativeTo: this.route });
        } else {
          this.isFailure = true;
          this.cdr.detectChanges();
        }
      });
  }

  private updateOffer(offer: SpecialOffer) {
    this.isSaving = true;
    this.offerApiService
      .updateOffer(offer)
      .pipe(
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'ERROR: Update Offer',
            detail: 'Something went wrong while updating the offer',
          });
          return of({ error: true });
        })
      )
      .subscribe((result: any) => {
        this.isPublishing = false;
        this.isSaving = false;
        if (!result?.error) {
          this.messageService.add({
            severity: 'success',
            summary: 'SUCCESS: Update Offer',
            detail: 'Successfully updated the offer',
          });
          this.router.navigate(['../'], { relativeTo: this.route });
        } else {
          this.isFailure = true;
          this.cdr.detectChanges();
        }
      });
  }

  cancel() {
    this.cancelFn();
  }
}
