<div *ngIf="scheduledSmsForm" [formGroup]="scheduledSmsForm">
  <div class="form-segment-header">
    <h5>Schedule Text Message</h5>
    <p class="segment-subheader">Specify when members receive text notifications</p>
    <p style="font-size: 12px !important">
      <i>(Text messages will only be sent out during 8 am - 9 pm)</i>
    </p>
  </div>
  <div style="margin-top: 10px">
    <app-offer-sms-reminder-control formControlName="Reminder" class="full-width"></app-offer-sms-reminder-control>
    <p
      *ngIf="scheduledSmsForm.controls.Reminder.dirty && scheduledSmsForm.controls.Reminder.errors"
      class="offer-reminder-control-error"
      data-cy="offer-reminder-control-error"
    >
      <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> No longer valid because send
      date is before the current time
    </p>
  </div>
  <div style="margin-top: 25px">
    <app-offer-date-time-control
      formControlName="SMSDate"
      class="full-width"
      label="Scheduled Text Date"
      [minDate]="minDate"
      [useMinDate]="true"
      [maxDate]="maxDate$ | async"
      data-cy="offer-sms-date-control"
    ></app-offer-date-time-control>
    <p
      *ngIf="scheduledSmsForm.controls.SMSDate.dirty && scheduledSmsForm.controls.SMSDate.errors"
      class="offer-form-error-message"
      data-cy="offer-form-sms-date-error"
    >
      <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon>
      {{ getScheduledSMSErrorMessage(scheduledSmsForm.controls.SMSDate.errors) }}
    </p>
  </div>
</div>
