import { Component, inject } from '@angular/core';
import { EnrollmentFormService } from '@app/enroll/services';
import { EnrollmentOfferTypesEnum } from '@shared/enums/enrollment-offer-types.enum';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { EnrollmentOfferInstantFormComponent } from '@app/enroll/forms/enrollment-offer-instant-form/enrollment-offer-instant-form.component';
import { EnrollmentOfferBonusFormComponent } from '@app/enroll/forms/enrollment-offer-bonus-form/enrollment-offer-bonus-form.component';

@Component({
  selector: 'app-configure-enrollment-offer-form',
  templateUrl: './configure-enrollment-offer-form.component.html',
  styleUrls: ['./configure-enrollment-offer-form.component.scss'],
  standalone: true,
  imports: [EnrollmentOfferInstantFormComponent, ReactiveFormsModule, NgIf, EnrollmentOfferBonusFormComponent],
})
export class ConfigureEnrollmentOfferFormComponent {
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  protected enrollmentOfferForm = this.enrollmentFormService.getEnrollmentOfferForm();

  get instantOfferForm() {
    return this.enrollmentOfferForm.controls.Instant;
  }

  get bonusOfferForm() {
    return this.enrollmentOfferForm.controls.Bonus;
  }

  get isInstantOffer() {
    return this.enrollmentOfferForm.controls.Selected.value === EnrollmentOfferTypesEnum.Instant;
  }
}
