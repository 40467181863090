import { CredentialsState } from '@app/resources/ngrx/states';
import { createReducer, on } from '@ngrx/store';
import { CredentialsActions } from '@app/resources/ngrx/actions';

export const initialCredentialsState: CredentialsState = { clientAccessId: null, jwt: null };

export const _credentialsReducer = createReducer(
  initialCredentialsState,
  on(CredentialsActions.initializeCredentials, (state, { payload }) => ({
    ...payload.credentials,
  })),
  on(CredentialsActions.updateCredentialsSuccess, (state, { payload }) => ({
    clientAccessId: payload.credentials?.clientAccessId ?? null,
    jwt: payload.credentials?.jwt ?? null,
  })),
  on(CredentialsActions.clearCredentialsSuccess, (state) => ({
    clientAccessId: null,
    jwt: null,
  }))
);
