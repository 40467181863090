import { Component, inject } from '@angular/core';
import { ReviewQuestionFormComponent } from '@app/enroll/forms/review-question-form/review-question-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnrollmentFormService } from '@app/enroll/services';

@Component({
  selector: 'app-enrollment-review-form',
  templateUrl: './enrollment-review-form.component.html',
  styleUrls: ['./enrollment-review-form.component.scss'],
  standalone: true,
  imports: [ReviewQuestionFormComponent, FormsModule, ReactiveFormsModule],
})
export class EnrollmentReviewFormComponent {
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  protected reviewForm = this.enrollmentFormService.getReviewQuestionsForm();
}
