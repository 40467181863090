import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from '@app/resources/ngrx/states';

export const selectAuthenticationState = createFeatureSelector<AuthenticationState>('authentication');
export const selectLoginStatus = createSelector(selectAuthenticationState, (state) => state.loggedIn);
export const selectShowTermsAndConditions = createSelector(
  selectAuthenticationState,
  (state) => state.showTermsAndConditions
);

export const selectLoginError = createSelector(selectAuthenticationState, (state) => state.error);
