<div class="submission-loader-wrapper" data-cy="enrollment-complete">
  <div class="circle-loader">
    <svg class="checkmark addClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <path class="checkmark__check addClass" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
  <div class="submission-loader-message">
    <h3>Enrollment Completed</h3>
    <p>{{ 'Enrollment already completed.' }} <a [href]="redirectLink">Click here to go to login.</a></p>
  </div>
</div>
