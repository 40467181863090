import { AccessType } from '@shared/enums';
import { Resources, Credentials } from '@shared/models';
import { inject, Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { EmployeeAccessTypes } from '@shared/enums';
import { FullAdmin, LocationAdmin } from '@shared/utils';
import { Store } from '@ngrx/store';
import { CredentialsActions } from '@app/resources/ngrx/actions';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private readonly store = inject(Store);
  private _credentials: Credentials | null = null;
  private accessTypes: AccessType[] = [];

  constructor() {
    const savedCredentials =
      sessionStorage.getItem(Resources.CredentialsKey) || localStorage.getItem(Resources.CredentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      if (this._credentials) {
        this.setAccessTypesFromJwt(this._credentials.jwt);
        this.store.dispatch(CredentialsActions.initializeCredentials({ payload: { credentials: this._credentials } }));
      }
    }
  }
  get credentials(): Credentials | null {
    return this._credentials;
  }

  clearCredentials() {
    this._credentials = null;
    this.accessTypes = [];
    const savedCredentials = localStorage.getItem(Resources.CredentialsKey);
    if (savedCredentials) {
      const credentials: Credentials = JSON.parse(savedCredentials);

      credentials.jwt = null;
      const updatedCredentialsJSON = JSON.stringify(credentials);

      localStorage.setItem(Resources.CredentialsKey, updatedCredentialsJSON);
    }
  }

  isAuthenticated(): boolean {
    return !!this.credentials?.jwt && !this.isTokenExpired(this.credentials.jwt);
  }

  hasAccessAny(accessTypes: AccessType[]): boolean {
    return this.accessTypes.some((accessType) => accessTypes.includes(accessType));
  }

  hasAccess(jwt: string | null | undefined, accessType: EmployeeAccessTypes): boolean {
    if (!jwt) return false;
    const token = this.getDecodedAccessToken(jwt);
    if (token === null) return false;
    const employeeAccessType = this.getEmployeeAccessTypesFromToken(token);
    if (employeeAccessType === null) return false;
    return (employeeAccessType & accessType) === accessType;
  }

  setCredentials(credentials?: Credentials | null): void {
    this._credentials = credentials || null;

    if (credentials && credentials.jwt) {
      localStorage.setItem(Resources.CredentialsKey, JSON.stringify(credentials));
      this.setAccessTypesFromJwt(credentials.jwt);
    }
  }

  public isAccessDowngrade(oldJwt: string, newJwt: string) {
    return this.checkIfJwtIsDowngraded(oldJwt, newJwt);
  }

  private checkIfJwtIsDowngraded(oldJwt: string, newJwt: string): boolean {
    if (this.hasAccess(newJwt, FullAdmin)) return false;

    return (
      (this.hasAccess(oldJwt, FullAdmin) && !this.hasAccess(newJwt, FullAdmin)) ||
      (this.hasAccess(oldJwt, LocationAdmin) && !this.hasAccess(newJwt, LocationAdmin))
    );
  }

  private isTokenExpired(token: string): boolean {
    const decoded: any = this.getDecodedAccessToken(token);
    if (!decoded.exp) {
      return true; // If there's no expiry information, assume it's expired.
    }

    const expiryDateInMs = decoded.exp * 1000;
    const currentDateInMs = Date.now();

    return currentDateInMs >= expiryDateInMs;
  }

  private setAccessTypesFromJwt(jwt: string | null): void {
    const tokenInfo = this.getDecodedAccessToken(jwt);
    if (!tokenInfo) {
      return;
    }

    const accessTypes: AccessType[] = this.getAccessTypesFromToken(tokenInfo);
    this.setAccessTypes(accessTypes);
  }

  private setAccessTypes(accessTypes: AccessType[]): void {
    this.accessTypes = accessTypes;
  }

  private getAccessTypesFromToken(token: any): AccessType[] {
    return token.AccessType.split(',')
      .map((accessType: string) => accessType.trim())
      .map((accessType: string) => this.mapToAccessType(accessType));
  }

  private getEmployeeAccessTypesFromToken(token: any): EmployeeAccessTypes | null {
    const accessTypes: AccessType[] = this.getAccessTypesFromToken(token);
    let employeeAccessType: EmployeeAccessTypes | null = null;

    accessTypes.forEach((accessType) => {
      if (employeeAccessType === null) employeeAccessType = this.mapToEmployeeAccessType(accessType);
      else employeeAccessType |= this.mapToEmployeeAccessType(accessType);
    });
    return employeeAccessType;
  }

  private getDecodedAccessToken(token: string | null): any {
    try {
      if (!token) {
        return null;
      }
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private mapToAccessType(accessType: string): AccessType {
    switch (accessType) {
      case 'DeviceAdmin':
        return AccessType.DeviceAdmin;
      case 'EmployeeAdmin':
        return AccessType.EmployeeAdmin;
      case 'LocationAdmin':
        return AccessType.LocationAdmin;
      case 'OfferAdmin':
        return AccessType.OfferAdmin;
      case 'ConsumerAccess':
        return AccessType.ConsumerAccess;
      case 'ConsumerReward':
        return AccessType.ConsumerReward;
      case 'SaleOverview':
        return AccessType.SaleOverview;
      case 'OrderAdmin':
        return AccessType.OrderAdmin;
      case 'ProductAdmin':
        return AccessType.ProductAdmin;
      case 'LoyaltyProgramAdmin':
        return AccessType.LoyaltyProgramAdmin;
      case 'QuestionAdmin':
        return AccessType.QuestionAdmin;
      case 'BillingAdmin':
        return AccessType.BillingAdmin;
      case 'SendReceiveMessages':
        return AccessType.SendReceiveMessages;
      case 'TabletopAdmin':
        return AccessType.TabletopAdmin;
      case 'ReportAdmin':
        return AccessType.ReportAdmin;
      case 'PayAtTableFull':
        return AccessType.PayAtTableFull;
      case 'PayAtTablePartial':
        return AccessType.PayAtTablePartial;
      default:
        throw new Error(`Unrecognized access type: ${accessType}`);
    }
  }

  private mapToEmployeeAccessType(accessType: string): EmployeeAccessTypes {
    switch (accessType) {
      case 'DeviceAdmin':
        return EmployeeAccessTypes.DeviceAdmin;
      case 'EmployeeAdmin':
        return EmployeeAccessTypes.EmployeeAdmin;
      case 'LocationAdmin':
        return EmployeeAccessTypes.LocationAdmin;
      case 'OfferAdmin':
        return EmployeeAccessTypes.OfferAdmin;
      case 'ConsumerAccess':
        return EmployeeAccessTypes.ConsumerAccess;
      case 'ConsumerReward':
        return EmployeeAccessTypes.ConsumerReward;
      case 'SaleOverview':
        return EmployeeAccessTypes.SaleOverview;
      case 'OrderAdmin':
        return EmployeeAccessTypes.OrderAdmin;
      case 'ProductAdmin':
        return EmployeeAccessTypes.ProductAdmin;
      case 'LoyaltyProgramAdmin':
        return EmployeeAccessTypes.LoyaltyProgramAdmin;
      case 'QuestionAdmin':
        return EmployeeAccessTypes.QuestionAdmin;
      case 'BillingAdmin':
        return EmployeeAccessTypes.BillingAdmin;
      case 'SendReceiveMessages':
        return EmployeeAccessTypes.SendReceiveMessages;
      case 'TabletopAdmin':
        return EmployeeAccessTypes.TabletopAdmin;
      case 'ReportAdmin':
        return EmployeeAccessTypes.ReportAdmin;
      case 'PayAtTableFull':
        return EmployeeAccessTypes.PayAtTableFull;
      case 'PayAtTablePartial':
        return EmployeeAccessTypes.PayAtTablePartial;
      default:
        throw new Error(`Unrecognized access type: ${accessType}`);
    }
  }
}
