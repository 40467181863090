import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { SharedModule } from '@app/@shared';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ConfigurationService } from '@ep/shared';
import assetCacheBusting from '../../../../asset-cache-busting.json';
import { getEnvironment } from '@shared/utils';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    ButtonModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
    DropdownModule,
    AdminComponent,
    AdminRoutingModule,
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configurationService: ConfigurationService) => () =>
        configurationService.load(
          getEnvironment(),
          assetCacheBusting[getEnvironment() as keyof typeof assetCacheBusting]
        ),
      deps: [ConfigurationService],
      multi: true,
    },
  ],
})
export class AdminModule {}
