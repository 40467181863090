<div *ngIf="pointsForm">
  <form [formGroup]="pointsForm" class="points-form">
    <div style="width: 100%">
      <div class="points-form-row">
        <ep-simple-text-control
          formControlName="Name"
          label="Program Name"
          data-cy="loyalty-program-points-name"
          class="points-control-width"
        ></ep-simple-text-control>
        <p *ngIf="!isXSmallScreen; else infoName" class="points-control-info-width">
          <i>{{ nameHelpText }}</i>
        </p>
        <ng-template #infoName>
          <i class="pi pi-info-circle help-icon" (click)="showHelp('name')" data-cy="points-name-help"></i>
        </ng-template>
      </div>
    </div>
    <div class="points-form-row">
      <ep-price-control
        formControlName="QualifierAmount"
        label="For Every"
        data-cy="loyalty-program-points-qualifier"
        class="points-control-width"
      ></ep-price-control>
      <p *ngIf="!isXSmallScreen; else infoQualifierAmount" class="points-control-info-width">
        <i>
          This is the spend interval amount that is used to calculate the points earned. (e.g., for every $10 spent,
          receive 1 point)
        </i>
      </p>
      <ng-template #infoQualifierAmount>
        <i
          class="pi pi-info-circle help-icon"
          (click)="showHelp('qualifier')"
          data-cy="points-qualifier-amount-help"
        ></i>
      </ng-template>
    </div>
    <div class="points-form-row">
      <ep-positive-integer-control
        formControlName="EarnPoints"
        label="Earn Points"
        data-cy="loyalty-program-points-earned"
        class="points-control-width"
      ></ep-positive-integer-control>
      <p *ngIf="!isXSmallScreen; else infoEarnPoints" class="points-control-info-width">
        <i> This is the number of points that the customer earns when they meet spend interval "For Every" </i>
      </p>
      <ng-template #infoEarnPoints>
        <i class="pi pi-info-circle help-icon" (click)="showHelp('earn')" data-cy="points-earned-points-help"></i>
      </ng-template>
    </div>
    <div class="points-form-row">
      <ep-positive-integer-control
        formControlName="RewardGoal"
        label="Points Goal"
        data-cy="loyalty-program-points-reward-goal"
        class="points-control-width"
      ></ep-positive-integer-control>
      <p *ngIf="!isXSmallScreen; else infoRewardGoal" class="points-control-info-width">
        <i> This is the points threshold required for an enrolled member to redeem their reward </i>
      </p>
      <ng-template #infoRewardGoal>
        <i class="pi pi-info-circle help-icon" (click)="showHelp('goal')" data-cy="points-reward-goal-help"></i>
      </ng-template>
    </div>
    <div class="points-form-row">
      <ep-price-control
        formControlName="RewardAmount"
        label="Reward Amount"
        data-cy="loyalty-program-points-reward-amount"
        class="points-control-width"
      ></ep-price-control>
      <p *ngIf="!isXSmallScreen; else infoRewardAmount" class="points-control-info-width">
        <i> This is the amount that is rewarded after the customer has reached the points goal </i>
      </p>
      <ng-template #infoRewardAmount>
        <i class="pi pi-info-circle help-icon" (click)="showHelp('reward')" data-cy="points-reward-amount-help"></i>
      </ng-template>
    </div>
  </form>
  <p-dialog
    [(visible)]="isDialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ 'max-width': '400px' }"
    data-cy="points-form-dialog"
  >
    <p class="m-0 points-form-dialog-content" data-cy="points-help-dialog-content">
      <i>{{ getHelpDialog() }}</i>
    </p>
  </p-dialog>
</div>
