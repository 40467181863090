import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ChangePassword } from '@app/@shared/models/change-password.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { IframeManagerService } from '@app/@shared/services/iframe-manager.service';
import { AuthenticationService } from '@app/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  fgChangePassword!: FormGroup;
  applicationProfile!: ApplicationProfile;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private applicationProfileService: ApplicationProfileService,
    private messageService: MessageService,
    private iframeManagerService: IframeManagerService
  ) {}

  ngOnInit() {
    this.iframeManagerService.setShouldShowIframe(false);

    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
      });

    this.createForm();
  }

  onChangePasswordClicked(): void {
    this.fgChangePassword.markAllAsTouched();
    if (this.fgChangePassword.valid) {
      this.isLoading = true;

      const changePassword: ChangePassword = {
        ...this.fgChangePassword.value,
        $id: this.applicationProfile.Employee.$id,
        employeeId: this.applicationProfile.Employee.EmployeeId,
      };

      this.authenticationService
        .changePassword(changePassword)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.fgChangePassword.reset();
          this.fgChangePassword.updateValueAndValidity();
          this.messageService.add({ severity: 'success', summary: 'Password Change Successful!' });
          this.isLoading = false;
        });
    }
  }

  passwordsMatchValidator(control: AbstractControl): { [key: string]: any } | null {
    const newPasswordA = control.get('NewPasswordA');
    const newPasswordB = control.get('NewPasswordB');

    if (newPasswordA && newPasswordB && newPasswordA.value !== newPasswordB.value) {
      return { passwordsNotMatch: true };
    }

    return null;
  }

  private createForm() {
    this.fgChangePassword = this.formBuilder.group(
      {
        OldPassword: new FormControl('', Validators.required),
        NewPasswordA: new FormControl('', [Validators.required, Validators.minLength(8)]),
        NewPasswordB: new FormControl('', Validators.required),
      },
      { validators: this.passwordsMatchValidator }
    );
  }
}
