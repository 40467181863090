import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EnrollComponent } from './enroll.component';
import { EnrollmentCompleteComponent } from '@app/enroll/components/enrollment-complete/enrollment-complete.component';

const routes: Routes = [
  {
    path: 'enroll/:enrollId',
    component: EnrollComponent,
    data: { title: 'Enrollment' },
  },
  {
    path: 'enroll/:enrollId/complete',
    component: EnrollmentCompleteComponent,
    data: { title: 'Enrollment Complete' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class EnrollRoutingModule {}
