<div *appLoading="!isLoaded || isSaving" class="offer-form-wrapper">
  <div *ngIf="!isFailure; else contactCustomerSupport" class="offer-form-card-wrapper">
    <p-card class="offer-form-card">
      <ng-template pTemplate="header">
        <div>
          <p-button
            (onClick)="cancel()"
            id="offer-exit-button"
            icon="pi pi-times"
            data-cy="cancel-offer-form-button"
          ></p-button>
        </div>
        <div class="offer-form-card-header">
          <div class="p-card-title full-width">{{ isReview ? 'Review Form' : 'Offer Form' }}</div>
        </div>
      </ng-template>
      <app-offer-form *ngIf="!isReview; else reviewOfferCard" [action]="action"></app-offer-form>
      <ng-template #reviewOfferCard>
        <app-offer-review></app-offer-review>
      </ng-template>
      <div class="action-buttons" *ngIf="action !== 'view'">
        <div *ngIf="isReview" class="review-buttons">
          <p-button
            *ngIf="isReview"
            label="Back"
            (click)="isReview = false"
            [disabled]="isPublishing"
            data-cy="go-back-offer-button"
          ></p-button>
          <div>
            <p-button
              [disabled]="isPublishing"
              [label]="isPublished ? 'Re-publish' : 'Publish'"
              (onClick)="saveOffer()"
              data-cy="publish-offer-button"
            ></p-button>
          </div>
        </div>
        <div *ngIf="!isReview" class="non-review-buttons">
          <p-button
            label="Review"
            [disabled]="isReviewButtonDisabled()"
            pTooltip="Please make sure the form is updated and valid"
            tooltipEvent="hover|focus"
            [tooltipDisabled]="!offerForm.invalid"
            data-cy="review-offer-button"
            (onClick)="reviewOffer()"
          ></p-button>
        </div>
      </div>
    </p-card>
    <p-confirmDialog key="offer-confirm" [style]="{ width: '30vw' }" #cd data-cy="offer-confirmation-dialog">
      <ng-template pTemplate="footer">
        <div *ngIf="!cannotCreateOffer" class="offer-confirmation-buttons" data-cy="offer-confirmation-dialog-buttons">
          <button
            pButton
            label="No"
            (click)="cd.reject()"
            class="p-button-outlined w-8rem"
            data-cy="offer-form-confirmation-no"
          ></button>
          <button
            pButton
            label="Yes"
            (click)="cd.accept()"
            class="p-button-outlined w-8rem"
            data-cy="offer-form-confirmation-yes"
          ></button>
        </div>
      </ng-template>
    </p-confirmDialog>
  </div>
  <ng-template #contactCustomerSupport>
    <app-error-contact-customer-support></app-error-contact-customer-support>
  </ng-template>
</div>
