import { Component } from '@angular/core';
import { ConfigureEnrollmentOfferFormComponent } from '@app/enroll/forms';

@Component({
  selector: 'app-merchant-configure-offer-step',
  templateUrl: './merchant-configure-offer-step.component.html',
  styleUrls: ['./merchant-configure-offer-step.component.scss'],
  standalone: true,
  imports: [ConfigureEnrollmentOfferFormComponent],
})
export class MerchantConfigureOfferStepComponent {}
