<div id="dashboard-panel-wrapper" class="full-height">
  <div class="full-width dashboard-panel-header">
    <h3>Dashboard</h3>
    <p-button
      *ngIf="(dashboardUpdated$ | async) === true"
      icon="pi pi-refresh"
      label="Refresh"
      (click)="onRefreshDashboard()"
    ></p-button>
  </div>
  <div class="full-width" *ngIf="(isLoyaltyProgramAdmin$ | async) === true; else noSaleOverview">
    <p-tabView>
      <p-tabPanel header="TODAY">
        <ng-template pTemplate="content">
          <app-dashboard-today-panel-view></app-dashboard-today-panel-view>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="WEEK">
        <ng-template pTemplate="content">
          <app-dashboard-week-panel-view></app-dashboard-week-panel-view>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="MONTH">
        <ng-template pTemplate="content">
          <app-dashboard-month-panel-view></app-dashboard-month-panel-view>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="CUSTOM">
        <ng-template pTemplate="content">
          <app-dashboard-custom-panel-view></app-dashboard-custom-panel-view>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
  <ng-template #noSaleOverview>
    <h5>No Sale Overview</h5>
  </ng-template>
</div>
