import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { MerchantAccountFormComponent } from '@app/enroll/forms/merchant-account-form/merchant-account-form.component';

@Component({
  selector: 'app-merchant-account-step',
  templateUrl: './merchant-account-step.component.html',
  styleUrls: ['./merchant-account-step.component.scss'],
  imports: [CardModule, ButtonModule, MerchantAccountFormComponent],
  standalone: true,
})
export class MerchantAccountStepComponent {}
