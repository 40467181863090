import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { AuthenticationService } from '..';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CredentialsActions, HydrationActions, MessagingActions, RouterActions } from '@app/resources/ngrx/actions';
import { Store } from '@ngrx/store';
import { selectRouter } from '@app/resources/ngrx/selectors';
import { BehaviorSubject, catchError, distinctUntilChanged, Observable, of, Subscription, take, tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';

@UntilDestroy()
@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
})
export class ImpersonateComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly subdomainService = inject(SubdomainService);

  SubdomainTypes = SubdomainTypes;

  private readonly _subdomainType$ = new BehaviorSubject<SubdomainTypes | null>(null);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  protected subdomainType$: Observable<SubdomainTypes> = this._subdomainType$
    .asObservable()
    .pipe(filter(isNotNullOrUndefined), distinctUntilChanged());

  protected isLoading$ = this._isLoading$.asObservable().pipe(distinctUntilChanged());

  private subs = new Subscription();

  ngOnInit(): void {
    this.setIsLoading();
    this.subs.add(
      this.store
        .select(selectRouter)
        .pipe(
          untilDestroyed(this),
          filter(({ state }) => !!state.params['jwt']),
          take(1),
          tap(({ state }) => {
            this.store.dispatch(HydrationActions.clear());
            this.store.dispatch(CredentialsActions.clearCredentials());

            const jwt = state.params['jwt'];
            this.store.dispatch(
              CredentialsActions.updateCredentials({
                payload: {
                  credentials: { clientAccessId: '', jwt },
                },
              })
            );
          }),
          switchMap(() => {
            return this.authenticationService.getApplicationProfile().pipe(
              take(1),
              tap((applicationProfile: ApplicationProfile) => {
                this._subdomainType$.next(this.subdomainService.getDomain());
                this.applicationProfileService.setApplicationProfile(applicationProfile);
                this.setIsNotLoading();
                this.store.dispatch(
                  RouterActions.navigateByUrl({ payload: { path: `${applicationProfile.Location.LocationId}/admin` } })
                );
              }),
              catchError((err) => {
                const message = {
                  severity: 'error',
                  summary: `ERROR: Impersonation`,
                  detail: `Error impersonating!`,
                };
                this.store.dispatch(MessagingActions.createMessage({ payload: { message } }));
                this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: '/auth/login' } }));
                return of(err);
              })
            );
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private setIsLoading() {
    this._isLoading$.next(true);
  }

  private setIsNotLoading() {
    this._isLoading$.next(false);
  }
}
