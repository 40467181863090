import { Component, inject } from '@angular/core';
import { CardModule } from 'primeng/card';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReactiveFormsModule } from '@angular/forms';
import { EnrollmentFormService } from '@app/enroll/services/enrollment-form.service';
import { EnrollmentOfferTypesEnum } from '@shared/enums/enrollment-offer-types.enum';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FONT_AWESOME_ICONS } from '@shared/icons';

@Component({
  selector: 'app-choose-enrollment-offer-form',
  templateUrl: './choose-enrollment-offer-form.component.html',
  styleUrls: ['./choose-enrollment-offer-form.component.scss'],
  imports: [CardModule, NgForOf, NgIf, RadioButtonModule, ReactiveFormsModule, NgClass, FaIconComponent],
  standalone: true,
})
export class ChooseEnrollmentOfferFormComponent {
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  protected enrollmentOfferForm = this.enrollmentFormService.getEnrollmentOfferForm();
  protected availableOffers = [
    {
      name: 'Instant',
      value: EnrollmentOfferTypesEnum.Instant,
      description: `
        Offering an instant reward off the customer's bill is one of the most simple and effective ways to gain enrollment.
      `,
      selector: 'instant',
    },
    {
      name: 'Bonus Reward',
      value: EnrollmentOfferTypesEnum.Bonus,
      description: `
        Offering a bonus reward provides the customer with a reward that they can use on any of their future purchases.
      `,
      selector: 'bonus',
    },
  ];

  protected icons = FONT_AWESOME_ICONS;

  get selectedOffer() {
    return this.enrollmentOfferForm.controls.Selected.value;
  }

  protected isInstantOffer(value: EnrollmentOfferTypesEnum) {
    return value === EnrollmentOfferTypesEnum.Instant;
  }

  protected updateSelectedOffer(value: EnrollmentOfferTypesEnum) {
    this.enrollmentOfferForm.controls.Selected.setValue(value);

    if (value === EnrollmentOfferTypesEnum.Instant) {
      this.enrollmentOfferForm.controls.Instant.enable({ emitEvent: false });
      this.enrollmentOfferForm.controls.Bonus.disable();
    } else {
      this.enrollmentOfferForm.controls.Bonus.enable({ emitEvent: false });
      this.enrollmentOfferForm.controls.Instant.disable();
    }
  }
}
