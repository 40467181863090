<div *ngIf="instantOfferForm">
  <form [formGroup]="instantOfferForm" class="offer-instant-form" data-cy="offer-instant-form">
    <div class="offer-instant-form-row">
      <ep-percentage-control
        formControlName="RewardPercentage"
        label="Instant Reward"
        data-cy="offer-instant-reward-percentage"
        class="offer-instant-control-width"
      ></ep-percentage-control>
      <p *ngIf="!isXSmallScreen; else infoPercentageReward" class="offer-instant-control-info-width">
        <i>{{ percentageHelpText }}</i>
      </p>
      <ng-template #infoPercentageReward>
        <i
          class="pi pi-info-circle help-icon"
          (click)="showHelp('percentage')"
          data-cy="offer-instant-reward-percentage-help"
        ></i>
      </ng-template>
    </div>
    <div class="offer-instant-form-row">
      <ep-price-control
        formControlName="MaxRewardAmount"
        label="Max Reward Amount"
        data-cy="offer-instant-max-reward-amount"
        class="offer-instant-control-width"
      ></ep-price-control>
      <p *ngIf="!isXSmallScreen; else infoMaxRewardAmount" class="offer-instant-control-info-width">
        <i>{{ maxRewardHelpText }}</i>
      </p>
      <ng-template #infoMaxRewardAmount>
        <i
          class="pi pi-info-circle help-icon"
          (click)="showHelp('max-reward')"
          data-cy="offer-instant-max-reward-help"
        ></i>
      </ng-template>
    </div>
  </form>
  <p-dialog
    [(visible)]="isDialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ 'max-width': '400px' }"
    data-cy="offer-instant-form-dialog"
  >
    <p class="m-0 offer-instant-form-dialog-content" data-cy="offer-instant-help-dialog-content">
      <i>{{ getHelpDialog() }}</i>
    </p>
  </p-dialog>
</div>
