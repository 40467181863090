<div>
  <span>
    <label class="enrollment-email-label"> Email Address </label>
    <input
      type="text"
      [ngClass]="{ 'custom-ng-pending': !isFocusedOut }"
      class="enrollment-email w-full p-inputtext p-component full-height-width"
      [formControl]="email"
      (focusout)="onFocusOut()"
      (input)="onFocusIn()"
      data-cy="enrollment-email-control"
    />
  </span>
  <p
    *ngIf="doesEmailControlHaveErrors()"
    class="enrollment-email-control-error"
    data-cy="enrollment-email-control-error"
  >
    <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> {{ displayEmailErrorMessage() }}
  </p>
</div>
