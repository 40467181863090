import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription, tap } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { PasswordModule } from 'primeng/password';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-enrollment-terminal-control',
  templateUrl: './enrollment-terminal-control.component.html',
  styleUrls: ['./enrollment-terminal-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, InputTextModule, NgIf, PasswordModule, NgClass, FaIconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnrollmentTerminalControlComponent,
      multi: true,
    },
  ],
})
export class EnrollmentTerminalControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label: string = 'Terminal';

  protected terminal = new FormControl<number | null>(null, {
    validators: [Validators.required, Validators.pattern(/^[0-9]{6}$/)],
  });
  onChange: any = (value: string) => {};
  onTouch: any = () => {};

  value: string | null = null;

  private subs = new Subscription();
  private isFocused = false;
  protected icons = FONT_AWESOME_ICONS;

  ngOnInit(): void {
    this.subs.add(
      this.terminal.valueChanges
        .pipe(
          tap((value) => {
            if (value !== null) this.value = value.toString();
            else value = null;
            this.onChange(this.value);
            this.onTouch();
            if (!this.isFocused) {
              this.onFocusIn();
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected onFocusIn() {
    this.isFocused = true;
  }

  protected onFocusOut() {
    this.isFocused = false;
  }

  protected get isFocusedOut() {
    return !this.isFocused;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.terminal.disable({ emitEvent: false }) : this.terminal.enable({ emitEvent: false });
  }

  setValue(value?: string | null) {
    this.value = value ?? null;
  }

  writeValue(value?: string): void {
    this.setValue(value ?? null);
    this.terminal.setValue(value !== undefined && value !== null && value !== '' ? parseInt(value) : null, {
      emitEvent: false,
    });
  }

  keyPressOnlyNumbers(e: KeyboardEvent) {
    const number = parseInt(e.key);
    const validCommands = ['backspace', 'enter', 'tab', 'arrowleft', 'arrowright', 'delete', 'home', 'shift'];
    return !isNaN(number) || validCommands.includes(e.key.toLowerCase());
  }
}
