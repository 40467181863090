<div>
  <form [formGroup]="enrollmentOfferForm" class="offer-option-panels" data-cy="choose-enrollment-offer-form">
    <div
      *ngFor="let offer of availableOffers"
      class="offer-option-card"
      [ngClass]="{ 'selected-option': offer.value === selectedOffer }"
    >
      <p-card class="full-width" [attr.data-cy]="offer.selector + '-card'" (click)="updateSelectedOffer(offer.value)">
        <div class="offer-card-content">
          <p-radioButton
            [inputId]="offer.name"
            [value]="offer.value"
            formControlName="Selected"
            [attr.data-cy]="offer.selector + '-radio-button'"
          ></p-radioButton>
          <fa-icon
            *ngIf="isInstantOffer(offer.value); else bonusIcon"
            [icon]="icons['faPercent']"
            size="lg"
            class="enlarged-offer-icon"
          ></fa-icon>
          <ng-template #bonusIcon>
            <fa-icon [icon]="icons['faDollarSign']" size="lg" class="enlarged-offer-icon"></fa-icon>
          </ng-template>
          <div class="offer-panel-content">
            <label [for]="offer.name" class="offer-panel-header">{{ offer.name }}</label>
            <p class="offer-description">{{ offer.description }}</p>
          </div>
        </div>
      </p-card>
    </div>
  </form>
</div>
