import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { combineLatest, concat, defer, distinctUntilChanged, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { OfferTextReminderEnum } from '@shared/enums';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { OfferReminder } from '@app/areas/offers/utils';
import { OfferDate, WhenOfferDateEnum } from '@app/areas/offers/services/offer.form.service';
import moment from 'moment-timezone';

/**
 * OfferValidator class
 *
 * A class of validator methods for the offer form.
 *
 * NOTE: This was originally static, but it made testing the validators impossible since we use DI here (that's why
 * it's a public class of support methods)
 */
export class OfferValidator {
  timeZoneService: TimezoneService;

  constructor(timezoneService: TimezoneService) {
    this.timeZoneService = timezoneService;
  }

  /**
   * Validates whether the SMS date time is within the 8am-5pm range and is within the range of the current time plus
   * 10 minutes and the start date of the offer.
   * @param startDateControl the offer start date form control
   */
  public offerSMSDateValidatorAsync<K extends AbstractControl>(startDateControl: K): AsyncValidatorFn {
    return <T extends AbstractControl>(control: T): Observable<ValidationErrors | null> => {
      if (!control || !startDateControl) {
        return of(null);
      }
      return combineLatest([
        concat(
          defer(() => of(control.value)),
          control.valueChanges
        ),
        concat(
          defer(() => of(startDateControl.value)),
          startDateControl.valueChanges
        ),
      ]).pipe(
        map((value: [OfferDate, OfferDate]) => {
          const nowMoment = this.timeZoneService
            .moment()
            .add(this.timeZoneService.getLocationClientOffset(), 'hours')
            .second(0)
            .millisecond(0);

          if (!value[0] || value[0].date === null) {
            control.setErrors({ required: true });
            return { required: true };
          }
          const dateMoment = this.timeZoneService.moment(value[0].date).second(0).millisecond(0);
          const startMoment = this.timeZoneService.moment(value[1].date).second(0).millisecond(0);
          const date = dateMoment.toDate();

          if (date.getHours() < 8 || (date.getHours() === 21 && date.getMinutes() > 0) || date.getHours() > 21) {
            control.setErrors({ invalidTime: true });
            return { invalidTime: true };
          }

          if (dateMoment.diff(nowMoment) < 0 || dateMoment.diff(startMoment) > 0) {
            control.setErrors({ invalidSMSDate: true });
            return { invalidSMSDate: true };
          }

          control.setErrors(null);
          return null;
        }),
        first()
      );
    };
  }

  /**
   * Validates whether the offer start date time is before the current time
   */
  public offerDurationStartDateValidatorAsync(): AsyncValidatorFn {
    return <K extends AbstractControl>(startDateControl: K): Observable<ValidationErrors | null> => {
      return concat(
        defer(() => of(startDateControl.value)),
        startDateControl.valueChanges
      ).pipe(
        map((startDate: OfferDate) => {
          if (!!startDate.date) {
            const nowMoment = this.timeZoneService
              .moment()
              .add(this.timeZoneService.getLocationClientOffset(), 'hours')
              .second(0)
              .millisecond(0);

            const startMoment = moment(startDate.date);

            if (startDate.when !== WhenOfferDateEnum.NOW && startMoment.isBefore(nowMoment)) {
              startDateControl.setErrors({ isBeforeNow: true });
              return { isBeforeNow: true };
            }
          }

          startDateControl.setErrors(null);
          return null;
        }),
        first()
      );
    };
  }

  /**
   * Validates whether the offer end date time is after the offer start date time
   * @param startDateControl the offer start date form control
   */
  public offerDurationEndDateValidatorAsync<T extends AbstractControl>(startDateControl: T): AsyncValidatorFn {
    return <K extends AbstractControl>(endDateControl: K): Observable<ValidationErrors | null> => {
      return combineLatest([
        concat(
          defer(() => of(startDateControl.value)),
          startDateControl.valueChanges
        ),
        concat(
          defer(() => of(endDateControl.value)),
          endDateControl.valueChanges
        ),
      ]).pipe(
        map(([startDate, endDate]: [OfferDate, OfferDate]) => {
          if (!!startDate.date && !!endDate.date) {
            const startMoment = this.timeZoneService.moment(startDate.date);
            const endMoment = this.timeZoneService.moment(endDate.date);

            if (endMoment.diff(startMoment, 'seconds') <= 1) {
              endDateControl.setErrors({ isLessThanStart: true });
              return { isLessThanStart: true };
            }
          }

          return null;
        }),
        first()
      );
    };
  }

  /**
   * Validates whether the current reminder selection is valid in relation to the offer start date and current time.
   * @param startDateControl the offer start date form control
   */
  public offerSMSReminderValidatorAsync<T extends AbstractControl>(startDateControl: T): AsyncValidatorFn {
    return <K extends AbstractControl>(reminderControl: K): Observable<ValidationErrors | null> => {
      if (!reminderControl?.valueChanges || !startDateControl?.valueChanges) {
        return of(null);
      }
      return combineLatest([
        concat(
          defer(() => of(startDateControl.value)),
          startDateControl.valueChanges
        ),
        concat(
          defer(() => of(reminderControl.value)),
          reminderControl.valueChanges
        ),
      ]).pipe(
        distinctUntilChanged(),
        map(([startDate, reminder]: [OfferDate, OfferReminder | null]) => {
          if (!!startDate.date && !!reminder) {
            const nowMoment = this.timeZoneService
              .moment()
              .add(this.timeZoneService.getLocationClientOffset(), 'hours')
              .second(0)
              .millisecond(0);
            const startMoment = moment(startDate.date);
            const start = startDate.date;
            const now = nowMoment.toDate();
            const key = parseInt(reminder.key) as OfferTextReminderEnum;

            if (
              key === OfferTextReminderEnum.ONE_WEEK_BEFORE &&
              ((startMoment.diff(nowMoment, 'days') === 6 && start.getHours() < 8 && now.getHours() > 8) ||
                startMoment.diff(nowMoment, 'days') <= 6)
            ) {
              reminderControl.setErrors({ cannotUseWeekReminder: true });
              return { cannotUseWeekReminder: true };
            }

            if (
              key === OfferTextReminderEnum.ONE_DAY_BEFORE &&
              startMoment.diff(nowMoment, 'hours') <= 24 &&
              ((start.getHours() < 8 && ((now.getHours() == 8 && now.getMinutes() >= 0) || now.getHours() > 8)) ||
                (start.getHours() >= 8 &&
                  now.getHours() >= start.getHours() &&
                  now.getMinutes() >= start.getMinutes()) ||
                (start.getHours() >= 21 && now.getHours() >= 8) ||
                (start.getDay() - now.getDay() < 1 && start.getHours() < 21))
            ) {
              reminderControl.setErrors({ cannotUseDayReminder: true });
              return { cannotUseDayReminder: true };
            }

            if (
              key === OfferTextReminderEnum.ONE_HOUR_BEFORE &&
              startMoment.diff(nowMoment, 'hours') <= 24 &&
              ((start.getHours() < 9 && ((now.getHours() == 21 && now.getMinutes() > 0) || now.getHours() >= 22)) ||
                startMoment.diff(nowMoment, 'hours') < 1 ||
                (start.getDay() - now.getDay() < 1 && start.getHours() >= 22 && now.getHours() >= 21))
            ) {
              reminderControl.setErrors({ cannotUseHourReminder: true });
              return { cannotUseHourReminder: true };
            }

            if (key === OfferTextReminderEnum.CUSTOM && startMoment.diff(nowMoment) < 0) {
              reminderControl.setErrors({ cannotUseCustomReminder: true });
              return { cannotUseCustomReminder: true };
            }
          }

          reminderControl.setErrors(null);
          return null;
        }),
        first()
      );
    };
  }
}
