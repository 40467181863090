import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Question } from '@app/resources/services';

const sortQuestionByStatusThenDisplay = (a: Question, b: Question) => {
  return a.StatusType - b.StatusType || (a.Display ?? '').localeCompare(b.Display ?? '');
};

export const questionAdapter: EntityAdapter<Question> = createEntityAdapter<Question>({
  sortComparer: sortQuestionByStatusThenDisplay,
  selectId: (e) => e?.QuestionId ?? 0,
});

export const {
  selectIds: selectQuestionIds,
  selectEntities: selectQuestionEntities,
  selectAll: selectAllQuestions,
  selectTotal: questionsCount,
} = questionAdapter.getSelectors();
