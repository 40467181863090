import { DeviceState } from '@app/resources/ngrx/states';
import { deviceAdapter } from '@app/resources/ngrx/adapters';
import { createReducer, on } from '@ngrx/store';
import { DeviceActions } from '@app/resources/ngrx/actions';
import * as fromDeviceAdapter from '@app/resources/ngrx/adapters';
import _ from 'lodash';

export const initialDeviceModuleState: DeviceState = deviceAdapter.getInitialState({
  selectedDeviceId: 0,
  selectId: 0,
});

export const _deviceReducer = createReducer(
  initialDeviceModuleState,
  on(DeviceActions.upsertDeviceSuccess, (state, { payload }) => {
    return fromDeviceAdapter.deviceAdapter.upsertOne(payload.device, state);
  }),
  on(DeviceActions.upsertDevicesSuccess, (state, { payload }) => {
    // This is necessary to make sure that DeviceTrackings are carried over
    // Right now, DeviceTrackings isn't linked to the Device entity, so data we get from SignalR has an empty
    // DeviceTracking list on the Device object
    const upsertedDevices = _.cloneDeep(payload.devices);
    for (let i = 0; i < upsertedDevices.length; i++) {
      const stateDevice = state.entities[upsertedDevices[i].DeviceId];
      if (stateDevice) {
        upsertedDevices[i].DeviceTrackings = _.cloneDeep(stateDevice.DeviceTrackings);
      }
    }

    return fromDeviceAdapter.deviceAdapter.upsertMany(upsertedDevices, state);
  }),
  on(DeviceActions.updateDeviceTrackingsSuccess, (state, { payload }) => {
    if (payload.deviceTrackings.length === 0) return state;

    const deviceId = payload.deviceTrackings[0].DeviceId;
    const updatedDevice = {
      ...state.entities[deviceId],
      DeviceTrackings: payload.deviceTrackings,
    };
    return fromDeviceAdapter.deviceAdapter.updateOne({ id: deviceId, changes: updatedDevice }, state);
  }),
  on(DeviceActions.loadDevicesSuccess, (state, { payload }) => {
    state = fromDeviceAdapter.deviceAdapter.removeAll({ ...state });
    return fromDeviceAdapter.deviceAdapter.addMany(payload.devices, state);
  }),
  on(DeviceActions.deviceById, (state, { payload }) => {
    return Object.assign({
      ...state,
      selectedDeviceId: payload.deviceId,
    });
  })
);
