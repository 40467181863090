import { inject, Injectable } from '@angular/core';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import moment from 'moment-timezone';
import { TimeZonesEnum, TimeZonesMap } from '@ep/shared';

/*
  Refer to the link below for available timezones in moment timezone js
  Link: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
 */
const TimeZoneTypeToMomentTimezoneMap: { [index: number]: string } = {
  [TimeZonesEnum.INTERNATIONALDATELINEWEST]: 'Pacific/Wallis',
  [TimeZonesEnum.MIDWAYISLAND_SAMOA]: 'Pacific/Samoa',
  [TimeZonesEnum.HAWAII]: 'US/Hawaii',
  [TimeZonesEnum.ALASKA]: 'US/Alaska',
  [TimeZonesEnum.PACIFICTIME]: 'America/Tijuana',
  [TimeZonesEnum.TIJUANA_BAJACALIFORNIA]: 'America/Tijuana',
  [TimeZonesEnum.ARIZONA]: 'US/Mountain',
  [TimeZonesEnum.MOUNTAINTIME]: 'US/Mountain',
  [TimeZonesEnum.CHIHUAHUA_LAPAZ_MAZATLAN]: 'America/Chihuahua',
  [TimeZonesEnum.CENTRALAMERICA]: 'America/Managua',
  [TimeZonesEnum.CENTRALTIME]: 'US/Central',
  [TimeZonesEnum.GUADALAJARA_MEXICOCITY]: 'America/Mexico_City',
  [TimeZonesEnum.SASKATCHEWAN]: 'Canada/Saskatchewan',
  [TimeZonesEnum.BOGOTA_LIMA_QUITO_RIOBRANCO]: 'America/Lima',
  [TimeZonesEnum.EASTERNTIME]: 'US/Eastern',
  [TimeZonesEnum.INDIANA]: 'US/East-Indiana',
  [TimeZonesEnum.ATLANTICTIME]: 'Canada/Atlantic',
  [TimeZonesEnum.LAPAZ]: 'America/La_Paz',
  [TimeZonesEnum.MANAUS]: 'America/Sao_Paulo',
  [TimeZonesEnum.SANTIAGO]: 'America/Santiago',
};

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private momentTimezone = 'America/Tijuana';

  initializeTimezone(timeZoneType: TimeZonesEnum = TimeZonesEnum.PACIFICTIME) {
    this.momentTimezone = TimeZoneTypeToMomentTimezoneMap[timeZoneType];
  }

  getLocationOffset(date?: moment.MomentInput): number {
    return this.moment(date).utcOffset() / 60;
  }

  getClientOffset(): number {
    return moment().utcOffset() / 60;
  }

  getLocationClientOffset(date?: moment.MomentInput) {
    return this.getLocationOffset(date) - this.getClientOffset();
  }

  moment(date?: moment.MomentInput): moment.Moment {
    return moment(date ?? new Date()).tz(this.momentTimezone);
  }

  reformatOfferDate(date?: moment.MomentInput) {
    const utc = this.moment(date);
    let utcOffset = this.getLocationOffset(date);
    return this.moment(utc.clone().add(utcOffset, 'hours').toDate());
  }

  isDST(date?: moment.MomentInput) {
    return this.moment(date).isDST();
  }

  // If the current time is in daylight savings and the date we are checking is not
  // Then we want to add an hour to the date times in the UI
  getDSTTime(date?: moment.MomentInput) {
    if (this.isDST() && !this.isDST(date)) {
      return 1;
    }
    return 0;
  }
}
