import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { OffersComponent } from './offers.component';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { OfferFormCardComponent } from '@app/areas/offers/components/offer-form-card/offer-form-card.component';
import { OfferDetailsComponent } from '@app/areas/offers/components/offer-details/offer-details.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/offers',
      component: OffersComponent,
      canActivate: [AccessGuard],
      data: { title: 'Offers', requiredAccessTypes: [AccessType.OfferAdmin] },
    },
    {
      path: ':locationId/offers/new',
      component: OfferFormCardComponent,
      canActivate: [AccessGuard],
      data: { title: 'Create Offer', requiredAccessTypes: [AccessType.OfferAdmin] },
    },
    {
      path: ':locationId/offers/:offerId',
      component: OfferDetailsComponent,
      canActivate: [AccessGuard],
      data: { title: 'Edit Offer', requiredAccessTypes: [AccessType.OfferAdmin] },
    },
    {
      path: ':locationId/offers/:offerId/edit',
      component: OfferFormCardComponent,
      canActivate: [AccessGuard],
      data: { title: 'Edit Offer', requiredAccessTypes: [AccessType.OfferAdmin] },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OffersRoutingModule {}
