import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { StepsModule } from 'primeng/steps';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of, startWith, Subscription, tap } from 'rxjs';
import { MerchantAccountStepComponent } from '@app/enroll/components/merchant-account-step/merchant-account-step.component';
import { AsyncPipe, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { map } from 'rxjs/operators';
import { EnrollmentFormGroup, EnrollmentFormService } from '@app/enroll/services/enrollment-form.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EnrollmentAPIService } from '@app/enroll/services/enrollment-api.service';
import { MerchantChooseProgramStepComponent } from '@app/enroll/components/merchant-choose-program-step/merchant-choose-program-step.component';
import { MerchantConfigureProgramStepComponent } from '@app/enroll/components/merchant-configure-program-step/merchant-configure-program-step.component';
import { LoyaltyProgramsTypesEnum } from '@shared/enums';
import { MerchantChooseOfferStepComponent, MerchantConfigureOfferStepComponent } from '@app/enroll/components';
import { EnrollmentOfferTypesEnum } from '@shared/enums/enrollment-offer-types.enum';
import { MerchantReviewQuestionStepComponent } from '@app/enroll/components/merchant-review-question-step/merchant-review-question-step.component';
import { EnrollmentSubmissionComponent } from '@app/enroll/components/enrollment-submission/enrollment-submission.component';
import { LoadingSpinnerDirective } from '@ep/shared';
import { EnrollmentCompleteComponent } from '@app/enroll/components/enrollment-complete/enrollment-complete.component';
import { EnrollmentCustomValidators } from '@app/enroll/validators';

const steps = [
  'account',
  'choose-program',
  'configure-program',
  'choose-offer',
  'configure-offer',
  'review-question',
] as const;
type EnrollmentSteps = (typeof steps)[number];
const isEnrollmentStep = (x: any): x is EnrollmentSteps => x;

@Component({
  selector: 'app-enrollment-stepper',
  templateUrl: './enrollment-stepper.component.html',
  styleUrls: ['./enrollment-stepper.component.scss'],
  standalone: true,
  imports: [
    StepsModule,
    MerchantAccountStepComponent,
    NgIf,
    ConfirmDialogModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    CardModule,
    ButtonModule,
    AsyncPipe,
    MerchantChooseProgramStepComponent,
    MerchantConfigureProgramStepComponent,
    NgClass,
    MerchantChooseOfferStepComponent,
    MerchantConfigureOfferStepComponent,
    MerchantReviewQuestionStepComponent,
    EnrollmentSubmissionComponent,
    LoadingSpinnerDirective,
    EnrollmentCompleteComponent,
  ],
  providers: [ConfirmationService],
})
export class EnrollmentStepperComponent implements OnInit, OnDestroy {
  private readonly enrollmentApiService = inject(EnrollmentAPIService);
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  private readonly confirmationService = inject(ConfirmationService);
  private readonly messagingService = inject(MessageService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly validators = new EnrollmentCustomValidators();
  private enrollmentId: string | null = null;

  protected enrollmentForm: EnrollmentFormGroup = this.enrollmentFormService.getForm();
  private isFormInitialized = false;
  protected currentQueryParam: EnrollmentSteps = 'account';
  protected items: MenuItem[] = [
    {
      label: 'Merchant Account',
    },
    {
      label: 'Choose Loyalty Program',
    },
    {
      label: 'Configure Loyalty Program',
    },
    {
      label: 'Choose Enrollment Offer',
    },
    {
      label: 'Configure Enrollment Offer',
    },
    {
      label: 'Review Question',
    },
  ];

  private _activeIndex$ = new BehaviorSubject<number>(0);
  protected readonly activeIndex$ = this._activeIndex$.asObservable();
  protected lastIndex = steps.length - 1;

  private _isCompleted = false;
  private _isLoading = true;
  private _isSaving = false;
  private _isSubmittingEnrollment = false;
  protected isStepInvalid$ = combineLatest([
    this.enrollmentForm.statusChanges.pipe(startWith(this.enrollmentForm.value)),
    this.activeIndex$,
  ]).pipe(
    map(([_, activeIndex]) => {
      return !this.isCurrentStepValid(activeIndex);
    })
  );

  private subs = new Subscription();

  protected get isCompleted() {
    return this._isCompleted;
  }

  protected get isLoading() {
    return this._isLoading;
  }

  protected get isSaving() {
    return this._isSaving;
  }

  protected get isSubmittingEnrollment() {
    return this._isSubmittingEnrollment;
  }

  get submitEnrollment$() {
    return !!this.enrollmentId ? this.enrollmentApiService.completeEnrollment(this.enrollmentId) : of(null);
  }

  get activeIndex() {
    return this._activeIndex$.value;
  }

  private displayWarningMessage() {
    this.confirmationService.confirm({
      message: 'Finish up current step before moving on',
      icon: 'pi pi-exclamation-triangle',
      key: 'enrollment-step-confirmation',
      reject: () => {
        return;
      },
    });
  }

  protected getStepHeader() {
    switch (this.activeIndex) {
      case 1:
        return 'Choose a Loyalty Program';
      case 2:
        const program = this.enrollmentForm?.controls.LoyaltyProgram.controls.Selected.value;
        return program === LoyaltyProgramsTypesEnum.CashBack
          ? 'Configure Cash Back Program'
          : program === LoyaltyProgramsTypesEnum.Points
          ? 'Configure Points Program'
          : 'Configure Loyalty Program';
      case 3:
        return 'Choose an Enrollment Offer';
      case 4:
        const offer = this.enrollmentForm?.controls.EnrollmentOffer.controls.Selected.value;
        return offer === EnrollmentOfferTypesEnum.Instant
          ? 'Configure Instant Offer'
          : offer === EnrollmentOfferTypesEnum.Bonus
          ? 'Configure Bonus Reward Offer'
          : 'Configure Enrollment Offer';
      case 5:
        return 'Review Question';
      default:
        return 'Account Setup';
    }
  }

  private isCurrentStepValid(index: number) {
    if (typeof this.enrollmentForm !== 'undefined' && this.enrollmentForm) {
      switch (index) {
        case 1:
          return this.enrollmentFormService.getLoyaltyProgramForm().controls.Selected.getRawValue() !== null;
        case 2:
          const selectedProgram = this.enrollmentFormService.getLoyaltyProgramForm().controls.Selected.getRawValue();
          return (
            (selectedProgram === LoyaltyProgramsTypesEnum.CashBack &&
              this.enrollmentFormService.getCashBackProgramForm().status === 'VALID') ||
            (selectedProgram === LoyaltyProgramsTypesEnum.Points &&
              this.enrollmentFormService.getPointsProgramForm().status === 'VALID')
          );
        case 3:
          return this.enrollmentFormService.getEnrollmentOfferForm().controls.Selected.value !== null;
        case 4:
          const selectedOffer = this.enrollmentFormService.getEnrollmentOfferForm().controls.Selected.getRawValue();
          return (
            (selectedOffer === EnrollmentOfferTypesEnum.Instant &&
              this.enrollmentFormService.getInstantOfferForm().status === 'VALID') ||
            (selectedOffer === EnrollmentOfferTypesEnum.Bonus &&
              this.enrollmentFormService.getBonusOfferForm().status === 'VALID')
          );
        case 5:
          return this.enrollmentFormService.getReviewQuestionsForm().status === 'VALID';
        default:
          return this.enrollmentFormService.getAccountForm().status === 'VALID';
      }
    }
    return false;
  }

  private checkQueryParams(queryParams: Params) {
    const keys = Object.keys(queryParams);
    /**
     * User is redirected to the first step if the query params do not match the list
     * of valid query params for the enrollment page
     */
    if (keys.length > 1 || (keys.length === 1 && !isEnrollmentStep(keys[0])) || keys.length === 0) {
      this._activeIndex$.next(0);
      this.navigateToStep('account');
    } else if (keys.length === 1 && isEnrollmentStep(keys[0])) {
      const step = keys[0] as EnrollmentSteps;
      const index = steps.indexOf(step);
      /**
       * The previous step must be valid in order to access the current step
       * when routing to the step directly. Else, the user is automatically
       * redirected to the first step in the enrollment process.
       */
      if (index > 0 && this.isCurrentStepValid(index - 1)) {
        this._activeIndex$.next(index);
        this.navigateToStep(step);
      } else {
        this._activeIndex$.next(0);
        this.navigateToStep('account');
      }
    }
  }

  protected goToNextStep() {
    if (this.isCurrentStepValid(this.activeIndex)) {
      if (this.enrollmentForm.dirty) {
        this.updateEnrollment();
      }
      this.goToStep(this.activeIndex + 1);
    } else {
      this.displayWarningMessage();
    }
  }

  protected goToPreviousStep() {
    if (this.enrollmentForm.dirty) this.updateEnrollment();
    this.goToStep(this.activeIndex - 1);
  }

  private goToStep(step: number) {
    this._activeIndex$.next(step);
    this.navigateToStep(steps[this.activeIndex]);
  }
  ngOnInit() {
    this.enrollmentId = this.route.snapshot.paramMap.get('enrollId');
    if (this.enrollmentId === null) throw new Error("EnrollmentId doesn't exist in url path");
    combineLatest([this.enrollmentApiService.getEnrollmentData(this.enrollmentId), this.route.queryParams]).subscribe(
      ([enrollmentResponse, queryParams]) => {
        if (enrollmentResponse.IsComplete) {
          this._isCompleted = true;
        } else if (enrollmentResponse.Data) {
          /*
           This is to prevent the form from being re-initialized on every query param change
          */
          if (!this.isFormInitialized) {
            this.enrollmentFormService.initializeForm({
              MerchantAccount: {
                ContactInformation: enrollmentResponse.Data.ContactInformation,
                BusinessInformation: enrollmentResponse.Data.BusinessInformation,
              },
              LoyaltyProgram: {
                CashBack: enrollmentResponse.Data.CashBackProgram,
                Points: enrollmentResponse.Data.PointsProgram,
              },
              EnrollmentOffer: {
                InstantOffer: enrollmentResponse.Data.InstantOffer,
                BonusOffer: enrollmentResponse.Data.BonusOffer,
              },
              ReviewQuestion: enrollmentResponse.Data.ReviewQuestions,
            });
            this.isFormInitialized = true;
          }
          this.checkQueryParams(queryParams);
        }
        if (this._isLoading) this._isLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private updateEnrollment() {
    if (this.enrollmentForm) {
      const contactInfo = this.enrollmentFormService.getContactInformation();
      const businessInfo = this.enrollmentFormService.getBusinessInformation();
      const cashBackProgram = this.enrollmentFormService.getCashBackProgram();
      const pointsProgram = this.enrollmentFormService.getPointsProgram();
      const instantOffer = this.enrollmentFormService.getInstantOffer();
      const bonusOffer = this.enrollmentFormService.getBonusOffer();
      const reviewQuestion = this.enrollmentFormService.getReviewQuestions();

      this.enrollmentApiService
        .updateEnrollment({
          EnrollmentId: this.enrollmentId!,
          MerchantAccount: {
            ContactInformation: contactInfo,
            BusinessInformation: businessInfo,
          },
          LoyaltyProgram: {
            CashBack: cashBackProgram,
            Points: pointsProgram,
          },
          EnrollmentOffer: {
            InstantOffer: instantOffer,
            BonusOffer: bonusOffer,
          },
          ReviewQuestions: reviewQuestion,
        })
        .pipe(
          tap((response: any) => {
            if (response?.error) {
              this.messagingService.add({
                severity: 'error',
                summary: 'Error Updating Enrollment',
                detail: 'An error occurred while trying to save enrollment information',
              });
            } else {
              this.enrollmentForm.markAsPristine();
            }
          })
        )
        .subscribe();
    }
  }

  protected submitEnrollment() {
    this.updateEnrollment();
    this._isSaving = true;
    this.enrollmentForm.updateValueAndValidity();
    this.validators
      .checkIfEmailIsUniqueValidatorAsync(null)(this.enrollmentFormService.getContactInfoForm().controls.Email)
      .subscribe((response) => {
        if (this.enrollmentForm.valid && response === null) {
          this._isSubmittingEnrollment = true;
        } else {
          const invalid: (string | null)[] = [];
          const controls = this.enrollmentFormService.getForm().controls;

          for (const name in controls) {
            if (this.enrollmentFormService.getForm().get(name)?.invalid) {
              invalid.push(this.mapEnrollmentFormControlNameToPageStepTitle(name));
            }
          }
          let invalidSteps: string | null = '';
          if (invalid.length === 1) invalidSteps = invalid[0];
          else if (invalid.length > 1) {
            for (let i = 0; i < invalid.length; i++) {
              if (i === 0) {
                invalidSteps = invalid[i];
              } else if (i === invalid.length - 1) {
                invalidSteps += `, and ${invalid[i]}`;
              } else {
                invalidSteps += `, ${invalid[i]}`;
              }
            }
          }

          this._isSaving = false;
          this.confirmationService.confirm({
            message: `There is an invalid field in ${invalidSteps}. Fix it before proceeding.`,
            header: 'Invalid Form',
            icon: 'pi pi-exclamation-triangle',
            key: 'enrollment-submission-confirmation',
            acceptVisible: false,
            rejectVisible: false,
          });
        }
      });
  }

  private mapEnrollmentFormControlNameToPageStepTitle(name: string) {
    switch (name) {
      case 'MerchantAccount':
        return 'Merchant Account';
      case 'LoyaltyProgram':
        return 'Configure Loyalty Program';
      case 'EnrollmentOffer':
        return 'Configure Enrollment Offer';
      case 'Review Questions':
        return 'Review Question';
      default:
        return null;
    }
  }

  private navigateToStep(step: EnrollmentSteps) {
    const queryParams: Params = { [`${step}`]: true };
    this.currentQueryParam = step;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }
}
