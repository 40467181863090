import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OffersRoutingModule } from './offers-routing.module';
import { SharedModule } from '@app/@shared';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { OffersComponent } from './offers.component';
import { OfferTableComponent } from './components/offer-table/offer-table.component';
import { IframeManagerService } from '@shared/services/iframe-manager.service';
import { OfferApiService } from '@app/areas/offers/services/offer.api.service';
import { OfferFormService } from '@app/areas/offers/services/offer.form.service';
import { OfferFormComponent } from './components/offer-form/offer-form.component';
import { OfferReviewComponent } from './components/offer-review/offer-review.component';
import { OfferFormCardComponent } from './components/offer-form-card/offer-form-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { OfferDetailsCardComponent } from './components/offer-details-card/offer-details-card.component';
import { OfferAnalyticsCardComponent } from './components/offer-analytics-card/offer-analytics-card.component';
import { ConfirmationService } from 'primeng/api';
import { OfferDetailsHeaderButtonsComponent } from './components/offer-details-header-buttons/offer-details-header-buttons.component';
import { TooltipModule } from 'primeng/tooltip';
import { OfferDetailsFormComponent } from './forms/offer-details-form/offer-details-form.component';
import { OfferDurationFormComponent } from './forms/offer-duration-form/offer-duration-form.component';
import { OfferSmsReminderControlComponent } from './controls/offer-sms-reminder-control/offer-sms-reminder-control.component';
import { OfferScheduledSmsFormComponent } from '@app/areas/offers/forms/offer-scheduled-sms-form/offer-scheduled-sms-form.component';
import { OfferSmsBodyFormComponent } from './forms/offer-sms-body-form/offer-sms-body-form.component';

@NgModule({
  declarations: [OffersComponent],
  imports: [
    CommonModule,
    OffersRoutingModule,
    SharedModule,
    TableModule,
    ButtonModule,
    OfferTableComponent,
    OfferFormComponent,
    OfferReviewComponent,
    OfferFormCardComponent,
    OfferDetailsComponent,
    OfferDetailsCardComponent,
    OfferAnalyticsCardComponent,
    OfferDetailsHeaderButtonsComponent,
    ReactiveFormsModule,
    TooltipModule,
    OfferDetailsFormComponent,
    OfferDurationFormComponent,
    OfferScheduledSmsFormComponent,
    OfferSmsReminderControlComponent,
    OfferSmsBodyFormComponent,
  ],
  providers: [OfferApiService, OfferFormService, ConfirmationService],
})
export class OffersModule {
  constructor(iframeManagerService: IframeManagerService) {
    iframeManagerService.setShouldShowIframe(false);
  }
}
