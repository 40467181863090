import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { UntilDestroy } from '@shared';
import { LoginRequest } from '@app/@shared/models/login-request.model';
import { CredentialsService } from '..';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { Subscription, tap } from 'rxjs';
import { LoginTypes } from '@shared/enums';
import { Store } from '@ngrx/store';
import { AuthenticationActions, RouterActions } from '@app/resources/ngrx/actions';
import { selectLoginError, selectShowTermsAndConditions } from '@app/resources/ngrx/selectors';
import { isNotNullOrUndefined } from '@ep/shared';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private readonly store = inject(Store);

  fgLogin!: FormGroup;
  fgTermsAndConditionsForm!: FormGroup;
  isLoading = false;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;
  shouldShowTermsAndConditions: boolean = false;

  showTermsAndConditions$ = this.store.select(selectShowTermsAndConditions).pipe(
    tap((showTC) => {
      if (showTC) this.isLoading = false;
    })
  );

  loginError$ = this.store.select(selectLoginError).pipe(
    filter(isNotNullOrUndefined),
    tap(() => {
      this.isLoading = false;
    })
  );

  private subs = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private subdomainService: SubdomainService
  ) {}

  ngOnInit() {
    this.subdomainType = this.subdomainService.getDomain();
    this.createLoginForm();
    this.createTermsAndConditionsForm();
    this.store.dispatch(AuthenticationActions.authenticationShowTermsAndConditions({ payload: { showTC: false } }));
    this.subs.add(this.loginError$.subscribe());
  }

  onLoginClicked(): void {
    const request = this.getLoginRequest();
    if (!request) return;

    this.isLoading = true;
    this.store.dispatch(AuthenticationActions.authenticationLogin({ payload: { request } }));
  }

  onLoginWithTcClicked(): void {
    const request = this.getLoginRequest();
    if (!request) return;

    this.isLoading = true;
    this.store.dispatch(AuthenticationActions.authenticationLoginWithTC({ payload: { request } }));
  }

  private getLoginRequest(): LoginRequest | null {
    return !this.fgLogin.valid
      ? null
      : {
          ...this.fgLogin.value,
          clientAccessIds: [this.credentialsService.credentials?.clientAccessId || ''],
          referenceIdent: '',
          loginType: LoginTypes.Employee,
        };
  }

  onForgotPasswordClicked(): void {
    this.store.dispatch(RouterActions.navigateByUrl({ payload: { path: 'auth/reset-password' } }));
  }

  private createLoginForm() {
    this.fgLogin = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  private createTermsAndConditionsForm() {
    this.fgTermsAndConditionsForm = this.formBuilder.group({
      termsAndConditions: [false, Validators.pattern('true')],
      privacyPolicy: [false, Validators.pattern('true')],
      merchantFunded: [false, Validators.pattern('true')],
      monthlySubscription: [false, Validators.pattern('true')],
    });
  }
}
