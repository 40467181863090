import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseApiService {
  private readonly emailEndpoint = '/v2/Emails';

  findUnique(email: string) {
    return this.get(`${this.emailEndpoint}/check`, {
      email: email,
    }).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
}
