import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Credentials } from '@shared/models';

enum CredentialsActionTypes {
  INITIALIZE_CREDENTIALS = 'Initialize Credentials',
  UPDATE_CREDENTIALS = 'Update Credentials',
  UPDATE_CREDENTIALS_SUCCESS = 'Update Credentials Success',
  CLEAR_CREDENTIALS = 'Clear Credentials',
  CLEAR_CREDENTIALS_SUCCESS = 'Clear Credentials Success',
}

export const CredentialsActions = createActionGroup({
  source: 'Credentials',
  events: {
    [CredentialsActionTypes.INITIALIZE_CREDENTIALS]: props<{ payload: { credentials: Credentials } }>(),
    [CredentialsActionTypes.UPDATE_CREDENTIALS]: props<{ payload: { credentials: Credentials | null } }>(),
    [CredentialsActionTypes.UPDATE_CREDENTIALS_SUCCESS]: props<{ payload: { credentials: Credentials | null } }>(),
    [CredentialsActionTypes.CLEAR_CREDENTIALS]: emptyProps(),
    [CredentialsActionTypes.CLEAR_CREDENTIALS_SUCCESS]: emptyProps(),
  },
});
