import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, Observable, of } from 'rxjs';
import { MerchantPaymentTypes } from '@shared/enums';
import { map } from 'rxjs/operators';

export interface MerchantContactInformation {
  PhoneNumber: string;
  FirstName: string;
  LastName: string;
  EmailAddress: string;
  PosIdent: string;
}

export interface MerchantBusinessInformation {
  Name: string;
  Address: string;
  PhoneNumber: string;
  TimeZone: number; // please replace string with the actual TypeScript type for your TimeZone
}

interface EnrollmentPricingInfo {
  Base: number;
  PerCampaign: number;
  PerDevice: number;
  PerLocation: number;
  PerSMS: number;
  MerchantPaymentType: MerchantPaymentTypes;
  MaxFreeEnrollments?: number;
  PrepaidCampaigns: number;
}

export interface MerchantAccount {
  ContactInformation?: MerchantContactInformation | null;
  BusinessInformation?: MerchantBusinessInformation | null;
}

export interface EnrollmentCashBackProgram {
  Name: string;
  Percentage: number;
}

export interface EnrollmentPointsProgram {
  Name: string;
  QualifierAmount: number;
  PointsEarned: number;
  RewardQualifier: number;
  RewardAmount: number;
}

export interface EnrollmentLoyaltyProgram {
  CashBack?: EnrollmentCashBackProgram | null;
  Points?: EnrollmentPointsProgram | null;
}

export interface EnrollmentOfferInstant {
  RewardPercentage: number | null;
  MaxRewardAmount: number | null;
}

export interface EnrollmentOfferBonus {
  RewardPercentage: number | null;
  MaxRewardAmount: number | null;
}

export interface EnrollmentOffer {
  InstantOffer?: EnrollmentOfferInstant | null;
  BonusOffer?: EnrollmentOfferBonus | null;
}

export interface ReviewQuestions {
  Question: string;
}

export interface UpdateEnrollmentRequest {
  EnrollmentId: string;
  MerchantAccount: MerchantAccount;
  LoyaltyProgram: EnrollmentLoyaltyProgram;
  EnrollmentOffer: EnrollmentOffer;
  ReviewQuestions?: ReviewQuestions | null;
}

export interface EnrollmentData {
  CoalitionId: number;
  SalesRepresentativeId: number;
  MerchantEmail: string;
  PricingInformation: EnrollmentPricingInfo;
  BusinessInformation?: MerchantBusinessInformation | null;
  ContactInformation?: MerchantContactInformation | null;
  CashBackProgram?: EnrollmentCashBackProgram | null;
  PointsProgram?: EnrollmentPointsProgram | null;
  InstantOffer?: EnrollmentOfferInstant | null;
  BonusOffer?: EnrollmentOfferBonus | null;
  ReviewQuestions?: ReviewQuestions | null;
}

export interface EnrollmentResponse {
  EnrollmentId: number;
  Data?: EnrollmentData | null;
  IsComplete: boolean;
}

export interface CompleteEnrollmentResponse {
  success?: boolean;
  error?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService extends BaseApiService {
  private readonly enrollmentEndpoint = `/v2/Enrollments`;
  find(enrollmentId: string) {
    return this.get<EnrollmentResponse>(`${this.enrollmentEndpoint}/${enrollmentId}/data`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      }),
      map(MapEnrollmentResponseToEnrollmentData)
    );
  }

  update(updateEnrollmentRequest: UpdateEnrollmentRequest) {
    return this.patch(`${this.enrollmentEndpoint}`, updateEnrollmentRequest).pipe(
      catchError((err) => {
        console.error(err);
        return of({ error: true });
      })
    );
  }

  complete(enrollmentId: string): Observable<CompleteEnrollmentResponse | null> {
    return this.get<CompleteEnrollmentResponse>(`${this.enrollmentEndpoint}/${enrollmentId}/complete`).pipe(
      map(() => {
        return { success: true };
      }),
      catchError((err) => {
        console.error(err);
        return of({ error: true });
      })
    );
  }
}

function MapEnrollmentResponseToEnrollmentData(response: any) {
  let enrollmentData: EnrollmentData | null = null;

  if (response.data) {
    enrollmentData = {
      CoalitionId: response.data.coalitionId,
      SalesRepresentativeId: response.data.salesRepresentativeId,
      MerchantEmail: response.data.merchantEmail,
      PricingInformation: {
        Base: response.data.pricingInformation.base,
        PerCampaign: response.data.pricingInformation.perCampaign,
        PerDevice: response.data.pricingInformation.perDevice,
        PerLocation: response.data.pricingInformation.perLocation,
        PerSMS: response.data.pricingInformation.perSMS,
        MerchantPaymentType: response.data.pricingInformation.merchantPaymentType,
        MaxFreeEnrollments: response.data.pricingInformation.maxFreeEnrollments,
        PrepaidCampaigns: response.data.pricingInformation.mrepaidCampaigns,
      },
      BusinessInformation: null,
      ContactInformation: null,
    };

    if (response.data.businessInformation) {
      enrollmentData.BusinessInformation = {
        Name: response.data.businessInformation.name,
        Address: response.data.businessInformation.address,
        PhoneNumber: response.data.businessInformation.phoneNumber,
        TimeZone: response.data.businessInformation.timeZone,
      };
    }

    if (response.data.contactInformation) {
      enrollmentData.ContactInformation = {
        PhoneNumber: response.data.contactInformation.phoneNumber,
        FirstName: response.data.contactInformation.firstName,
        LastName: response.data.contactInformation.lastName,
        EmailAddress: response.data.contactInformation.emailAddress,
        PosIdent: response.data.contactInformation.posIdent,
      };
    }

    if (response.data.cashBackProgram) {
      enrollmentData.CashBackProgram = {
        Name: response.data.cashBackProgram.name,
        Percentage: response.data.cashBackProgram.percentage,
      };
    } else if (response.data.pointsProgram) {
      enrollmentData.PointsProgram = {
        Name: response.data.pointsProgram.name,
        QualifierAmount: response.data.pointsProgram.qualifierAmount,
        PointsEarned: response.data.pointsProgram.pointsEarned,
        RewardQualifier: response.data.pointsProgram.rewardQualifier,
        RewardAmount: response.data.pointsProgram.rewardAmount,
      };
    }

    if (response.data.instantOffer) {
      enrollmentData.InstantOffer = {
        RewardPercentage: response.data.instantOffer.rewardPercentage,
        MaxRewardAmount: response.data.instantOffer.maxRewardAmount,
      };
    } else if (response.data.bonusOffer) {
      enrollmentData.BonusOffer = {
        RewardPercentage: response.data.bonusOffer.rewardPercentage,
        MaxRewardAmount: response.data.bonusOffer.maxRewardAmount,
      };
    }

    if (response.data.reviewQuestions) {
      enrollmentData.ReviewQuestions = {
        Question: response.data.reviewQuestions.question,
      };
    }
  }

  const enrollmentResponse: EnrollmentResponse = {
    EnrollmentId: response.enrollmentId,
    Data: enrollmentData,
    IsComplete: response.isComplete,
  };

  return enrollmentResponse;
}
