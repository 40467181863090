import { Component, inject, OnInit } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { NgIf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { WindowService } from '@app/resources/services/window.service';
import { EnrollmentOfferBonusFormGroup } from '@app/enroll/services';
import { PercentageControlComponent, PriceControlComponent } from '@ep/shared';
import {
  ENROLLMENT_BONUS_OFFER_CALCULATED_MAX_REWARD_HELP,
  ENROLLMENT_BONUS_OFFER_CALCULATED_PERCENTAGE_HELP,
} from '@app/enroll/utils/constants';

type BonusOfferControlHelpType = 'percentage' | 'max-reward';

@Component({
  selector: 'app-enrollment-offer-bonus-form',
  templateUrl: './enrollment-offer-bonus-form.component.html',
  styleUrls: ['./enrollment-offer-bonus-form.component.scss'],
  imports: [
    DialogModule,
    NgIf,
    PaginatorModule,
    ReactiveFormsModule,
    PercentageControlComponent,
    PriceControlComponent,
  ],
  standalone: true,
})
export class EnrollmentOfferBonusFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  private readonly windowService = inject(WindowService);
  protected bonusOfferForm: EnrollmentOfferBonusFormGroup | null = null;
  protected isDialogVisible = false;

  protected percentageHelpText = ENROLLMENT_BONUS_OFFER_CALCULATED_PERCENTAGE_HELP;
  protected maxRewardHelpText = ENROLLMENT_BONUS_OFFER_CALCULATED_MAX_REWARD_HELP;
  private helpIndex: BonusOfferControlHelpType = 'percentage';
  private helpDialog: { [index: string]: string } = {
    percentage: this.percentageHelpText,
    ['max-reward']: this.maxRewardHelpText,
  };

  ngOnInit() {
    this.bonusOfferForm = this.controlContainer.control as EnrollmentOfferBonusFormGroup;
  }

  get isXSmallScreen() {
    return this.windowService.isXSmallScreen;
  }

  protected showHelp(controlHelp: BonusOfferControlHelpType) {
    this.helpIndex = controlHelp;
    this.isDialogVisible = true;
  }

  protected getHelpDialog() {
    return this.helpDialog[this.helpIndex];
  }
}
