import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, tap } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { CompleteEnrollmentResponse } from '@app/resources/services/enrollment.service';

@Component({
  selector: 'app-enrollment-submission',
  templateUrl: './enrollment-submission.component.html',
  styleUrls: ['./enrollment-submission.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class EnrollmentSubmissionComponent implements OnInit, OnDestroy {
  @Input() enrollmentSubmissionObservable: Observable<any> | null = null;

  protected isSubmitting = true;
  protected isSuccess: boolean | null = null;
  protected isFailure: boolean | null = null;
  private subs = new Subscription();

  ngOnInit() {
    if (this.enrollmentSubmissionObservable) {
      this.enrollmentSubmissionObservable
        .pipe(
          tap((response: CompleteEnrollmentResponse | null) => {
            if (!!response && response.success) {
              this.isSuccess = true;
            } else {
              this.isFailure = true;
            }
            this.isSubmitting = false;
          })
        )
        .subscribe();
    }
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
