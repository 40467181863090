import { Component, inject, OnInit } from '@angular/core';
import { OfferDetailsCardComponent } from '../offer-details-card/offer-details-card.component';
import { OfferAnalyticsCardComponent } from '../offer-analytics-card/offer-analytics-card.component';
import { OfferApiService } from '@app/areas/offers/services/offer.api.service';
import { catchError, forkJoin, of, Subscription, take, tap } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Offer, OfferMetadata } from '@app/resources/services';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { OfferFormCardComponent } from '@app/areas/offers/components/offer-form-card/offer-form-card.component';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { OfferStatusTypes } from '@shared/enums';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OfferFormService } from '@app/areas/offers/services/offer.form.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LoadingSpinnerDirective } from '@ep/shared';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ApplicationProfileService } from '@shared/services/application-profile.service';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    OfferDetailsCardComponent,
    OfferAnalyticsCardComponent,
    NgSwitchCase,
    NgSwitchDefault,
    OfferFormCardComponent,
    NgSwitch,
    ButtonModule,
    CardModule,
    TooltipModule,
    ConfirmDialogModule,
    LoadingSpinnerDirective,
    FaIconComponent,
  ],
})
export class OfferDetailsComponent implements OnInit {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly confirmationService = inject(ConfirmationService);
  private readonly offerApiService = inject(OfferApiService);
  private readonly offerFormService = inject(OfferFormService);
  private readonly route = inject(ActivatedRoute);
  private readonly messageService = inject(MessageService);
  private readonly router = inject(Router);
  private readonly timezoneService = inject(TimezoneService);
  private subs = new Subscription();

  protected icons = FONT_AWESOME_ICONS;
  protected action: 'edit' | 'duplicate' | 'view' = 'view';
  protected offerMetadata: OfferMetadata = {
    NumberOfTextsSent: 0,
    PercentUsed: 0,
    OfferRevenue: 0,
    AverageSpend: 0,
  };

  protected offer: Offer | null = null;
  protected isLoading = true;

  ngOnInit(): void {
    this.subs.add(
      this.route.url
        .pipe(
          first(),
          switchMap((segments) => {
            const path = segments.pop()?.path;
            if (path) {
              const id = parseInt(path);
              if (isNaN(id)) {
                console.error('Invalid id parameter');
                this.router.navigate(['not-found']);
              }
              return forkJoin([this.offerApiService.getOfferById(id), this.offerApiService.getOfferMetadataById(id)]);
            }
            return of(null);
          }),
          map((result: null | [Offer | null, OfferMetadata]) => {
            if (result && result[0]) {
              this.offer = {
                ...result[0],
                startDateTime: this.timezoneService.reformatOfferDate(result[0].startDateTime).toDate(),
                endDateTime: this.timezoneService.reformatOfferDate(result[0].endDateTime).toDate(),
                sendTime: this.timezoneService
                  .moment(result[0].sendTime)
                  .add(this.timezoneService.getLocationClientOffset(result[0].sendTime), 'hours')
                  .toDate(),
              };
              this.offerMetadata = result[1];
              this.isLoading = false;
            } else {
              console.error('Could not get offer details');
              this.router.navigate(['not-found']);
            }
          })
        )
        .subscribe()
    );

    this.subs.add(
      this.route.queryParamMap
        .pipe(
          tap((queryParams) => {
            if (queryParams.get('edit')) this.action = 'edit';
            else if (queryParams.get('duplicate')) this.action = 'duplicate';
            else this.action = 'view';
          })
        )
        .subscribe()
    );
  }

  getPartialOffer(): Partial<Offer> {
    return {
      namePrivate: this.offer?.namePrivate,
      receiveAmount: this.offer?.receiveAmount,
    };
  }

  updateRoute = (queryParam?: string) => {
    const routeExtras: NavigationExtras = {
      relativeTo: this.route,
    };
    if (!!queryParam) {
      routeExtras['queryParams'] = {
        [queryParam]: true,
      };

      routeExtras['queryParamsHandling'] = 'merge';
    }
    if (!!!queryParam && this.offerFormService.getOfferForm().dirty && this.offerFormService.getOfferForm().touched) {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'offer-confirm',
        accept: () => {
          if (!!queryParam)
            this.router.navigateByUrl(`${this.getBaseOfferPath()}/${this.offer?.offerId}?${queryParam}=true`);
          else this.router.navigateByUrl(`${this.getBaseOfferPath()}/${this.offer?.offerId}`);
        },
      });
    } else {
      if (!!queryParam)
        this.router.navigateByUrl(`${this.getBaseOfferPath()}/${this.offer?.offerId}?${queryParam}=true`);
      else this.router.navigateByUrl(`${this.getBaseOfferPath()}/${this.offer?.offerId}`);
    }
  };

  get isPendingTextNotSent() {
    return this.offer?.statusType === OfferStatusTypes.PendingNoText;
  }

  get doNotShowWarningBanner() {
    return (
      (this.offer?.statusType === OfferStatusTypes.PendingNoText && this.action) ||
      ((this.offer?.statusType === OfferStatusTypes.Suspended || this.offer?.statusType === OfferStatusTypes.Deleted) &&
        this.action === 'view') ||
      (this.offer?.statusType !== OfferStatusTypes.PendingNoText && this.action === 'duplicate')
    );
  }

  protected endOffer() {
    if (this.offer) {
      this.isLoading = true;
      this.confirmationService.confirm({
        message: 'You are about to cancel this offer. Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key: 'offer-cancel',
        accept: () => {
          this.offerApiService
            .cancelOffer(this.offer!.offerId!)
            .pipe(
              catchError((err) => {
                console.error(err);
                this.messageService.add({
                  severity: 'danger',
                  summary: 'Error: Cancel Offer',
                  detail: 'Something went wrong when cancelling the offer',
                });
                return of({ error: true });
              }),
              tap((result: any) => {
                this.isLoading = false;
                if (typeof result !== 'object' || !result?.error) {
                  this.messageService.add({
                    severity: 'info',
                    summary: 'Update: Cancel Offer',
                    detail: 'Successfully cancelled the offer',
                  });
                  this.goBackToOffers();
                }
              })
            )
            .subscribe();
        },
        reject: () => {
          this.isLoading = false;
        },
      });
    }
  }

  protected goBackToOffers() {
    this.router.navigateByUrl(this.getBaseOfferPath());
  }

  private getBaseOfferPath() {
    const locationId = this.applicationProfileService.getApplicationProfile().Location.LocationId;
    return `/${locationId}/offers`;
  }
}
