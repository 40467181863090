import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MembersRoutingModule } from './members-routing.module';
import { MembersComponent } from './members.component';
import { SharedModule } from '@app/@shared';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [MembersComponent],
  imports: [CommonModule, MembersRoutingModule, SharedModule, TableModule, ButtonModule],
})
export class MembersModule {}
