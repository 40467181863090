import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Question } from '@app/resources/services';
import { QuestionStatusTypes } from '@shared/enums';

enum QuestionActionTypes {
  UPSERT_QUESTION_SUCCESS = 'Upsert Question Success',
  UPSERT_QUESTION_ERROR = 'Upsert Question Error',
  UPSERT_MANY_QUESTION_SUCCESS = 'Upsert Many Question Success',
  UPSERT_MANY_QUESTION_ERROR = 'Upsert Many Question Error',
  LOAD_QUESTIONS = 'Load Questions',
  LOAD_QUESTIONS_SUCCESS = 'Load Questions Success',
  LOAD_QUESTIONS_ERROR = 'Load Questions Error',
  SELECT_QUESTION = 'Question By Id',
}

export const QuestionActions = createActionGroup({
  source: 'Question',
  events: {
    [QuestionActionTypes.LOAD_QUESTIONS]: emptyProps(),
    [QuestionActionTypes.LOAD_QUESTIONS_SUCCESS]: props<{
      payload: { questions: Question[] };
    }>(),
    [QuestionActionTypes.LOAD_QUESTIONS_ERROR]: props<{ payload: { error: string } }>(),
    [QuestionActionTypes.UPSERT_QUESTION_SUCCESS]: props<{
      payload: { question: Question };
    }>(),
    [QuestionActionTypes.UPSERT_QUESTION_ERROR]: props<{ payload: { error: string } }>(),
    [QuestionActionTypes.UPSERT_MANY_QUESTION_SUCCESS]: props<{
      payload: { questions: Question[] };
    }>(),
    [QuestionActionTypes.UPSERT_MANY_QUESTION_ERROR]: props<{ payload: { error: string } }>(),
    [QuestionActionTypes.SELECT_QUESTION]: props<{ payload: { questionId: number } }>(),
  },
});
