import { Injectable } from '@angular/core';
import { SubdomainTypes } from '../enums/subdomain-types.enum';

@Injectable({
  providedIn: 'root',
})
export class SubdomainService {
  constructor() {}

  getDomain(): SubdomainTypes | null {
    const host = window.location.hostname;
    switch (host) {
      case SupportedHostNames.EagleProcessing:
        return SubdomainTypes.EagleProcessing;
      case SupportedHostNames.EnrollAndPay:
        return SubdomainTypes.EnrollAndPay;
      default:
        return SubdomainTypes.EnrollAndPay;
    }
  }
}

enum SupportedHostNames {
  EagleProcessing = 'merchant.eagleprocessingrewards.com',
  EnrollAndPay = 'portal.enrollandpay.com',
}
