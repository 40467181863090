<div *appLoading="!offer || isLoading" data-cy="offer-details" style="height: 100%">
  <div id="warning-offer-banner" *ngIf="!doNotShowWarningBanner" data-cy="offer-warning-banner">
    Offer is not editable since the offer text has been sent out, the offer is currently active, or the offer has been
    cancelled
  </div>
  <div *ngIf="action === 'view'" style="height: 50px">
    <p-button
      (onClick)="goBackToOffers()"
      [style]="{ height: '100%' }"
      id="offers-back-button"
      data-cy="go-back-offers-button"
    >
      <fa-icon [icon]="icons['faArrowLeft']" size="lg" style="color: #213246"></fa-icon>
    </p-button>
  </div>
  <div [ngSwitch]="action" id="offer-details-page">
    <div *ngSwitchCase="'view'" id="offer-details-view" data-cy="offer-details-view">
      <div id="offer-details-info">
        <app-offer-details-card
          id="offer-details-card"
          [offer]="offer"
          (duplicateOfferEvent)="updateRoute('duplicate')"
          (editOfferEvent)="updateRoute('edit')"
          data-cy="offer-form-details-card"
        ></app-offer-details-card>
        <app-offer-analytics-card
          id="offer-analytics-card"
          [offerMetadata]="offerMetadata"
          data-cy="offer-form-analytics-card"
        ></app-offer-analytics-card>
      </div>
      <p-card *ngIf="isPendingTextNotSent">
        <div id="offer-details-end-button-wrapper">
          <p-button
            id="end-offer-button"
            label="Cancel Offer"
            size="large"
            (onClick)="endOffer()"
            data-cy="cancel-offer-button"
          ></p-button>
        </div>
      </p-card>
    </div>
    <div *ngSwitchCase="'edit'" class="full-height-width">
      <app-offer-form-card
        [offer]="offer"
        action="edit"
        data-cy="offer-form-edit"
        [cancelFn]="updateRoute"
      ></app-offer-form-card>
    </div>
    <div *ngSwitchCase="'duplicate'" class="full-height-width">
      <app-offer-form-card
        [offer]="getPartialOffer()"
        action="add"
        [cancelFn]="updateRoute"
        data-cy="offer-form-duplicate"
      ></app-offer-form-card>
    </div>
  </div>
</div>
<p-confirmDialog
  class="offer-cancel-confirmation"
  key="offer-cancel"
  [style]="{ width: '30vw' }"
  #cf
  data-cy="cancel-offer-dialog"
>
</p-confirmDialog>
