export interface DailySaleOverview {
  DailySaleOverviewId: number;
  DailyDate: Date | string;
  LoyaltyProgramId?: number;
  MerchantId?: number;
  LocationId?: number;
  TotalEnrollmentOrderCount: number;
  TotalMemberOrderCount: number;
  TotalNonMemberOrderCount: number;
  TotalEnrollmentSpendAmount: number;
  TotalMemberSpendAmount: number;
  TotalNonMemberSpendAmount: number;
  EnrollmentCount: number;
  DeclinedCount: number;
  AssignedCount: number;
  OptInToOfferCount: number;
  TotalQuestionAnswerCount: number;
  TotalQuestionAnswerScore: number;
  TotalRewardEarnedOrderCount: number;
  TotalRewardSpentOrderCount: number;
  TotalRewardEarnedAmount: number;
  TotalRewardSpentAmount: number;
  TotalRewardEarnedOrderAmount: number;
  TotalRewardSpentOrderAmount: number;
  TotalDiscountOrderCount: number;
  TotalDiscountOrderAmount: number;
  TotalDiscountCount: number;
  TotalDiscountAmount: number;
  TrendingDate?: Date;
  TrendingType?: number;
  Ts?: number[];
}

export interface DailySaleOverviewResponseData {
  dailySaleOverviewId: number;
  dailyDate: Date;
  loyaltyProgramId?: number;
  merchantId?: number;
  locationId?: number;
  totalEnrollmentOrderCount: number;
  totalMemberOrderCount: number;
  totalNonMemberOrderCount: number;
  totalEnrollmentSpendAmount: number;
  totalMemberSpendAmount: number;
  totalNonMemberSpendAmount: number;
  enrollmentCount: number;
  declinedCount: number;
  assignedCount: number;
  optInToOfferCount: number;
  totalQuestionAnswerCount: number;
  totalQuestionAnswerScore: number;
  totalRewardEarnedOrderCount: number;
  totalRewardSpentOrderCount: number;
  totalRewardEarnedAmount: number;
  totalRewardSpentAmount: number;
  totalRewardEarnedOrderAmount: number;
  totalRewardSpentOrderAmount: number;
  totalDiscountOrderCount: number;
  totalDiscountOrderAmount: number;
  totalDiscountCount: number;
  totalDiscountAmount: number;
  trendingDate?: Date;
  trendingType?: number;
  ts?: number[];
}

export interface DailySaleOverviewResponse {
  $id: number;
  $values: DailySaleOverviewResponseData[];
}
