<div class="daily-date-panel-view-wrapper">
  <div>
    <app-dashboard-loyalty-program-panel
      [data$]="loyaltyProgramDataViewCardConfig$"
    ></app-dashboard-loyalty-program-panel>
  </div>
  <div class="full-width daily-date-sales-panel">
    <app-dashboard-custom-search *ngIf="showCustomSearch"></app-dashboard-custom-search>
    <app-dashboard-overview-panel
      header="SALES OVERVIEW"
      [data$]="dailySalesOverview$"
      [showChartData]="showChartData"
      [graphData$]="dashboardGraphData$"
    ></app-dashboard-overview-panel>
  </div>
  <div class="full-width">
    <app-dashboard-overview-panel
      header="LOYALTY OVERVIEW"
      [data$]="dailyLoyaltyOverview$"
    ></app-dashboard-overview-panel>
  </div>
</div>
