<ep-auth-container
  [imageSrc]="
    subdomainType === SubdomainTypes.EagleProcessing
      ? '../../assets/eagle-logo.png'
      : '../../assets/enroll-and-pay-logo.png'
  "
>
  <div *appLoading="isLoading" id="enrollment-stepper-wrapper">
    <app-enrollment-stepper style="width: 100%"></app-enrollment-stepper>
  </div>
</ep-auth-container>
