<div *ngIf="cashBackForm">
  <form [formGroup]="cashBackForm" class="cash-back-form" data-cy="cash-back-form">
    <div class="cash-back-form-row">
      <ep-simple-text-control
        formControlName="Name"
        label="Program Name"
        data-cy="loyalty-program-cashback-name"
        class="cash-back-control-width"
      ></ep-simple-text-control>
      <p *ngIf="!isXSmallScreen; else infoName" class="cash-back-control-info-width">
        <i>{{ nameHelpText }}</i>
      </p>
      <ng-template #infoName>
        <i class="pi pi-info-circle help-icon" (click)="showHelp('name')" data-cy="cashback-name-help"></i>
      </ng-template>
    </div>
    <div class="cash-back-form-row">
      <ep-percentage-control
        formControlName="CashBackPercentage"
        label="Cash Back Percentage"
        data-cy="loyalty-program-cashback-percentage"
        class="cash-back-control-width"
      ></ep-percentage-control>
      <p *ngIf="!isXSmallScreen; else infoPercentage" class="cash-back-control-info-width">
        <i
          >This is the percentage of the customer's purchase that will be given back to the customer to be spent on
          future transactions</i
        >
      </p>
      <ng-template #infoPercentage>
        <i class="pi pi-info-circle help-icon" (click)="showHelp('percentage')" data-cy="cashback-percentage-help"></i>
      </ng-template>
    </div>
  </form>
  <p-dialog
    [(visible)]="isDialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ 'max-width': '400px' }"
    data-cy="cash-back-form-dialog"
  >
    <p class="m-0 cash-back-form-dialog-content" data-cy="cash-back-help-dialog-content">
      <i>{{ getHelpDialog() }}</i>
    </p>
  </p-dialog>
</div>
