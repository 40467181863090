import { EmployeeAccessTypes } from '@shared/enums';

export const DeviceAdmin: EmployeeAccessTypes =
  EmployeeAccessTypes.DeviceAdmin | EmployeeAccessTypes.PayAtTablePartial | EmployeeAccessTypes.PayAtTableFull;

export const LocationAdmin: EmployeeAccessTypes =
  DeviceAdmin |
  EmployeeAccessTypes.LocationAdmin |
  EmployeeAccessTypes.OfferAdmin |
  EmployeeAccessTypes.OrderAdmin |
  EmployeeAccessTypes.LoyaltyProgramAdmin |
  EmployeeAccessTypes.QuestionAdmin |
  EmployeeAccessTypes.ReportAdmin |
  EmployeeAccessTypes.ConsumerAccess |
  EmployeeAccessTypes.ConsumerReward |
  EmployeeAccessTypes.SaleOverview |
  EmployeeAccessTypes.ProductAdmin |
  EmployeeAccessTypes.TabletopAdmin;

export const FullAdmin: EmployeeAccessTypes =
  LocationAdmin |
  EmployeeAccessTypes.EmployeeAdmin |
  EmployeeAccessTypes.BillingAdmin |
  EmployeeAccessTypes.SendReceiveMessages;
