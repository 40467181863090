export interface Representative {
  $id: string;
  RepresentativeId: number;
  CoalitionId: number;
  NameFirst: string;
  NameLast: string;
  AccessType: number;
  StatusType: number;
  LanguageType: number;
  RepresentativeType: number;
}
