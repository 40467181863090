import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { NgIf } from '@angular/common';
import { TooltipOptions } from 'primeng/api';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-offer-details-header-buttons',
  templateUrl: './offer-details-header-buttons.component.html',
  styleUrls: ['./offer-details-header-buttons.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonModule, TooltipModule, FaIconComponent],
})
export class OfferDetailsHeaderButtonsComponent {
  @Input() isPendingTextNotSent = false;
  @Output() duplicateOfferEvent = new EventEmitter(false);
  @Output() editOfferEvent = new EventEmitter(false);

  protected tooltipOptions: TooltipOptions = {
    tooltipPosition: 'top',
  };

  protected icons = FONT_AWESOME_ICONS;

  protected duplicateOffer() {
    this.duplicateOfferEvent.emit(true);
  }

  protected editOffer() {
    this.editOfferEvent.emit(true);
  }
}
