import { Routes, Route } from '@angular/router';
import { AuthenticationGuard } from '@app/auth';
import { ShellComponent } from './shell.component';
import { GetApplicationProfileResolver } from '@app/@shared/resolvers/get-application-profile.resolver';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */

  constructor() {}
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: [...routes],
      canActivate: [AuthenticationGuard],
      resolve: {
        applicationProfile: GetApplicationProfileResolver,
      },
      data: {
        reuse: true,
      },
    };
  }
}
