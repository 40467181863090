import { Component } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-enrollment-complete',
  templateUrl: './enrollment-complete.component.html',
  styleUrls: ['./enrollment-complete.component.scss'],
  imports: [NgIf],
  standalone: true,
})
export class EnrollmentCompleteComponent {
  protected readonly redirectLink = 'auth/login';
}
