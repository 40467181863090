export const ENROLLMENT_LOYALTY_PROGRAM_NAME_HELP = 'Best to keep it short (e.g., Panera Rewards or Sprinkle Perks)';
export const ENROLLMENT_INSTANT_OFFER_CALCULATED_PERCENTAGE_HELP =
  "This is the percentage of the customer's transaction that will be provided as a reward on their current transaction.";
export const ENROLLMENT_BONUS_OFFER_CALCULATED_PERCENTAGE_HELP =
  "This is the percentage of the customer's transaction that will be provided as a reward for future transactions.";
export const ENROLLMENT_INSTANT_OFFER_CALCULATED_MAX_REWARD_HELP =
  'If you want to limit the instant reward amount, specify the max threshold that you would like to cap the reward at.';

export const ENROLLMENT_BONUS_OFFER_CALCULATED_MAX_REWARD_HELP =
  'If you want to limit the bonus reward amount, specify the max threshold that you would like to cap the reward at.';
