export enum EmployeeAccessTypes {
  DeviceAdmin = 1,
  EmployeeAdmin = 2,
  LocationAdmin = 4,
  OfferAdmin = 8,
  ConsumerAccess = 16,
  ConsumerReward = 32, //can employee issue rewards to a consumer directly.
  SaleOverview = 64,
  OrderAdmin = 128,
  ProductAdmin = 256,
  LoyaltyProgramAdmin = 512,
  QuestionAdmin = 1024,
  BillingAdmin = 2048,
  SendReceiveMessages = 4096,
  TabletopAdmin = 8192,
  ReportAdmin = 16384,
  PayAtTableFull = 32768,
  PayAtTablePartial = 65536,
}
