import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  AuthenticationActions,
  AuthenticationLoginErrorReturnType,
  AuthenticationLoginSuccessActionReturnType,
  CredentialsActions,
  HydrationActions,
  MessagingActions,
  RouterActions,
} from '@app/resources/ngrx/actions';
import { AuthenticationService } from '@shared/services/authentication.service';
import { catchError, EMPTY, Observable, of, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationProfile, Credentials, LoginResponse, ResponseMessagesTypes } from '@shared/models';
import { LoginStatusTypes } from '@shared/enums';
import { ApplicationProfileService } from '@shared/services/application-profile.service';

@Injectable()
export class AuthenticationEffects {
  private readonly store = inject(Store);
  private readonly action$ = inject(Actions);
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly authenticationService = inject(AuthenticationService);

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthenticationActions.authenticationLogin),
      switchMap(({ payload }) => {
        return this.authenticationService.login(payload.request).pipe(this.loginPipe());
      })
    )
  );

  loginWithTC$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthenticationActions.authenticationLoginWithTC),
      switchMap(({ payload }) => {
        return this.authenticationService.loginWithTc(payload.request).pipe(this.loginPipe());
      })
    )
  );

  logout$ = createEffect(() =>
    this.action$.pipe(
      ofType(AuthenticationActions.authenticationLogout),
      switchMap(() => {
        return this.authenticationService.logout().pipe(
          tap(() => {
            this.applicationProfileService.setApplicationProfile(null);
            this.store.dispatch(CredentialsActions.clearCredentials());
            this.store.dispatch(
              RouterActions.navigateByUrl({
                payload: {
                  path: '/auth/login',
                  extras: {
                    replaceUrl: true,
                  },
                },
              })
            );
            this.store.dispatch(HydrationActions.clear());
          }),
          map(() => AuthenticationActions.authenticationLogoutSuccess()),
          catchError((error: { message: string }) => {
            const message = {
              severity: 'error',
              summary: `ERROR: Log Out`,
              detail: `Failed to logout of account.`,
            };
            this.store.dispatch(MessagingActions.createMessage({ payload: { message } }));
            return of(AuthenticationActions.authenticationLogoutError({ payload: { error: error.message } }));
          })
        );
      })
    )
  );

  private loginPipe(): (
    source: Observable<LoginResponse>
  ) => Observable<AuthenticationLoginSuccessActionReturnType | AuthenticationLoginErrorReturnType | never> {
    return (source) =>
      source.pipe(
        map((response: LoginResponse) => {
          if (this.hasInvalidUsernamePasswordError(response)) throw new Error('Invalid username or password');
          return response;
        }),
        tap((response) => {
          if (response.LoginStatus !== LoginStatusTypes.TCFlag) this.store.dispatch(HydrationActions.clear());
        }),
        switchMap((response) => {
          if (response.LoginStatus === LoginStatusTypes.TCFlag) {
            this.store.dispatch(
              AuthenticationActions.authenticationShowTermsAndConditions({ payload: { showTC: true } })
            );
            return EMPTY;
          }

          this.saveCredentials(response);
          return this.authenticationService.getApplicationProfile().pipe(
            take(1),
            tap((applicationProfile: ApplicationProfile) => {
              this.applicationProfileService.setApplicationProfile(applicationProfile);
              const path = `${applicationProfile.Location.LocationId}/dashboard`;
              this.store.dispatch(RouterActions.navigateByUrl({ payload: { path } }));
            }),
            map(() => AuthenticationActions.authenticationLoginSuccess())
          );
        }),
        catchError((error: { message: string }) => {
          const message = {
            severity: 'error',
            summary: `ERROR: Failed Login`,
            detail: `Couldn't login with the given username and password`,
          };
          this.store.dispatch(MessagingActions.createMessage({ payload: { message } }));
          return of(AuthenticationActions.authenticationLoginError({ payload: { error: error.message } }));
        })
      );
  }

  private saveCredentials(loginResponse: LoginResponse): void {
    const credentials: Credentials = {
      clientAccessId: loginResponse.ClientAccessId,
      jwt: loginResponse.JWT,
    };
    this.store.dispatch(CredentialsActions.updateCredentials({ payload: { credentials } }));
  }

  private hasInvalidUsernamePasswordError(loginResponse: LoginResponse): boolean {
    return (
      loginResponse.ResponseMessages &&
      loginResponse.ResponseMessages.includes(ResponseMessagesTypes.InvalidUsernamePassword)
    );
  }
}
