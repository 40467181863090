<p-card
  *ngIf="config"
  [style]="{
    color: 'white',
    'background-color': config.backgroundColor,
    'background-image': 'url(' + config.pathToImg + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'bottom right',
    width: '100%',
    height: '100%'
  }"
  [ngClass]="{ zoom: !!config.zoomOnHover }"
>
  <div class="view-card-content">
    <span style="font-weight: 500"> {{ config.subheader }} </span>
    <div class="view-card-header-row">
      <span class="view-card-header text-overflow">{{ config.header }}</span>
      <span *ngIf="config.headerSubtext" style="font-weight: 500"> {{ config.headerSubtext }} </span>
    </div>
    <span> {{ config.text }} </span>
  </div>
</p-card>
