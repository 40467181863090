<div>
  <form [formGroup]="loyaltyProgramForm" class="program-option-panels" data-cy="choose-loyalty-program-form">
    <div
      *ngFor="let program of availablePrograms"
      class="program-option-card"
      [ngClass]="{ 'selected-option': program.value === selectedProgram }"
    >
      <p-card
        class="full-width"
        [attr.data-cy]="program.selector + '-card'"
        (click)="updateSelectedProgram(program.value)"
      >
        <div class="program-card-content">
          <p-radioButton
            [inputId]="program.name"
            [value]="program.value"
            formControlName="Selected"
            [attr.data-cy]="program.selector + '-radio-button'"
          ></p-radioButton>
          <fa-icon
            *ngIf="isCashBackProgram(program.value); else pointsIcon"
            [icon]="icons['faSackDollar']"
            size="lg"
            class="enlarged-program-icon"
          ></fa-icon>
          <ng-template #pointsIcon>
            <fa-icon [icon]="icons['faMedal']" size="lg" class="enlarged-program-icon"> </fa-icon>
          </ng-template>
          <div class="program-panel-content">
            <label [for]="program.name" class="program-panel-header">{{ program.name }}</label>
            <p class="program-description">{{ program.description }}</p>
          </div>
        </div>
      </p-card>
    </div>
  </form>
</div>
