import {
  ApplicationProfileState,
  CredentialsState,
  DeviceState,
  EmployeeState,
  LoyaltyProgramState,
  QuestionState,
} from '@app/resources/ngrx/states';
import { RouterStateUrl } from '@app/resources/ngrx/states/router.state';
import { RouterReducerState } from '@ngrx/router-store';
import { AuthenticationState } from '@app/resources/ngrx/states/authentication.state';

const DEFAULT_ENTITY = {
  ids: [],
  entities: {},
};

export interface RootState {
  applicationProfile: ApplicationProfileState;
  authentication: AuthenticationState;
  credentials: CredentialsState;
  device: DeviceState;
  employee: EmployeeState;
  loyaltyProgram: LoyaltyProgramState;
  questions: QuestionState;
  router: RouterReducerState<RouterStateUrl>;
}

export const DEFAULT_ROOT_STATE: RootState = {
  applicationProfile: {},
  authentication: { loggedIn: false, showTermsAndConditions: false, error: null },
  credentials: { clientAccessId: null, jwt: null },
  device: { ...DEFAULT_ENTITY, selectedDeviceId: 0 },
  employee: { ...DEFAULT_ENTITY, selectedEmployeeId: 0 },
  loyaltyProgram: DEFAULT_ENTITY,
  questions: { ...DEFAULT_ENTITY, selectedQuestionId: 0 },
  router: {
    state: {
      url: '',
      queryParams: {},
      params: {},
    },
    navigationId: 0,
  },
};
