import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';
import { OfferQualifierTypes, OfferReceiveTypes, OfferStatusTypes, OfferTypes, ValidForTypes } from '@shared/enums';

export interface Offer {
  offerId?: number | null;
  namePrivate: string;
  receiveAmount: number;
  startDateTime: Date | string;
  endDateTime?: Date | string | null;
  statusType: OfferStatusTypes;
  sendTime: Date | string | null;
  display: string;
  isDisplayAuto: boolean;
}

interface BaseOfferRequest {
  NamePrivate: string;
  ReceiveAmount: number;
  SendTime: Date | string;
  StartDateTime: Date | string;
  EndDateTime?: Date | string | null;
  StatusType: OfferStatusTypes;
  Display: string;
  UpdateDateTime: Date | string;
}

export interface CreateOfferRequest extends BaseOfferRequest {
  LocationId: number;
  ReceiveType: OfferReceiveTypes;
  ValidForType: ValidForTypes;
  QualifierType: OfferQualifierTypes;
  OfferType: OfferTypes;
  IsDisplayAuto: boolean;
}

export interface UpdateOfferRequest extends BaseOfferRequest {
  OfferId: number;
}

export interface OfferMetadata {
  NumberOfTextsSent: number;
  PercentUsed: number;
  OfferRevenue: number;
  AverageSpend: number;
}

@Injectable({
  providedIn: 'root',
})
export class OfferService extends BaseApiService {
  private readonly offerEndpoint = `/v2/Offers`;
  findById(offerId: number) {
    return this.get(`${this.offerEndpoint}/${offerId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
  findAll(locationId: number) {
    return this.get(`${this.offerEndpoint}`, {
      locationId: locationId,
    }).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  findMetadataById(offerId: number) {
    return this.get(`${this.offerEndpoint}/${offerId}/metadata`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  create(offerRequest: CreateOfferRequest) {
    return this.post(`${this.offerEndpoint}`, offerRequest).pipe(
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  update(offerRequest: UpdateOfferRequest) {
    return this.patch(`${this.offerEndpoint}`, offerRequest).pipe(
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  cancel(offerId: number) {
    return this.patch(`${this.offerEndpoint}/${offerId}/cancel`, null).pipe(
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }
}
