<div class="offer-review-card-wrapper">
  <div class="offer-review-details">
    <div>
      <p><b>Name</b></p>
      <p>{{ getOfferName$() | async }}</p>
    </div>
    <div>
      <p><b>Discount</b></p>
      <p>{{ getOfferDiscount$() | async }}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="offer-review-details">
    <div>
      <p><b>Duration</b></p>
      <p>{{ getOfferStartDate$() | async }} - {{ getOfferEndDate$() | async }}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="offer-review-details">
    <div>
      <p><b>Scheduled Reminder</b></p>
      <p>{{ getOfferReminderDate$() | async }}</p>
    </div>
    <div>
      <p><b>Message</b></p>
      <p>{{ getOfferSMSMessage$() | async }}</p>
    </div>
  </div>
</div>
