import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DashboardOverviewPanelComponent } from '@app/areas/dashboard/components/dashboard-overview-panel/dashboard-overview-panel.component';
import { TabViewModule } from 'primeng/tabview';
import { isNotNullOrUndefined, LoadingSpinnerDirective } from '@ep/shared';
import { DashboardTodayPanelViewComponent } from '@app/areas/dashboard/components/dashboard-today-panel-view/dashboard-today-panel-view.component';
import { DashboardWeekPanelViewComponent } from '@app/areas/dashboard/components/dashboard-week-panel-view/dashboard-week-panel-view.component';
import { DashboardMonthPanelViewComponent } from '@app/areas/dashboard/components/dashboard-month-panel-view/dashboard-month-panel-view.component';
import { DashboardCustomPanelViewComponent } from '@app/areas/dashboard/components/dashboard-custom-panel-view/dashboard-custom-panel-view.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { filter, map } from 'rxjs/operators';
import { EmployeeAccessTypes, SignalrTargetsEnum } from '@shared/enums';
import { SignalRService } from '@app/resources/services/signalr.service';
import { DashboardApiService } from '@app/areas/dashboard/services/dashboard.api.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss'],
  imports: [
    DashboardOverviewPanelComponent,
    TabViewModule,
    LoadingSpinnerDirective,
    DashboardTodayPanelViewComponent,
    DashboardWeekPanelViewComponent,
    DashboardMonthPanelViewComponent,
    DashboardCustomPanelViewComponent,
    NgIf,
    AsyncPipe,
    ButtonModule,
  ],
  standalone: true,
})
export class DashboardPanelComponent implements OnInit, OnDestroy {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly dashboardApiService = inject(DashboardApiService);
  private readonly signalRService = inject(SignalRService);

  private _dashboardUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected dashboardUpdated$ = this._dashboardUpdated$.asObservable().pipe(filter(isNotNullOrUndefined));

  private subs = new Subscription();

  protected get isLoyaltyProgramAdmin$() {
    return this.applicationProfileService.applicationProfile$.pipe(
      map((appProfile) => {
        return (
          (appProfile.Employee.AccessType & EmployeeAccessTypes.LoyaltyProgramAdmin) ===
          EmployeeAccessTypes.LoyaltyProgramAdmin
        );
      })
    );
  }

  ngOnInit() {
    this.getDashboardData();
    this.listenForDashboardUpdates();

    this.subs.add(
      this.dashboardUpdated$.subscribe((dashboardUpdated) => {
        if (dashboardUpdated) this.signalRService.disconnect();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onRefreshDashboard() {
    if (this._dashboardUpdated$.value) {
      this._dashboardUpdated$.next(false);
      this.getDashboardData();
      this.listenForDashboardUpdates();
    }
  }

  private listenForDashboardUpdates() {
    if (!this.signalRService.hasConnectionStarted()) this.signalRService.startConnection();

    this.signalRService.hubConnection.on(SignalrTargetsEnum.UpdateDashboard, (data) => {
      this._dashboardUpdated$.next(true);
    });
  }

  private getDashboardData() {
    this.dashboardApiService.getDailyOverviewInfo$().subscribe();
  }
}
