import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChartModule } from 'primeng/chart';

export type CustomChartJSData = {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  backgroundColor: string;
  tension: number;
  order: number;
};

export type DashboardChartJSData = {
  labels: string[];
  datasets: CustomChartJSData[];
};

const customCanvasBackgroundColorPlugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const chartAreaBorderPlugin = {
  id: 'chartAreaBorder',
  beforeDraw(chart: any, args: any, options: any) {
    const {
      ctx,
      chartArea: { left, top, width, height },
    } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  },
};

@Component({
  selector: 'app-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgIf, ChartModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardGraphComponent {
  @Input() data$: Observable<DashboardChartJSData> | null = null;
  plugins: any[] = [customCanvasBackgroundColorPlugin, chartAreaBorderPlugin];

  options: any = {
    maintainAspectRatio: false,
    aspectRatio: 0.7,
    plugins: {
      customCanvasBackgroundColor: {
        color: '#eff3f9',
      },
      chartAreaBorder: {
        borderColor: '#ccd3dd',
        borderWidth: 1.5,
      },
      legend: {
        labels: {
          useBorderRadius: true,
          borderRadius: 5,
          boxWidth: 20,
          boxHeight: 20,
        },
        position: 'top',
        align: 'end',
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Sale Dates',
          color: '#607389',
          font: {
            size: 16,
            weight: 500,
          },
        },
        ticks: {
          color: '#607389',
          font: {
            size: 14,
            weight: 500,
          },
        },
        grid: {
          color: '#ccd3dd',
          drawBorder: true,
        },
        offset: true,
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Sales ($)',
          color: '#607389',
          font: {
            size: 16,
            weight: 500,
          },
        },
        ticks: {
          color: '#607389',
          font: {
            size: 14,
            weight: 500,
          },
          min: 0,
          suggestedMin: 0,
          beginAtZero: true,
        },
        grid: {
          color: '#ccd3dd',
          drawBorder: true,
        },
      },
    },
  };
}
