import { Pipe, PipeTransform } from '@angular/core';
import { getOfferStatusSeverity } from '@app/areas/offers/utils';
import { Offer } from '@app/resources/services';
/*
 * Returns a severity string value based on an offer status type
 * Takes an offer argument
 *
 * Example:
 *   offer => { ..., offerStatusType: OfferStatusTypes.Cancelled }
 *   {{ offer | offerStatusSeverity }}
 *   returns: 'danger'
 */
@Pipe({
  standalone: true,
  name: 'offerStatusSeverity',
})
export class OfferStatusSeverityPipe implements PipeTransform {
  transform(value: Offer): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
    return getOfferStatusSeverity(value);
  }
}
