<div class="dashboard-overview-panel-wrapper">
  <p-panel>
    <ng-template pTemplate="header">
      <div class="panel-header-wrapper">
        <img class="png-icon" src="../../../../../assets/images/mn_icon.png" alt="dashboard-overview-panel-header" />
        <span class="panel-header">{{ header }}</span>
      </div>
    </ng-template>
    <div *ngIf="showChartData" class="dashboard-overview-graph-wrapper">
      <app-dashboard-graph [data$]="graphData$"></app-dashboard-graph>
    </div>
    <p-divider *ngIf="showChartData"></p-divider>
    <div class="overview-panel-column-data">
      <div class="overview-panel-row-data">
        <app-dashboard-overview-panel-data
          [data]="overviews[0]"
          class="dashboard-overview-panel-data"
        ></app-dashboard-overview-panel-data>
        <app-dashboard-overview-panel-data
          [data]="overviews[1]"
          class="dashboard-overview-panel-data"
        ></app-dashboard-overview-panel-data>
      </div>
      <div class="overview-panel-row-data">
        <app-dashboard-overview-panel-data
          [data]="overviews[2]"
          class="dashboard-overview-panel-data"
        ></app-dashboard-overview-panel-data>
        <app-dashboard-overview-panel-data
          [data]="overviews[3]"
          class="dashboard-overview-panel-data"
        ></app-dashboard-overview-panel-data>
      </div>
    </div>
  </p-panel>
</div>
