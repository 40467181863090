import { ApplicationProfileState } from '@app/resources/ngrx/states';
import { createReducer, on } from '@ngrx/store';
import { ApplicationProfileActions } from '@app/resources/ngrx/actions';

export const initialApplicationProfileState: ApplicationProfileState = {};

export const _applicationProfileReducer = createReducer(
  initialApplicationProfileState,
  on(ApplicationProfileActions.loadApplicationProfilesSuccess, (state, { payload }) => ({
    ...state,
    profile: payload.profile,
  }))
);
