import { Component, Input } from '@angular/core';
import { CardModule } from 'primeng/card';
import { NgClass, NgIf } from '@angular/common';

export interface ViewCardConfig {
  header: string;
  headerSubtext?: string | null;
  subheader: string;
  text: string;
  pathToImg: string;
  backgroundColor: string;
  zoomOnHover?: boolean;
}

@Component({
  selector: 'app-view-card',
  templateUrl: './view-card.component.html',
  styleUrls: ['./view-card.component.scss'],
  standalone: true,
  imports: [CardModule, NgIf, NgClass],
})
export class ViewCardComponent {
  @Input() config: ViewCardConfig | null = null;
}
