import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RootState } from '@app/resources/ngrx/states';

enum HydrationActionTypes {
  HYDRATE = 'Hydrate',
  HYDRATE_SUCCESS = 'Hydrate Success',
  HYDRATE_ERROR = 'Hydrate Error',
  CLEAR = 'Clear',
}

export const HydrationActions = createActionGroup({
  source: 'Hydration',
  events: {
    [HydrationActionTypes.HYDRATE]: emptyProps(),
    [HydrationActionTypes.HYDRATE_SUCCESS]: props<{ state: RootState | null }>(),
    [HydrationActionTypes.HYDRATE_ERROR]: emptyProps(),
    [HydrationActionTypes.CLEAR]: emptyProps(),
  },
});
