<div id="offer-details-header-buttons">
  <p-button
    (onClick)="duplicateOffer()"
    pTooltip="Duplicate Offer"
    [tooltipOptions]="tooltipOptions"
    [autoHide]="false"
    data-cy="duplicate-offer-button"
  >
    <fa-icon [icon]="icons['faCopy']" size="lg" style="color: #ffffff"></fa-icon>
  </p-button>
  <p-button
    *ngIf="isPendingTextNotSent"
    (onClick)="editOffer()"
    pTooltip="Edit Offer"
    [tooltipOptions]="tooltipOptions"
    [autoHide]="false"
    data-cy="edit-offer-button"
  >
    <fa-icon [icon]="icons['faPenToSquare']" size="lg" style="color: #ffffff"></fa-icon>
  </p-button>
</div>
