import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CardModule } from 'primeng/card';
import { AsyncPipe, NgIf } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Offer } from '@app/resources/services';
import { LoyaltyProgramsTypesEnum, OfferStatusTypes } from '@shared/enums';
import {
  OfferFormattedDatePipe,
  OfferFormattedReminderPipe,
  OfferStatusPipe,
  OfferSmsBodyPipe,
  OfferStatusSeverityPipe,
} from '@app/areas/offers/pipes';
import { OfferDetailsHeaderButtonsComponent } from '@app/areas/offers/components/offer-details-header-buttons/offer-details-header-buttons.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { WindowService } from '@app/resources/services/window.service';
import { TooltipModule } from 'primeng/tooltip';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-offer-details-card',
  templateUrl: './offer-details-card.component.html',
  styleUrls: ['./offer-details-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CardModule,
    AsyncPipe,
    DividerModule,
    TagModule,
    TooltipModule,
    ConfirmDialogModule,
    OfferFormattedDatePipe,
    OfferFormattedReminderPipe,
    OfferStatusSeverityPipe,
    OfferDetailsHeaderButtonsComponent,
    OverlayPanelModule,
    OfferStatusPipe,
    OfferSmsBodyPipe,
  ],
})
export class OfferDetailsCardComponent {
  private readonly windowService = inject(WindowService);
  private readonly applicationProfileService = inject(ApplicationProfileService);

  @Input() offer: Offer | null = null;
  @Output() duplicateOfferEvent = new EventEmitter(false);
  @Output() editOfferEvent = new EventEmitter(false);

  get isSmallScreen() {
    return this.windowService.isSmallScreen;
  }

  get isPendingTextNotSent() {
    return this.offer?.statusType === OfferStatusTypes.PendingNoText;
  }

  protected getOfferDiscountAmount$() {
    return this.applicationProfileService.applicationProfile$.pipe(
      map((appProfile) => {
        let newDiscount = this.offer?.receiveAmount ?? 0;
        if (
          (appProfile.Merchant.LoyaltyProgram.LoyaltyProgramType & LoyaltyProgramsTypesEnum.CashBack) ===
          LoyaltyProgramsTypesEnum.CashBack
        ) {
          newDiscount += appProfile.Merchant.LoyaltyProgram.ReceiveAmount * 10000;
        }
        return `Up to ${newDiscount}% off`;
      })
    );
  }

  protected duplicateOffer() {
    this.duplicateOfferEvent.emit(true);
  }

  protected editOffer() {
    this.editOfferEvent.emit(true);
  }
}
