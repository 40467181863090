import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OfferDate, OfferFormService, WhenOfferDateEnum } from '@app/areas/offers/services/offer.form.service';
import { AsyncPipe } from '@angular/common';
import { combineLatest, concat, defer, of, startWith } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { map } from 'rxjs/operators';
import { LoyaltyProgramsTypesEnum, OfferTextReminderEnum } from '@shared/enums';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { TimezoneService } from '@app/resources/services/timezone.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-offer-review',
  templateUrl: './offer-review.component.html',
  styleUrls: ['./offer-review.component.scss'],
  standalone: true,
  imports: [AsyncPipe, DividerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferReviewComponent {
  private readonly offerFormService = inject(OfferFormService);
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly timezoneService = inject(TimezoneService);
  private readonly defaultDate: OfferDate = {
    when: WhenOfferDateEnum.CUSTOM,
    date: this.timezoneService.moment().add(this.timezoneService.getLocationClientOffset()).toDate(),
  };
  offerForm = this.offerFormService.getOfferForm();

  protected getOfferDetails() {
    return this.offerForm.controls.Details;
  }

  protected getOfferDuration() {
    return this.offerForm.controls.Duration;
  }

  protected getOfferScheduledSMS() {
    return this.offerForm.controls.ScheduledSMS;
  }
  protected getOfferName$() {
    return this.getOfferDetails().controls.Name.valueChanges.pipe(
      startWith(this.getOfferDetails().controls.Name.value)
    );
  }

  protected getOfferDiscount$() {
    return combineLatest([
      this.getOfferDetails().controls.DiscountPercentage.valueChanges.pipe(
        startWith(this.getOfferDetails().controls.DiscountPercentage.value)
      ),
      this.applicationProfileService.applicationProfile$,
    ]).pipe(
      map(([discount, appProfile]) => {
        if (discount !== null && !!appProfile) {
          let newDiscount = discount;
          if (
            (appProfile.Merchant.LoyaltyProgram.LoyaltyProgramType & LoyaltyProgramsTypesEnum.CashBack) ===
            LoyaltyProgramsTypesEnum.CashBack
          ) {
            newDiscount += appProfile.Merchant.LoyaltyProgram.ReceiveAmount * 10000;
          }
          return `Total of ${newDiscount}% off`;
        }
        return 'N/A';
      })
    );
  }

  protected getOfferStartDate$() {
    return this.getOfferDuration().controls.StartDate.valueChanges.pipe(
      startWith(this.getOfferDuration().controls.StartDate.value || this.defaultDate),
      map((date) => this.mapDateToString(date))
    );
  }

  protected getOfferEndDate$() {
    return this.getOfferDuration().controls.EndDate.valueChanges.pipe(
      startWith(this.getOfferDuration().controls.EndDate.value || this.defaultDate),
      map((date) => this.mapDateToString(date))
    );
  }

  protected getOfferReminderDate$() {
    return combineLatest([
      this.getOfferScheduledSMS().controls.SMSDate.valueChanges.pipe(
        startWith(this.getOfferScheduledSMS().controls.SMSDate.value || this.defaultDate),
        map((date) => this.mapDateToString(date))
      ),
      concat(
        defer(() => of(this.getOfferScheduledSMS().controls.Reminder.value)),
        this.getOfferScheduledSMS().controls.Reminder.valueChanges
      ),
    ]).pipe(
      map(([dateString, reminder]) => {
        if (!!dateString && !!reminder) {
          const key = parseInt(reminder.key) as OfferTextReminderEnum;
          if (key === OfferTextReminderEnum.NOW) return 'Send now';
          else return dateString;
        }
        return 'N/A';
      })
    );
  }

  protected getOfferSMSMessage$() {
    return this.getOfferScheduledSMS().controls.SMSBody.valueChanges.pipe(
      startWith(this.getOfferScheduledSMS().controls.SMSBody.value)
    );
  }

  private mapDateToString(offerDate: OfferDate) {
    if (!!offerDate.date && typeof offerDate.date === 'object') {
      return `${moment(offerDate.date).format('dddd, MMMM DD, YYYY h:mm a')}`;
    }
    return 'N/A';
  }
}
