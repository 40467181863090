import { createActionGroup, props } from '@ngrx/store';
import { Message } from 'primeng/api';

enum MessagingActionTypes {
  CREATE_MESSAGE = 'Create Message',
}

export const MessagingActions = createActionGroup({
  source: 'Messaging',
  events: {
    [MessagingActionTypes.CREATE_MESSAGE]: props<{ payload: { message: Message } }>(),
  },
});
