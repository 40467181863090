import { Component, OnInit } from '@angular/core';
import { IframeManagerService } from '@app/@shared/services/iframe-manager.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private iframeManagerService: IframeManagerService) {}

  ngOnInit(): void {
    this.iframeManagerService.setShouldShowIframe(false);
  }
}
