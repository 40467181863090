import { inject, Injectable } from '@angular/core';
import { LoyaltyProgramService } from '@app/resources/services/loyalty-program.service';
import { DailySaleOverview, DailySaleOverviewResponseData } from '@shared/models/daily-sale-overview.model';
import { BehaviorSubject, shareReplay, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@ep/shared';
import { TimezoneService } from '@app/resources/services';
import moment from 'moment-timezone';

/**
 * A service for interacting with the dashboard API.
 */
@Injectable()
export class DashboardApiService {
  private readonly loyaltyProgramService = inject(LoyaltyProgramService);
  private readonly timezoneService = inject(TimezoneService);

  private _dailySaleOverview$: BehaviorSubject<DailySaleOverview[] | null> = new BehaviorSubject<
    DailySaleOverview[] | null
  >(null);
  dailySaleOverview$ = this._dailySaleOverview$.asObservable().pipe(filter(isNotNullOrUndefined), shareReplay(1));

  /**
   * Retrieves daily overview information.
   *
   * @returns {Observable<DailySaleOverview[] | null>} Observable that emits the daily sale overview data or null if data is not available.
   */
  getDailyOverviewInfo$() {
    const fromDate = this.timezoneService.moment().subtract(30, 'day').format('YYYY-MM-DD');
    const toDate = this.timezoneService.moment().format('YYYY-MM-DD');
    const dateRange = this.getDateRange(fromDate, toDate);

    return this.loyaltyProgramService
      .findDaily({
        FromDate: fromDate,
        ToDate: toDate,
      })
      .pipe(
        tap((data) => {
          if (!!data) {
            const dailySaleOverviews: DailySaleOverview[] = [];
            let i = 0;
            for (let date of dateRange) {
              if (data.$values[i] && moment(data.$values[i].dailyDate).format('YYYY-MM-DD') === date) {
                dailySaleOverviews.push(MapToDailySaleOverview(data.$values[i]));
                i++;
              } else {
                dailySaleOverviews.push(MapToDailySaleOverview({ dailyDate: date } as DailySaleOverviewResponseData));
              }
            }
            this._dailySaleOverview$.next(dailySaleOverviews);
          }
          this._dailySaleOverview$.next(null);
        })
      );
  }

  /**
   * Retrieves custom daily overview information based on the given date range.
   *
   * @param {string} fromDate - The start date of the date range in the format 'YYYY-MM-DD'.
   * @param {string} toDate - The end date of the date range in the format 'YYYY-MM-DD'.
   * @returns {Observable<DailySaleOverview[]>} - An Observable that emits an array of DailySaleOverview objects or null if no data is found.
   */
  getCustomDailyOverviewInfo$(fromDate: string, toDate: string) {
    const dateRange = this.getDateRange(fromDate, toDate);
    return this.loyaltyProgramService
      .findDaily({
        FromDate: fromDate,
        ToDate: toDate,
      })
      .pipe(
        map((data) => {
          if (!!data) {
            const dailySaleOverviews: DailySaleOverview[] = [];
            let i = 0;
            for (let date of dateRange) {
              if (data.$values[i] && moment(data.$values[i].dailyDate).format('YYYY-MM-DD') === date) {
                dailySaleOverviews.push(MapToDailySaleOverview(data.$values[i]));
                i++;
              } else {
                dailySaleOverviews.push(MapToDailySaleOverview({ dailyDate: date } as DailySaleOverviewResponseData));
              }
            }
            return dailySaleOverviews;
          }
          return null;
        })
      );
  }

  /**
   * Calculates the date range between two dates.
   *
   * @param {moment.MomentInput} fromDate - The starting date.
   * @param {moment.MomentInput} toDate - The ending date.
   * @returns {Array.<string>} - An array of dates within the range.
   */
  private getDateRange(fromDate: moment.MomentInput, toDate: moment.MomentInput) {
    if (moment(fromDate, 'YYYY-MM-DD').isSame(moment(toDate, 'YYYY-MM-DD'), 'day')) return [toDate];
    let date = fromDate;
    const dates = [date];
    do {
      date = moment(date).add(1, 'day');
      dates.push(date.format('YYYY-MM-DD'));
    } while (moment(date).isBefore(toDate));
    return dates;
  }
}

/**
 * Maps the given data to an instance of DailySaleOverview.
 *
 * @param {DailySaleOverviewResponseData | null} data - The data to map to DailySaleOverview.
 * @returns {DailySaleOverview} - The mapped DailySaleOverview instance.
 */
function MapToDailySaleOverview(data?: DailySaleOverviewResponseData | null): DailySaleOverview {
  return {
    DailySaleOverviewId: data?.dailySaleOverviewId ?? 0,
    DailyDate: data?.dailyDate ?? new Date(),
    LoyaltyProgramId: data?.loyaltyProgramId ?? 0,
    MerchantId: data?.merchantId ?? 0,
    LocationId: data?.locationId ?? 0,
    TotalEnrollmentOrderCount: data?.totalEnrollmentOrderCount ?? 0,
    TotalMemberOrderCount: data?.totalMemberOrderCount ?? 0,
    TotalNonMemberOrderCount: data?.totalNonMemberOrderCount ?? 0,
    TotalEnrollmentSpendAmount: data?.totalEnrollmentSpendAmount ?? 0,
    TotalMemberSpendAmount: data?.totalMemberSpendAmount ?? 0,
    TotalNonMemberSpendAmount: data?.totalNonMemberSpendAmount ?? 0,
    EnrollmentCount: data?.enrollmentCount ?? 0,
    DeclinedCount: data?.declinedCount ?? 0,
    AssignedCount: data?.assignedCount ?? 0,
    OptInToOfferCount: data?.optInToOfferCount ?? 0,
    TotalQuestionAnswerCount: data?.totalQuestionAnswerCount ?? 0,
    TotalQuestionAnswerScore: data?.totalQuestionAnswerScore ?? 0,
    TotalRewardEarnedOrderCount: data?.totalRewardEarnedOrderCount ?? 0,
    TotalRewardSpentOrderCount: data?.totalRewardSpentOrderCount ?? 0,
    TotalRewardEarnedAmount: data?.totalRewardEarnedAmount ?? 0,
    TotalRewardSpentAmount: data?.totalRewardSpentAmount ?? 0,
    TotalRewardEarnedOrderAmount: data?.totalRewardEarnedOrderAmount ?? 0,
    TotalRewardSpentOrderAmount: data?.totalRewardSpentOrderAmount ?? 0,
    TotalDiscountOrderCount: data?.totalDiscountOrderCount ?? 0,
    TotalDiscountOrderAmount: data?.totalDiscountOrderAmount ?? 0,
    TotalDiscountCount: data?.totalDiscountCount ?? 0,
    TotalDiscountAmount: data?.totalDiscountAmount ?? 0,
    TrendingDate: data?.trendingDate,
    TrendingType: data?.trendingType,
    Ts: data?.ts,
  };
}
