import { inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { LoyaltyProgramsTypesEnum } from '@shared/enums';
import {
  EnrollmentCashBackProgram,
  EnrollmentLoyaltyProgram,
  EnrollmentOffer,
  EnrollmentOfferBonus,
  EnrollmentOfferInstant,
  EnrollmentPointsProgram,
  MerchantAccount,
  MerchantBusinessInformation,
  MerchantContactInformation,
  ReviewQuestions,
} from '@app/resources/services/enrollment.service';
import { EnrollmentOfferTypesEnum } from '@shared/enums/enrollment-offer-types.enum';
import { TimeZonesEnum } from '@ep/shared';

/**
 * Forms for enrollment merchant account setup
 */
export type EnrollmentAccountContactFormControls = {
  FirstName: FormControl<string | null>;
  LastName: FormControl<string | null>;
  Email: FormControl<string | null>;
  Phone: FormControl<string | null>;
  TerminalLogin: FormControl<string | null>;
};
export type EnrollmentAccountBusinessFormControls = {
  BusinessName: FormControl<string | null>;
  Address: FormControl<string | null>;
  Phone: FormControl<string | null>;
  TimeZone: FormControl<TimeZonesEnum | null>;
};

export type EnrollmentAccountContactFormGroup = FormGroup<EnrollmentAccountContactFormControls>;
export type EnrollmentAccountBusinessFormGroup = FormGroup<EnrollmentAccountBusinessFormControls>;

export type EnrollmentAccountFormControls = {
  Contact: EnrollmentAccountContactFormGroup;
  Business: EnrollmentAccountBusinessFormGroup;
};

export type EnrollmentAccountFormGroup = FormGroup<EnrollmentAccountFormControls>;

/**
 * Forms for enrollment loyalty program setup
 */

export type EnrollmentLPCashBackFormControls = {
  Name: FormControl<string | null>;
  CashBackPercentage: FormControl<number | null>;
};
export type EnrollmentLPPointsFormControls = {
  Name: FormControl<string | null>;
  QualifierAmount: FormControl<number | null>;
  EarnPoints: FormControl<number | null>;
  RewardGoal: FormControl<number | null>;
  RewardAmount: FormControl<number | null>;
};

export type EnrollmentLPCashBackFormGroup = FormGroup<EnrollmentLPCashBackFormControls>;
export type EnrollmentLPPointsFormGroup = FormGroup<EnrollmentLPPointsFormControls>;

export type EnrollmentLoyaltyProgramFormControls = {
  Selected: FormControl<LoyaltyProgramsTypesEnum | null>;
  CashBack: EnrollmentLPCashBackFormGroup;
  Points: EnrollmentLPPointsFormGroup;
};

export type EnrollmentLoyaltyProgramFormGroup = FormGroup<EnrollmentLoyaltyProgramFormControls>;

/**
 * Forms for enrollment offer setup
 */

export type EnrollmentOfferInstantFormControls = {
  RewardPercentage: FormControl<number | null>; // Percentage
  MaxRewardAmount: FormControl<number | null>; // Dollar Amount
};
// Two types: Fixed and Calculated, but Fixed is not supported atm
export type EnrollmentOfferBonusFormControls = {
  RewardPercentage: FormControl<number | null>; // Percentage
  MaxRewardAmount: FormControl<number | null>; // Dollar Amount
};

export type EnrollmentOfferInstantFormGroup = FormGroup<EnrollmentOfferInstantFormControls>;
export type EnrollmentOfferBonusFormGroup = FormGroup<EnrollmentOfferBonusFormControls>;

export type EnrollmentOfferFormControls = {
  Selected: FormControl<EnrollmentOfferTypesEnum | null>;
  Instant: EnrollmentOfferInstantFormGroup;
  Bonus: EnrollmentOfferBonusFormGroup;
};

export type EnrollmentOfferFormGroup = FormGroup<EnrollmentOfferFormControls>;

export type ReviewQuestionControl = FormControl<string | null>;
export type ReviewQuestionsFormArray = FormArray<ReviewQuestionControl>;
export type EnrollmentReviewQuestionsFormControls = {
  Questions: ReviewQuestionsFormArray;
};
export type EnrollmentReviewQuestionsFormGroup = FormGroup<EnrollmentReviewQuestionsFormControls>;

export type EnrollmentFormControls = {
  MerchantAccount: EnrollmentAccountFormGroup;
  LoyaltyProgram: EnrollmentLoyaltyProgramFormGroup;
  EnrollmentOffer: EnrollmentOfferFormGroup;
  ReviewQuestions: EnrollmentReviewQuestionsFormGroup;
};

export type EnrollmentFormGroup = FormGroup<EnrollmentFormControls>;

@Injectable()
export class EnrollmentFormService {
  private readonly fb = inject(NonNullableFormBuilder);

  private enrollmentForm: EnrollmentFormGroup = this.fb.group<EnrollmentFormControls>({
    MerchantAccount: this.fb.group<EnrollmentAccountFormControls>({
      Contact: this.fb.group<EnrollmentAccountContactFormControls>({
        FirstName: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        LastName: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        Email: this.fb.control<string | null>(null, {
          validators: [Validators.required, Validators.email],
        }),
        Phone: this.fb.control<string | null>(null, {
          validators: [Validators.required, Validators.pattern(/^\+(?:[0-9]●?){6,14}[0-9]$/)],
        }),
        TerminalLogin: this.fb.control<string | null>(null, {
          validators: [Validators.required, Validators.pattern(/^[0-9]{6}$/)],
        }),
      }),
      Business: this.fb.group<EnrollmentAccountBusinessFormControls>({
        BusinessName: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        Address: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        Phone: this.fb.control<string | null>(null, {
          validators: [Validators.required, Validators.pattern(/^\+(?:[0-9]●?){6,14}[0-9]$/)],
        }),
        TimeZone: this.fb.control<TimeZonesEnum | null>(null, { validators: [Validators.required] }),
      }),
    }),
    LoyaltyProgram: this.fb.group<EnrollmentLoyaltyProgramFormControls>({
      Selected: this.fb.control<LoyaltyProgramsTypesEnum | null>(null, { validators: [Validators.required] }),
      CashBack: this.fb.group<EnrollmentLPCashBackFormControls>({
        Name: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        CashBackPercentage: this.fb.control<number | null>(null, {
          validators: [Validators.required, Validators.pattern('[0-9]{1,3}'), Validators.min(0), Validators.max(100)],
        }),
      }),
      Points: this.fb.group<EnrollmentLPPointsFormControls>({
        Name: this.fb.control<string | null>(null, { validators: [Validators.required] }),
        QualifierAmount: this.fb.control<number | null>(null, { validators: [Validators.required, Validators.min(0)] }),
        EarnPoints: this.fb.control<number | null>(null, { validators: [Validators.required, Validators.min(0)] }),
        RewardGoal: this.fb.control<number | null>(null, { validators: [Validators.required, Validators.min(0)] }),
        RewardAmount: this.fb.control<number | null>(null, { validators: [Validators.required, Validators.min(0)] }),
      }),
    }),
    EnrollmentOffer: this.fb.group<EnrollmentOfferFormControls>({
      Selected: this.fb.control<EnrollmentOfferTypesEnum | null>(null, { validators: [Validators.required] }),
      Instant: this.fb.group<EnrollmentOfferInstantFormControls>({
        RewardPercentage: this.fb.control<number | null>(null, {
          validators: [Validators.required, Validators.pattern('[0-9]{1,3}'), Validators.min(0), Validators.max(100)],
        }),
        MaxRewardAmount: this.fb.control<number | null>(null, {
          validators: [Validators.required, Validators.min(0)],
        }),
      }),
      Bonus: this.fb.group<EnrollmentOfferBonusFormControls>({
        RewardPercentage: this.fb.control<number | null>(null, {
          validators: [Validators.required, Validators.pattern('[0-9]{1,3}'), Validators.min(0), Validators.max(100)],
        }),
        MaxRewardAmount: this.fb.control<number | null>(null, {
          validators: [Validators.required, Validators.min(0)],
        }),
      }),
    }),
    ReviewQuestions: this.fb.group<EnrollmentReviewQuestionsFormControls>({
      Questions: this.fb.array<ReviewQuestionControl>([], { validators: [Validators.max(1)] }),
    }),
  });

  getForm() {
    return this.enrollmentForm;
  }

  getAccountForm() {
    return this.enrollmentForm.controls.MerchantAccount;
  }

  getContactInfoForm() {
    return this.getAccountForm().controls.Contact;
  }

  getBusinessInfoForm() {
    return this.getAccountForm().controls.Business;
  }

  getLoyaltyProgramForm() {
    return this.enrollmentForm.controls.LoyaltyProgram;
  }

  getCashBackProgramForm() {
    return this.getLoyaltyProgramForm().controls.CashBack;
  }

  getPointsProgramForm() {
    return this.getLoyaltyProgramForm().controls.Points;
  }

  getEnrollmentOfferForm() {
    return this.enrollmentForm.controls.EnrollmentOffer;
  }

  getInstantOfferForm() {
    return this.getEnrollmentOfferForm().controls.Instant;
  }

  getBonusOfferForm() {
    return this.getEnrollmentOfferForm().controls.Bonus;
  }

  getReviewQuestionsForm() {
    return this.enrollmentForm.controls.ReviewQuestions;
  }

  initializeForm(data?: {
    MerchantAccount: MerchantAccount;
    LoyaltyProgram: EnrollmentLoyaltyProgram;
    EnrollmentOffer: EnrollmentOffer;
    ReviewQuestion?: ReviewQuestions | null;
  }) {
    this.resetForm();

    if (data?.MerchantAccount.ContactInformation) {
      this.initializeContactForm(data.MerchantAccount.ContactInformation);
    }
    if (data?.MerchantAccount.BusinessInformation) {
      this.initializeBusinessForm(data.MerchantAccount.BusinessInformation);
    }
    if (data?.LoyaltyProgram.CashBack) {
      this.initializeCashBackForm(data.LoyaltyProgram.CashBack);
      this.getPointsProgramForm().disable();
    } else if (data?.LoyaltyProgram.Points) {
      this.initializePointsForm(data.LoyaltyProgram.Points);
      this.getCashBackProgramForm().disable();
    }
    if (data?.EnrollmentOffer.InstantOffer) {
      this.initializeInstantOfferForm(data.EnrollmentOffer.InstantOffer);
      this.getBonusOfferForm().disable();
    } else if (data?.EnrollmentOffer.BonusOffer) {
      this.initializeBonusOfferForm(data.EnrollmentOffer.BonusOffer);
      this.getInstantOfferForm().disable();
    }

    if (data?.ReviewQuestion) {
      this.initializeReviewQuestionsForm(data.ReviewQuestion.Question);
    }

    this.enrollmentForm.markAsTouched();
    this.enrollmentForm.markAsPristine();
  }

  private resetForm() {
    this.enrollmentForm.controls.MerchantAccount.reset();
    this.enrollmentForm.controls.LoyaltyProgram.reset();
    this.enrollmentForm.controls.EnrollmentOffer.reset();
    this.enrollmentForm.controls.ReviewQuestions.controls.Questions.clear();
  }

  private initializeContactForm(contactInfo: MerchantContactInformation) {
    this.enrollmentForm.controls.MerchantAccount.controls.Contact.patchValue({
      FirstName: contactInfo.FirstName,
      LastName: contactInfo.LastName,
      Email: contactInfo.EmailAddress,
      Phone: contactInfo.PhoneNumber,
      TerminalLogin: contactInfo.PosIdent,
    });
  }

  private initializeBusinessForm(businessInfo: MerchantBusinessInformation) {
    this.enrollmentForm.controls.MerchantAccount.controls.Business.patchValue({
      BusinessName: businessInfo.Name,
      Address: businessInfo.Address,
      Phone: businessInfo.PhoneNumber,
      TimeZone: businessInfo.TimeZone as TimeZonesEnum,
    });
  }

  private initializeCashBackForm(program: EnrollmentCashBackProgram) {
    this.getLoyaltyProgramForm().controls.Selected.setValue(LoyaltyProgramsTypesEnum.CashBack);
    this.getCashBackProgramForm().patchValue({
      Name: program.Name,
      CashBackPercentage: program.Percentage,
    });
  }

  private initializePointsForm(program: EnrollmentPointsProgram) {
    this.getLoyaltyProgramForm().controls.Selected.setValue(LoyaltyProgramsTypesEnum.Points);
    this.getPointsProgramForm().patchValue({
      Name: program.Name,
      QualifierAmount: program.QualifierAmount,
      EarnPoints: program.PointsEarned,
      RewardGoal: program.RewardQualifier,
      RewardAmount: program.RewardAmount,
    });
  }

  private initializeInstantOfferForm(offer: EnrollmentOfferInstant) {
    this.getEnrollmentOfferForm().controls.Selected.setValue(EnrollmentOfferTypesEnum.Instant);
    this.getInstantOfferForm().patchValue({
      RewardPercentage: offer.RewardPercentage,
      MaxRewardAmount: offer.MaxRewardAmount,
    });
  }

  private initializeBonusOfferForm(offer: EnrollmentOfferBonus) {
    this.getEnrollmentOfferForm().controls.Selected.setValue(EnrollmentOfferTypesEnum.Bonus);
    this.getBonusOfferForm().patchValue({
      RewardPercentage: offer.RewardPercentage,
      MaxRewardAmount: offer.MaxRewardAmount,
    });
  }

  private initializeReviewQuestionsForm(question: string) {
    if (this.getReviewQuestionsForm().controls.Questions.length === 0) {
      this.getReviewQuestionsForm().controls.Questions.push(
        this.fb.control<string | null>(question, { validators: [Validators.required] })
      );
    }
  }

  getContactInformation(): MerchantContactInformation {
    return {
      PhoneNumber: this.getContactInfoForm().getRawValue().Phone ?? '',
      FirstName: this.getContactInfoForm().getRawValue().FirstName ?? '',
      LastName: this.getContactInfoForm().getRawValue().LastName ?? '',
      EmailAddress: this.getContactInfoForm().getRawValue().Email ?? '',
      PosIdent: this.getContactInfoForm().getRawValue().TerminalLogin ?? '',
    };
  }

  getBusinessInformation(): MerchantBusinessInformation {
    return {
      Name: this.getBusinessInfoForm().getRawValue().BusinessName ?? '',
      Address: this.getBusinessInfoForm().getRawValue().Address ?? '',
      PhoneNumber: this.getBusinessInfoForm().getRawValue().Phone ?? '',
      TimeZone: this.getBusinessInfoForm().getRawValue().TimeZone ?? 0,
    };
  }

  getCashBackProgram(): EnrollmentCashBackProgram | null {
    return this.getLoyaltyProgramForm().controls.Selected.getRawValue() === LoyaltyProgramsTypesEnum.CashBack
      ? {
          Name: this.getCashBackProgramForm().getRawValue().Name ?? '',
          Percentage: this.getCashBackProgramForm().getRawValue().CashBackPercentage ?? 0,
        }
      : null;
  }

  getPointsProgram(): EnrollmentPointsProgram | null {
    return this.getLoyaltyProgramForm().controls.Selected.getRawValue() === LoyaltyProgramsTypesEnum.Points
      ? {
          Name: this.getPointsProgramForm().getRawValue().Name ?? '',
          QualifierAmount: this.getPointsProgramForm().getRawValue().QualifierAmount ?? 0,
          PointsEarned: this.getPointsProgramForm().getRawValue().EarnPoints ?? 0,
          RewardQualifier: this.getPointsProgramForm().getRawValue().RewardGoal ?? 0,
          RewardAmount: this.getPointsProgramForm().getRawValue().RewardAmount ?? 0,
        }
      : null;
  }

  getInstantOffer(): EnrollmentOfferInstant | null {
    return this.getEnrollmentOfferForm().controls.Selected.getRawValue() === EnrollmentOfferTypesEnum.Instant
      ? {
          RewardPercentage: this.getInstantOfferForm().getRawValue().RewardPercentage ?? 0,
          MaxRewardAmount: this.getInstantOfferForm().getRawValue().MaxRewardAmount ?? 0,
        }
      : null;
  }

  getBonusOffer(): EnrollmentOfferBonus | null {
    return this.getEnrollmentOfferForm().controls.Selected.getRawValue() === EnrollmentOfferTypesEnum.Bonus
      ? {
          RewardPercentage: this.getBonusOfferForm().getRawValue().RewardPercentage ?? 0,
          MaxRewardAmount: this.getBonusOfferForm().getRawValue().MaxRewardAmount ?? 0,
        }
      : null;
  }

  getReviewQuestions(): ReviewQuestions | null {
    return this.getReviewQuestionsForm().controls.Questions.length !== 0
      ? {
          Question: this.getReviewQuestionsForm().controls.Questions.at(0).getRawValue() ?? '',
        }
      : null;
  }
}
