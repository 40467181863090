import { createReducer, on } from '@ngrx/store';
import { LoyaltyProgramState } from '@app/resources/ngrx/states';
import { LoyaltyProgramActions } from '@app/resources/ngrx/actions';
import { loyaltyProgramAdapter } from '@app/resources/ngrx/adapters';

export const initialLoyaltyProgramModuleState: LoyaltyProgramState = loyaltyProgramAdapter.getInitialState();

export const _loyaltyProgramReducer = createReducer(
  initialLoyaltyProgramModuleState,
  on(LoyaltyProgramActions.updateLoyaltyProgramSuccess, (state, { payload }) =>
    loyaltyProgramAdapter.updateOne(payload.loyaltyProgram, state)
  ),
  on(LoyaltyProgramActions.loadLoyaltyProgramSuccess, (state, { payload }) => {
    state = loyaltyProgramAdapter.removeAll({ ...state });
    return loyaltyProgramAdapter.addOne(payload.loyaltyProgram, state);
  })
);
