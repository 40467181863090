import { Action, ActionReducer } from '@ngrx/store';
import { HydrationActions } from '@app/resources/ngrx/actions';
import { DEFAULT_ROOT_STATE, RootState } from '@app/resources/ngrx/states';

function isHydrateSuccess(action: Action): action is ReturnType<typeof HydrationActions.hydrateSuccess> {
  return action.type === HydrationActions.hydrateSuccess.type;
}

export const hydrationMetaReducer = (reducer: ActionReducer<RootState>): ActionReducer<RootState> => {
  return (state, action) =>
    isHydrateSuccess(action) ? action.state ?? DEFAULT_ROOT_STATE : reducer(state ?? DEFAULT_ROOT_STATE, action);
};
