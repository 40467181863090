import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { OfferDurationFormGroup } from '@app/areas/offers/services/offer.form.service';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { OfferDateTimeControlComponent } from '@app/areas/offers/controls';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-offer-duration-form',
  templateUrl: './offer-duration-form.component.html',
  styleUrls: ['./offer-duration-form.component.scss'],
  imports: [AsyncPipe, NgIf, OfferDateTimeControlComponent, ReactiveFormsModule, FaIconComponent],
  standalone: true,
})
export class OfferDurationFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  private readonly timezoneService = inject(TimezoneService);
  protected offerDurationForm: OfferDurationFormGroup | null = null;
  protected icons = FONT_AWESOME_ICONS;

  @Input() minDate: Date = this.timezoneService
    .moment()
    .add(this.timezoneService.getLocationClientOffset(), 'hours')
    .toDate();
  @Input() minEndDate$: Observable<Date> | null = null;

  ngOnInit() {
    this.offerDurationForm = this.controlContainer.control as OfferDurationFormGroup;
  }
}
