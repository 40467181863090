export enum OfferReceiveTypes {
  ProductValuedUpTo = 1,
  Reward = 2,
  Point = 4,
  OrderSpecific = 8,
  ProductSpecific = 16, // 0x00000010
  ReceiveFixedAmount = 32, // 0x00000020
  ReceivePercentage = 64, // 0x00000040
  AutoApply = 128, // 0x00000080
  Instant = 256, // 0x00000100
  Discount = 512, // 0x00000200
  Bitcoin = 1024, // 0x00000400
}
