import { Component, Input } from '@angular/core';
import { OverviewPanelData } from '@app/areas/dashboard/components/dashboard-overview-panel/dashboard-overview-panel.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-dashboard-overview-panel-data',
  templateUrl: './dashboard-overview-panel-data.component.html',
  styleUrls: ['./dashboard-overview-panel-data.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class DashboardOverviewPanelDataComponent {
  @Input() data: OverviewPanelData | null = null;
}
