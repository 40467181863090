import { inject, Injectable } from '@angular/core';
import { CreateOfferRequest, Offer, OfferMetadata, OfferService, UpdateOfferRequest } from '@app/resources/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment-timezone';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { OfferQualifierTypes, OfferReceiveTypes, OfferStatusTypes, OfferTypes, ValidForTypes } from '@shared/enums';

export interface SpecialOffer {
  offerId?: number | null;
  name: string;
  discountAmount: number;
  startDateTime: Date | string;
  endDateTime?: Date | string | null;
  statusType: OfferStatusTypes;
  sendTime: Date | string;
  smsBody: string | null;
}

const DEFAULT_SEND_DATETIME = '0001-01-01T00:00:00+00:00';

@Injectable()
export class OfferApiService {
  private readonly timeZoneService = inject(TimezoneService);
  private readonly offerService = inject(OfferService);

  getOfferById(offerId: number): Observable<Offer | null> {
    return this.offerService.findById(offerId).pipe(map((result: any) => this.mapResultToOffer(result)));
  }

  getOffers(locationId: number): Observable<Offer[]> {
    return this.offerService.findAll(locationId).pipe(
      map((result: any) => {
        if (result !== null && result.$values !== null) {
          return result.$values.map((data: any) => this.mapResultToOffer(data));
        }
        return [];
      })
    );
  }

  getOfferMetadataById(offerId: number): Observable<OfferMetadata> {
    return this.offerService.findMetadataById(offerId).pipe(map(mapToOfferMetaData));
  }

  createOffer(locationId: number, merchantId: number, offer: SpecialOffer) {
    return this.offerService.create(mapToCreateOfferRequest(locationId, merchantId, offer));
  }

  updateOffer(offer: SpecialOffer) {
    return this.offerService.update(mapToUpdateOfferRequest(offer));
  }

  cancelOffer(offerId: number) {
    return this.offerService.cancel(offerId);
  }

  private mapResultToOffer(offer: any): Offer {
    const startDate = this.timeZoneService.moment(offer.startDateTime);
    const endDate = this.timeZoneService.moment(offer.endDateTime);

    return {
      ...offer,
      receiveAmount: offer.receiveAmount * 10000,
      startDateTime: offer.startDateTime ? startDate.toDate() : null,
      endDateTime: offer.endDateTime ? endDate.toDate() : null,
      sendTime: offer.sendTime === DEFAULT_SEND_DATETIME ? null : offer.sendTime,
      offerStatusType: offer.offerStatusType,
    };
  }
}

function mapToOfferMetaData(offerMetadata: any): OfferMetadata {
  return {
    NumberOfTextsSent: offerMetadata.numberOfTextsSent,
    PercentUsed: offerMetadata.percentUsed,
    OfferRevenue: offerMetadata.offerRevenue,
    AverageSpend: offerMetadata.averageSpend,
  };
}

function mapToCreateOfferRequest(locationId: number, merchantId: number, offer: SpecialOffer): CreateOfferRequest {
  return {
    LocationId: locationId,
    NamePrivate: offer.name,
    ReceiveAmount: offer.discountAmount,
    SendTime: offer.sendTime,
    StartDateTime: offer.startDateTime,
    EndDateTime: offer.endDateTime,
    StatusType: offer.statusType,
    Display: offer.smsBody!,
    IsDisplayAuto: true,
    ReceiveType: OfferReceiveTypes.AutoApply | OfferReceiveTypes.ReceivePercentage,
    ValidForType: ValidForTypes.ExistingMember,
    QualifierType: OfferQualifierTypes.PurchaseAmount,
    OfferType: OfferTypes.General,
    UpdateDateTime: new Date().toISOString().replace('Z', ''),
  };
}

function mapToUpdateOfferRequest(offer: SpecialOffer): UpdateOfferRequest {
  return {
    OfferId: offer.offerId!,
    NamePrivate: offer.name,
    ReceiveAmount: offer.discountAmount,
    SendTime: offer.sendTime,
    StartDateTime: offer.startDateTime,
    EndDateTime: offer.endDateTime,
    StatusType: offer.statusType,
    Display: offer.smsBody!,
    UpdateDateTime: new Date().toISOString().replace('Z', ''),
  };
}
