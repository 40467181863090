export {};

interface DateSettings {
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
}

declare global {
  interface Date {
    add(data?: DateSettings): Date;
    subtract(data?: DateSettings): Date;
    clone(): Date;
    isSameDate(date: Date): boolean;
    set(data?: DateSettings): Date;
    isSameOrBefore(date: Date): boolean;
  }
}

Date.prototype.add = function (data: DateSettings): Date {
  if (!data) return this;
  if (data.year !== undefined) this.setFullYear(this.getFullYear() + data.year);
  if (data.month !== undefined) this.setMonth(this.getMonth() + data.month);
  if (data.day !== undefined) this.setDate(this.getDate() + data.day);
  if (data.hour !== undefined) this.setHours(this.getHours() + data.hour);
  if (data.minute !== undefined) this.setMinutes(this.getMinutes() + data.minute);
  if (data.second !== undefined) this.setSeconds(this.getSeconds() + data.second);
  if (data.millisecond !== undefined) this.setMilliseconds(this.getMilliseconds() + data.millisecond);

  return this;
};

Date.prototype.subtract = function (data: DateSettings): Date {
  if (!data) return this;
  if (data.year !== undefined) this.setFullYear(this.getFullYear() - data.year);
  if (data.month !== undefined) this.setMonth(this.getMonth() - data.month);
  if (data.day !== undefined) this.setDate(this.getDate() - data.day);
  if (data.hour !== undefined) this.setHours(this.getHours() - data.hour);
  if (data.minute !== undefined) this.setMinutes(this.getMinutes() - data.minute);
  if (data.second !== undefined) this.setSeconds(this.getSeconds() - data.second);
  if (data.millisecond !== undefined) this.setMilliseconds(this.getMilliseconds() - data.millisecond);

  return this;
};

Date.prototype.set = function (data?: DateSettings): Date {
  if (!data) return this;
  if (data.year !== undefined) this.setFullYear(data.year);
  if (data.month !== undefined) this.setMonth(data.month);
  if (data.day !== undefined) this.setDate(data.day);
  if (data.hour !== undefined) this.setHours(data.hour);
  if (data.minute !== undefined) this.setMinutes(data.minute);
  if (data.second !== undefined) this.setSeconds(data.second);
  if (data.millisecond !== undefined) this.setMilliseconds(data.millisecond);
  return this;
};

Date.prototype.clone = function (): Date {
  return new Date(+this);
};

Date.prototype.isSameDate = function (date: Date): boolean {
  return (
    date &&
    this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth() &&
    this.getDate() === date.getDate()
  );
};

Date.prototype.isSameOrBefore = function (date: Date): boolean {
  return this.getTime() === date.getTime() || this.getTime() < date.getTime();
};
