import { Component } from '@angular/core';
import { LoyaltyProgramFormComponent } from '@app/enroll/forms/configure-loyalty-program-form/loyalty-program-form.component';

@Component({
  selector: 'app-merchant-configure-program-step',
  templateUrl: './merchant-configure-program-step.component.html',
  styleUrls: ['./merchant-configure-program-step.component.scss'],
  standalone: true,
  imports: [LoyaltyProgramFormComponent],
})
export class MerchantConfigureProgramStepComponent {}
