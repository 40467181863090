import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EnrollmentReviewQuestionsFormGroup, ReviewQuestionsFormArray } from '@app/enroll/services';
import { InternationalPhoneControlComponent, SimpleTextControlComponent } from '@ep/shared';
import { NgForOf, NgIf } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { WindowService } from '@app/resources/services/window.service';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-review-question-form',
  templateUrl: './review-question-form.component.html',
  styleUrls: ['./review-question-form.component.scss'],
  imports: [
    FormsModule,
    InternationalPhoneControlComponent,
    NgIf,
    ReactiveFormsModule,
    NgForOf,
    ButtonModule,
    DialogModule,
    PanelModule,
    SimpleTextControlComponent,
  ],
  standalone: true,
})
export class ReviewQuestionFormComponent implements OnInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly controlContainer = inject(ControlContainer);
  private readonly windowService = inject(WindowService);

  protected reviewQuestionsForm: EnrollmentReviewQuestionsFormGroup | null = null;
  protected isDialogVisible = false;

  ngOnInit() {
    this.reviewQuestionsForm = this.controlContainer.control as EnrollmentReviewQuestionsFormGroup;
  }

  get questions() {
    return this.reviewQuestionsForm?.controls.Questions as ReviewQuestionsFormArray;
  }

  addQuestion() {
    this.reviewQuestionsForm?.controls.Questions.push(
      new FormControl<string | null>(null, { validators: [Validators.required] })
    );
    this.cdr.detectChanges();
  }

  removeQuestion(index: number) {
    this.reviewQuestionsForm?.controls.Questions.removeAt(index);
    this.cdr.detectChanges();
  }

  get isXSmallScreen() {
    return this.windowService.isXSmallScreen;
  }

  protected showHelp() {
    this.isDialogVisible = true;
  }
}
