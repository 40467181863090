import { inject, Injectable } from '@angular/core';
import { catchError, Observable, tap } from 'rxjs';
import { LoginRequest } from '@app/@shared/models/login-request.model';
import { LoginResponse } from '../models/login-response.model';
import { ApplicationProfile } from '../models/application-profile.model';
import { ChangePassword } from '../models/change-password.model';
import { ResetPasswordRequest } from '../models/reset-password-request.model';
import { Credentials } from '../models/credentials.model';
import { Resources } from '../models/constants';
import { ApiResponse } from '../models/api-response.model';
import { LoginTypes } from '@shared/enums';
import { BaseApiService } from '@ep/shared';
import { Employee } from '../models/employee.model';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HydrationActions } from '@app/resources/ngrx/actions';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseApiService {
  private readonly store = inject(Store);

  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    const json = JSON.stringify(loginRequest);
    return this.post<LoginResponse>('/Login', json, true);
  }

  loginWithTc(loginRequest: LoginRequest): Observable<LoginResponse> {
    this.store.dispatch(HydrationActions.clear());
    const json = JSON.stringify(loginRequest);
    return this.post<LoginResponse>('/LoginwithTC', json, true);
  }

  logout(): Observable<void> {
    return this.get<void>('/Logout', undefined, true);
  }

  forgotPassword(forgotPasswordRequest: ResetPasswordRequest): Observable<void> {
    const json = JSON.stringify(forgotPasswordRequest);
    return this.post<void>('/ForgotPassword', json, true);
  }

  getApplicationProfile(): Observable<ApplicationProfile> {
    return this.get<ApplicationProfile>('/v2/ApplicationProfile/employee').pipe(
      catchError((err) => {
        console.error('Issue with getting employee profile');
        throw err;
      }),
      map(mapToApplicationProfile)
    );
  }

  changePassword(changePassword: ChangePassword): Observable<void> {
    const json = JSON.stringify(changePassword);
    return this.post<void>('/ChangePassword', json, true);
  }

  confirmPasswordReset(confirmPasswordReset: ChangePassword): Observable<void> {
    const json = JSON.stringify(confirmPasswordReset);
    return this.post<void>('/ConfirmPasswordReset', json, true);
  }

  getEmployeeByPasswordResetId(passwordResetId: string): Observable<ApiResponse<Employee>> {
    const queryParams = {
      loginType: LoginTypes.Employee,
    };

    return this.get<ApiResponse<Employee>>(`/PasswordReset/${passwordResetId}`, queryParams, true);
  }
}

function mapToApplicationProfile(profile: any): ApplicationProfile {
  return {
    Employee: {
      $id: profile.employee.$id,
      EmployeeId: profile.employee.employeeId,
      NameFirst: profile.employee.nameFirst,
      NameLast: profile.employee.nameLast,
      AccessType: profile.employee.accessType,
    },
    Merchant: {
      MerchantId: profile.merchant.merchantId,
      Name: profile.merchant.name,
      LoyaltyProgram: {
        LoyaltyProgramId: profile.merchant.loyaltyProgram.loyaltyProgramId,
        Name: profile.merchant.loyaltyProgram.name,
        LoyaltyProgramType: profile.merchant.loyaltyProgram.loyaltyProgramType,
        ReceiveAmount: profile.merchant.loyaltyProgram.receiveAmount,
      },
    },
    Location: {
      LocationId: profile.location.locationId,
      Name: profile.location.name,
      Address: profile.location.address,
      UtcOffsetHour: profile.location.utcOffsetHour,
      PhoneNumber: profile.location.phoneNumber,
      TimeZoneType: profile.location.timeZoneType,
    },
  };
}
