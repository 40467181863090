<ep-auth-container
  [imageSrc]="
    subdomainType === SubdomainTypes.EagleProcessing
      ? '../../assets/eagle-logo.png'
      : '../../assets/enroll-and-pay-logo.png'
  "
>
  <div *ngIf="!shouldShowReturn">
    <div class="mb-4">
      <h5 class="fw-semibold">Forgot Password</h5>
    </div>
    <form [formGroup]="fgForgotPassword" (keyup.enter)="onResetPasswordClicked()">
      <div class="form-group mb-4">
        <div class="flex flex-column gap-2">
          <label class="fw-semibold" htmlFor="email">Email:</label>
          <input
            type="email"
            pInputText
            formControlName="email"
            autocomplete="on"
            [class.is-invalid]="
              fgForgotPassword.get('email')?.invalid &&
              (fgForgotPassword.get('email')?.dirty || fgForgotPassword.get('email')?.touched)
            "
          />
          <div class="invalid-feedback" *ngIf="fgForgotPassword.get('email')?.errors?.['required']">
            Email is required
          </div>
          <div class="invalid-feedback" *ngIf="fgForgotPassword.get('email')?.errors?.['email']">Email is invalid</div>
        </div>
      </div>
      <div class="mb-4">
        <p-button label="RESET" [loading]="isLoading" (onClick)="onResetPasswordClicked()"></p-button>
        <p-button label="CANCEL" styleClass="p-button-secondary" (onClick)="onCancelClicked()"></p-button>
      </div>
    </form>
  </div>

  <div *ngIf="shouldShowReturn">
    <div class="mb-4">
      <h5 class="fw-semibold">Please check your email for a link to reset your password</h5>
    </div>
    <div>
      <a (click)="onCancelClicked()" routerLinkActive="active">Click here to return to login</a>
    </div>
  </div>
</ep-auth-container>
