import { Component, OnInit, HostListener, ViewChild, inject, ElementRef } from '@angular/core';
import { ActivatedRoute, Event as NavigationEvent, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { IframeEventTypes } from '@app/@shared/enums/iframe-event-types.enum';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { IframeManagerService } from '@app/@shared/services/iframe-manager.service';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { CredentialsService } from '@app/auth';
import { environment } from '@env/environment';
import { ConfigurationService, IframeManagerComponent, isNotNullOrUndefined } from '@ep/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, debounce, debounceTime, filter, of, skip, Subscription, tap } from 'rxjs';
import { WindowService } from '@app/resources/services/window.service';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { LegacyLoadedService } from '@app/resources/services/legacy-loaded.service';
import { switchMap } from 'rxjs/operators';
import { BreadcrumbService } from '@app/resources/services/breadcrumb.service';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { Store } from '@ngrx/store';
import { ApplicationProfileActions, CredentialsActions } from '@app/resources/ngrx/actions';
import { SignalRService } from '@app/resources/services/signalr.service';
import { SignalrTargetsEnum } from '@shared/enums';
import { Credentials } from '@shared/models';

@UntilDestroy()
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  private readonly breadcrumbService = inject(BreadcrumbService);
  private readonly windowService = inject(WindowService);
  private readonly timezoneService = inject(TimezoneService);
  private readonly legacyLoadedService = inject(LegacyLoadedService);
  private readonly signalRService = inject(SignalRService);
  private readonly store = inject(Store);
  @ViewChild('iframeManager', { static: true }) iframeManager!: IframeManagerComponent;

  // Icons
  icons = FONT_AWESOME_ICONS;

  // Component State.
  isLoading: boolean = false;
  shouldShowIframe: boolean = false;
  iframeWidth: string = '0%';
  iframeHeight: string = '0%';
  showMobileDropdown: boolean = false;

  // Application Data.
  AccessType = AccessType;
  applicationProfile!: ApplicationProfile;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;

  subs = new Subscription();

  constructor(
    private router: Router,
    private applicationProfileService: ApplicationProfileService,
    private iframeManagerService: IframeManagerService,
    private credentialsService: CredentialsService,
    private configurationService: ConfigurationService,
    private subdomainService: SubdomainService
  ) {}

  private _resize$ = new BehaviorSubject<boolean>(false);
  readonly resize$ = this._resize$.asObservable().pipe(filter(isNotNullOrUndefined));

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this._resize$.next(true);
  }

  ngOnInit(): void {
    this.signalRService.hubConnection.on(SignalrTargetsEnum.onJwtChange, (credentials: any) => {
      this.credentialsService.setCredentials(credentials);
      this.store.dispatch(CredentialsActions.updateCredentials({ payload: { credentials } }));
      this.legacyLoadedService.authenticateLegacy(
        this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy,
        this.credentialsService.credentials
      );
    });

    this.subs.add(
      this.resize$
        .pipe(
          debounceTime(300),
          tap(() => {
            this.windowService.checkScreenSize();

            document.documentElement.style.setProperty('--screenHeight', `${window.innerHeight}px`);
          })
        )
        .subscribe()
    );

    this.subdomainType = this.subdomainService.getDomain();
    this.windowService.checkScreenSize();
    this.subscribeToApplicationProfile();
    this.subscribeToIframeService();
    this.legacyLoadedService.authenticateLegacy(
      this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy,
      this.credentialsService.credentials
    );
    // Load the legacy iframe in the background
    this.legacyLoadedService.authenticateLegacyIframe$
      .pipe(
        tap((iframe) => {
          this.iframeManagerService.setIframeSrc(iframe);
        })
      )
      .subscribe();

    // Check initial route to figure out if we need to wait on legacy components or not
    this.checkCurrentRoute();

    // Subscribe to the iframe source changes.
    this.iframeManagerService.iframeSrc$.pipe(untilDestroyed(this)).subscribe((src: string) => {
      this.iframeManager.updateSanitizedUrl(src);
    });

    this.iframeManagerService
      .onMessageReceived()
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          // do something
          return of(null);
        }),
        filter(
          (event) =>
            event?.origin ===
              (this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy) &&
            (event?.data === IframeEventTypes.LegacyAppLoaded || event?.data === IframeEventTypes.NewAdmin)
        )
      )
      .subscribe((event) => {
        if (event?.data === IframeEventTypes.LegacyAppLoaded) {
          this.legacyLoadedService.setLegacyLoadedStatus(true);
          this.handleLegacyAppMessage();
        } else if (event?.data === IframeEventTypes.NewAdmin) {
          this.router.navigateByUrl(
            `${this.applicationProfileService.getApplicationProfile().Location.LocationId}/admin`
          );
        }
      });

    this.subs.add(
      this.router.events
        .pipe(filter((event: NavigationEvent) => event instanceof NavigationStart || event instanceof NavigationEnd))
        .subscribe((event: any) => {
          if (event instanceof NavigationStart) {
            if (
              event.url.includes('transactions') ||
              event.url.includes('reviews') ||
              event.url.includes('members') ||
              event.url.includes('reports') ||
              event.url.includes('messages') ||
              (event.url.includes('admin') && event.url.includes('billing'))
            ) {
              if (this.legacyLoadedService.isLegacyLoaded) {
                this.iframeManagerService.setShouldShowIframe(true);
              } else {
                this.isLoading = true;

                this.legacyLoadedService.authenticateLegacyIframe$
                  .pipe(
                    tap((iframe) => {
                      this.iframeManagerService.setIframeSrc(iframe);
                    }),
                    switchMap(() => {
                      return this.legacyLoadedService.isLegacyLoaded$;
                    })
                  )
                  .subscribe((isLegacyLoaded) => {
                    this.isLoading = !isLegacyLoaded;
                  });
              }
            } else {
              this.iframeManagerService.setShouldShowIframe(false);
            }
          } else if (event instanceof NavigationEnd) {
            this.breadcrumbService.updateBreadcrumbs(this.applicationProfile.Location.LocationId);
          }
        })
    );

    this.breadcrumbService.updateBreadcrumbs(this.applicationProfile.Location.LocationId);
  }

  get breadcrumbs$() {
    return this.breadcrumbService.breadcrumbs$;
  }

  get home$() {
    return this.breadcrumbService.home$;
  }

  get shouldShowBreadcrumbs() {
    return !this.isLoading && !this.shouldShowIframe && this.breadcrumbService.shouldShowBreadcrumbs;
  }

  get isLegacyLoaded() {
    return this.legacyLoadedService.isLegacyLoaded;
  }

  get isMobileScreen() {
    return this.windowService.isMobileScreen;
  }

  get isSmallScreen() {
    return this.windowService.isSmallScreen;
  }

  get shouldShowFullWidth() {
    return this.windowService.shouldShowFullWidth;
  }

  set shouldShowFullWidth(showFullWidth: boolean) {
    this.windowService.setShowFullWidth(showFullWidth);
  }

  onCloseClicked(): void {
    this.shouldShowFullWidth = !this.shouldShowFullWidth;
  }

  onNavigation(resource: string) {
    this.isLoading = true;
    this.iframeManagerService.setShouldShowIframe(false);
    this.router.navigateByUrl(`/${this.applicationProfile.Location.LocationId}/${resource}`);
  }

  onNavigationIFrame(primaryPath: string, primaryPathId?: string): void {
    this.isLoading = true;
    // If legacy is not yet loaded, we still navigate to the new url
    // Once legacy has loaded, then the app should take of the rest and load
    // the appropriate legacy component
    if (!this.legacyLoadedService.isLegacyLoaded) {
      const locationId = this.applicationProfile.Location.LocationId;
      const path = `${locationId}/${primaryPath}${primaryPathId ? '/' + primaryPathId : ''}`;
      this.router.navigate([path]);
    } else {
      this.updateIframeAndNavigate(primaryPath, primaryPathId);
    }
  }

  onToggleMobileDropdown(): void {
    this.showMobileDropdown = !this.showMobileDropdown;
  }

  closeMobileDropdown(): void {
    this.showMobileDropdown = false;
  }

  shouldShowMenuItem(accessType: AccessType): boolean {
    return this.credentialsService.hasAccessAny([accessType]);
  }

  getImageSrc(): string {
    const prefix = (this.isSmallScreen && !this.shouldShowFullWidth) || !this.shouldShowFullWidth ? 'small-' : '';
    const suffix = this.subdomainType === SubdomainTypes.EagleProcessing ? 'eagle-logo.png' : 'enroll-and-pay-logo.png';
    return `../../assets/${prefix}${suffix}`;
  }

  private subscribeToApplicationProfile(): void {
    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
        this.timezoneService.initializeTimezone(this.applicationProfile.Location.TimeZoneType);
        this.store.dispatch(
          ApplicationProfileActions.loadApplicationProfilesSuccess({ payload: { profile: applicationProfile } })
        );
      });
  }

  private subscribeToIframeService(): void {
    this.iframeManagerService.shouldShowIframe$
      .pipe(untilDestroyed(this), skip(1))
      .subscribe((shouldShowIframe: boolean) => {
        this.adjustIframeDimensions(shouldShowIframe);
        this.isLoading = false;
      });
  }

  private adjustIframeDimensions(shouldShow: boolean): void {
    this.shouldShowIframe = shouldShow;
    const dimension = shouldShow ? '100%' : '0%';
    this.iframeWidth = dimension;
    this.iframeHeight = dimension;
  }

  private updateIframeAndNavigate(primaryPath: string, primaryPathId?: string): void {
    const locationId = this.applicationProfile.Location.LocationId;
    const iframeSrc = this.iframeManagerService.generateIframeSrc(
      this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy,
      locationId,
      primaryPath,
      primaryPathId
    );
    if (iframeSrc) {
      this.iframeManagerService.setIframeSrc(iframeSrc);
      const path = `${locationId}/${primaryPath}${primaryPathId ? '/' + primaryPathId : ''}`;
      this.router.navigate([path]);
      this.iframeManagerService.setShouldShowIframe(true);
    }
  }

  private handleLegacyAppMessage(): void {
    this.checkCurrentRoute();
    this.isLoading = false;
  }

  private checkCurrentRoute(): void {
    const currentRoute = this.router.url;
    if (currentRoute === '/') {
      this.onNavigation('dashboard');
    } else {
      const segments = currentRoute.split('/');
      this.navigateOnSegments(segments);
    }
  }

  /**
   * We'll have to manually update this as we port over more resources to the new Angular app.
   * If the url segment contains a route that is implemented in Angular, route to the new Angular
   * app instead of loading the legacy iframe.
   * @param segments segments of the current url broken up by "/"
   * @private
   */
  private navigateOnSegments(segments: string[]) {
    this.isLoading = true;
    if (
      segments.includes('offers') ||
      segments.includes('dashboard') ||
      (segments.includes('admin') && !segments.includes('locations') && !segments.includes('billing'))
    ) {
      const path = segments.slice(2).join('/');
      this.onNavigation(path);
    } else {
      this.onNavigationIFrame(segments[2], segments[3]);
    }
  }
}
