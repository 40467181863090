import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Device } from '@app/resources/services';

const sortDeviceById = (a: Device, b: Device) => {
  return a.DeviceId - b.DeviceId;
};

export const deviceAdapter: EntityAdapter<Device> = createEntityAdapter<Device>({
  sortComparer: sortDeviceById,
  selectId: (e) => e?.DeviceId ?? 0,
});

export const {
  selectIds: selectDeviceIds,
  selectEntities: selectDeviceEntities,
  selectAll: selectAllDevices,
  selectTotal: devicesCount,
} = deviceAdapter.getSelectors();
