import { Component, inject, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { PercentageControlComponent, SimpleTextControlComponent } from '@ep/shared';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { OfferDetailsFormGroup } from '@app/areas/offers/services/offer.form.service';
import { combineLatest, concat, defer, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoyaltyProgramsTypesEnum } from '@shared/enums';
import { ApplicationProfileService } from '@shared/services/application-profile.service';

@Component({
  selector: 'app-offer-details-form',
  templateUrl: './offer-details-form.component.html',
  styleUrls: ['./offer-details-form.component.scss'],
  imports: [AsyncPipe, NgIf, PercentageControlComponent, ReactiveFormsModule, SimpleTextControlComponent],
  standalone: true,
})
export class OfferDetailsFormComponent implements OnInit {
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly controlContainer = inject(ControlContainer);
  protected offerDetailsForm: OfferDetailsFormGroup | null = null;
  protected offerDetailsSubHeader$: Observable<string | null> | null = null;

  ngOnInit() {
    this.offerDetailsForm = this.controlContainer.control as OfferDetailsFormGroup;
    if (this.offerDetailsForm) {
      this.offerDetailsSubHeader$ = combineLatest([
        this.applicationProfileService.applicationProfile$,
        concat(
          defer(() => of(this.offerDetailsForm!.controls.DiscountPercentage.value)),
          this.offerDetailsForm.controls.DiscountPercentage.valueChanges
        ),
      ]).pipe(
        map(([appProfile, discount]) => {
          if (discount === null) return null;

          const offerDiscount = discount;
          const rewardType =
            (appProfile.Merchant.LoyaltyProgram.LoyaltyProgramType & LoyaltyProgramsTypesEnum.CashBack) ===
            LoyaltyProgramsTypesEnum.CashBack
              ? 'cash back rewards'
              : 'point rewards';

          return `(Members will receive an additional ${offerDiscount}% discount on top of ${rewardType} earned on each net transaction)`;
        })
      );
    }
  }
}
