import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { OfferDate, OfferFormService, WhenOfferDateEnum } from '@app/areas/offers/services/offer.form.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoyaltyProgramsTypesEnum, OfferTextReminderEnum, OfferTextReminderMap } from '@shared/enums';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concat,
  defer,
  of,
  pairwise,
  startWith,
  Subscription,
  tap,
} from 'rxjs';
import { NgIf } from '@angular/common';
import { ApplicationProfileService } from '@shared/services/application-profile.service';
import { getDefaultSMS } from '@app/areas/offers/utils';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { OfferDetailsFormComponent } from '@app/areas/offers/forms/offer-details-form/offer-details-form.component';
import { OfferScheduledSmsFormComponent } from '@app/areas/offers/forms/offer-scheduled-sms-form/offer-scheduled-sms-form.component';
import { OfferSmsBodyFormComponent } from '@app/areas/offers/forms/offer-sms-body-form/offer-sms-body-form.component';
import { OfferDurationFormComponent } from '@app/areas/offers/forms/offer-duration-form/offer-duration-form.component';
import moment from 'moment-timezone';
import '@shared/extensions/date.extensions'; // Needed to use Date extensions

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    OfferDetailsFormComponent,
    OfferScheduledSmsFormComponent,
    OfferSmsBodyFormComponent,
    OfferDurationFormComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferFormComponent implements AfterViewInit, OnDestroy {
  @Input() action: 'add' | 'edit' | 'view' = 'view';
  private readonly applicationProfileService = inject(ApplicationProfileService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly offerFormService = inject(OfferFormService);
  private readonly timezoneService = inject(TimezoneService);
  offerForm = this.offerFormService.getOfferForm();
  private subs = new Subscription();

  private _maxDate$ = new BehaviorSubject<Date>(this.timezoneService.moment().add(1, 'years').toDate());
  protected maxDate$ = this._maxDate$.asObservable();
  protected minDate = this.timezoneService
    .moment()
    .add(this.timezoneService.getLocationClientOffset(), 'hours')
    .toDate();
  protected _minEndDate$ = new BehaviorSubject<Date>(this.minDate);
  protected minEndDate$ = this._minEndDate$.asObservable();

  ngAfterViewInit(): void {
    if (
      this.action !== 'view' &&
      this.offerFormService.getOfferDuration(this.offerForm).controls.EndDate.value.date !== null
    ) {
      this.offerFormService.getOfferDuration(this.offerForm).controls.EndDate.enable();
    }

    this.subs.add(
      this.offerForm.controls.ScheduledSMS.controls.Reminder.valueChanges
        .pipe(startWith(this.offerForm.controls.ScheduledSMS.controls.Reminder.value))
        .subscribe((reminder) => {
          const startDate = this.offerForm!.controls.Duration.controls.StartDate.value.date;
          if (!!reminder && !!startDate) {
            const key = parseInt(reminder.key) as OfferTextReminderEnum;
            this.updateSMSDateControlValue(key, startDate);
          }
        })
    );
    this.subs.add(
      concat(
        defer(() => of(this.offerForm!.controls.ScheduledSMS.controls.SMSDate.value)),
        this.offerForm!.controls.ScheduledSMS.controls.SMSDate.valueChanges
      )
        .pipe(
          startWith({
            when: WhenOfferDateEnum.CUSTOM,
            date: null,
          } as OfferDate),
          tap(() => {
            this.offerFormService
              .getOfferScheduledSMS(this.offerForm!)
              .controls.Reminder.updateValueAndValidity({ emitEvent: false });
          })
        )
        .subscribe()
    );

    this.subs.add(
      this.offerForm.controls.Duration.controls.StartDate.valueChanges
        .pipe(
          startWith({
            when: WhenOfferDateEnum.CUSTOM,
            date: null,
          } as OfferDate),
          pairwise(),
          catchError((err) => {
            console.error(err);
            throw err;
          }),
          tap((startDate) => {
            if (!!startDate[1].date) {
              this.offerFormService
                .getOfferScheduledSMS(this.offerForm!)
                .controls.SMSDate.updateValueAndValidity({ emitEvent: false });
              this.offerFormService.getOfferScheduledSMS(this.offerForm!).controls.SMSDate.markAsDirty();
              this.offerFormService
                .getOfferScheduledSMS(this.offerForm!)
                .controls.Reminder.updateValueAndValidity({ emitEvent: false });
              this.offerFormService.getOfferScheduledSMS(this.offerForm!).controls.Reminder.markAsDirty();

              if (this.action !== 'view' && this.offerForm.controls.Duration.controls.EndDate.disabled) {
                this.offerForm.controls.Duration.controls.EndDate.enable();
              }

              this._minEndDate$.next(this.timezoneService.moment(startDate[1].date).toDate());
              const now = this.timezoneService
                .moment()
                .add(this.timezoneService.getLocationClientOffset(), 'hours')
                .toDate();
              const start = this.timezoneService.moment(startDate[1].date).toDate();
              const smsDate = this.offerForm.controls.ScheduledSMS.controls.SMSDate.value.date;

              if (
                !startDate[0].date &&
                ((now.getFullYear() === start.getFullYear() &&
                  now.getMonth() === start.getMonth() &&
                  now.getHours() === start.getHours() &&
                  now.getMinutes() === start.getMinutes()) ||
                  (smsDate && this.timezoneService.moment(smsDate).isSameOrBefore(now)))
              ) {
                this.offerForm.controls.ScheduledSMS.controls.Reminder.setValue({
                  key: `${OfferTextReminderEnum.NOW}`,
                  value: `${OfferTextReminderMap[OfferTextReminderEnum.NOW]}`,
                });
              }

              const reminder = this.offerForm!.controls.ScheduledSMS.controls.Reminder.value;
              if (reminder === null) {
                this.offerFormService.setOfferScheduledSMS({
                  reminder: {
                    key: `${OfferTextReminderEnum.ONE_HOUR_BEFORE}`,
                    value: OfferTextReminderMap[OfferTextReminderEnum.ONE_HOUR_BEFORE],
                  },
                });
              } else {
                const key = parseInt(reminder.key) as OfferTextReminderEnum;
                this.updateSMSDateControlValue(key, startDate[1].date);
              }

              const endDate = this.offerForm!.controls.Duration.controls.EndDate.value.date;
              if (!endDate || (endDate && endDate.getTime() <= start.getTime())) {
                const end = start;
                end.setHours(23);
                end.setMinutes(59);
                end.setSeconds(0);
                end.setMilliseconds(0);
                this.offerForm!.controls.Duration.controls.EndDate.setValue({
                  when: WhenOfferDateEnum.CUSTOM,
                  date: end,
                });
              }
            }
          })
        )
        .subscribe()
    );

    this.subs.add(
      combineLatest([
        concat(
          defer(() => of(this.offerForm!.controls.Details.controls.Name.value)),
          this.offerForm.controls.Details.controls.Name.valueChanges
        ),
        concat(
          defer(() => of(this.offerForm!.controls.Details.controls.Location.value)),
          this.offerForm.controls.Details.controls.Location.valueChanges
        ),
        concat(
          defer(() => of(this.offerForm!.controls.Details.controls.DiscountPercentage.value)),
          this.offerForm.controls.Details.controls.DiscountPercentage.valueChanges
        ),
        concat(
          defer(() => of(this.offerForm!.controls.Duration.controls.StartDate.value)),
          this.offerForm.controls.Duration.controls.StartDate.valueChanges
        ),
        concat(
          defer(() => of(this.offerForm!.controls.Duration.controls.EndDate.value)),
          this.offerForm.controls.Duration.controls.EndDate.valueChanges
        ),
      ]).subscribe(([name, location, discount, start, end]) => {
        if (!!name && !!location && discount != null && !!start.date && !!end.date) {
          const appProfile = this.applicationProfileService.getApplicationProfile();
          const newDiscount =
            (appProfile.Merchant.LoyaltyProgram.LoyaltyProgramType & LoyaltyProgramsTypesEnum.CashBack) !==
            LoyaltyProgramsTypesEnum.CashBack
              ? discount
              : appProfile.Merchant.LoyaltyProgram.ReceiveAmount * 10000 + discount;

          this.offerFormService.setOfferScheduledSMS({
            smsBody: getDefaultSMS(
              appProfile.Location.Name,
              location,
              {
                startDate: start.date,
                endDate: end.date,
              },
              name,
              newDiscount
            ),
          });
        } else {
          this.offerForm.controls.ScheduledSMS.controls.SMSBody.setValue('');
        }
      })
    );

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private updateSMSDateControlValue(key: OfferTextReminderEnum, date: Date | string) {
    if (key === OfferTextReminderEnum.CUSTOM && this.action !== 'view') {
      this.offerForm!.controls.ScheduledSMS.controls.SMSDate.enable();
    } else this.offerForm!.controls.ScheduledSMS.controls.SMSDate.disable();

    let offerDate = this.offerForm!.controls.ScheduledSMS.controls.SMSDate.getRawValue();
    // If date exists, use existing date. Else, set custom date to 1 hour behind start date.
    if (key === OfferTextReminderEnum.CUSTOM) {
      if (offerDate.date != null) {
        this.updateSMSDate(key, offerDate.date);
      } else {
        this.updateSMSDate(OfferTextReminderEnum.ONE_HOUR_BEFORE, date);
      }
      if (this.action !== 'view') {
        this.offerForm!.controls.ScheduledSMS.controls.SMSDate.enable();
      } else this.offerForm!.controls.ScheduledSMS.controls.SMSDate.disable();
    } else {
      this.updateSMSDate(key, date);
    }
  }

  private updateSMSDate(key: OfferTextReminderEnum, date: Date | string) {
    const dateMoment = new Date(date);
    const hour = dateMoment.getHours();
    const minute = dateMoment.getMinutes();
    switch (key) {
      case OfferTextReminderEnum.CUSTOM:
        this._maxDate$.next(dateMoment.clone().add({ day: 1 }));
        this.offerFormService.setOfferScheduledSMS({
          smsDate: {
            when: WhenOfferDateEnum.CUSTOM,
            date: dateMoment,
          },
        });
        break;
      case OfferTextReminderEnum.ONE_HOUR_BEFORE:
        const hourBefore =
          hour <= 8
            ? dateMoment.subtract({ day: 1 }).set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
            : ((22 == hour && 1 <= minute) || 22 < hour) && hour < 24
            ? dateMoment.set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
            : dateMoment.subtract({ hour: 1 });

        this.offerFormService.setOfferScheduledSMS({
          smsDate: {
            when: WhenOfferDateEnum.CUSTOM,
            date: hourBefore,
          },
        });
        break;
      case OfferTextReminderEnum.ONE_DAY_BEFORE:
        const dayBefore =
          hour < 8
            ? dateMoment.subtract({ day: 1 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
            : ((21 === hour && minute > 0) || 22 <= hour) && hour < 24
            ? dateMoment.set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
            : dateMoment.subtract({ day: 1 });

        this.offerFormService.setOfferScheduledSMS({
          smsDate: {
            when: WhenOfferDateEnum.CUSTOM,
            date: dayBefore,
          },
        });
        break;
      case OfferTextReminderEnum.ONE_WEEK_BEFORE:
        const weekBefore =
          hour < 8 || (((21 == hour && 1 <= minute) || 22 <= hour) && hour < 24)
            ? dateMoment.subtract({ day: 6 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
            : dateMoment.subtract({ day: 7 });

        this.offerFormService.setOfferScheduledSMS({
          smsDate: {
            when: WhenOfferDateEnum.CUSTOM,
            date: weekBefore,
          },
        });
        break;
      case OfferTextReminderEnum.NOW:
        this.offerFormService.setOfferScheduledSMS({
          smsDate: {
            when: WhenOfferDateEnum.NOW,
            date: this.timezoneService
              .moment()
              .add(this.timezoneService.getLocationClientOffset(), 'hours')
              .second(0)
              .millisecond(0)
              .toDate(),
          },
        });
        break;
      default:
        break;
    }
  }
}
