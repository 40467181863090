<div class="dashboard-custom-search-wrapper">
  <p-panel>
    <ng-template pTemplate="header">
      <div class="panel-header-wrapper">
        <img class="png-icon" src="../../../../../assets/images/mn_icon.png" alt="dashboard-custom-search-header" />
        <span class="panel-header">DATE SEARCH</span>
      </div>
    </ng-template>
    <div class="custom-search-content">
      <form class="custom-search-row" [formGroup]="searchForm">
        <div class="custom-search-calendar-wrapper">
          <label for="fromDateSearch" class="search-calendar-label"> From Date </label>
          <p-calendar
            formControlName="fromDate"
            dateFormat="mm/dd/yy"
            [showIcon]="true"
            [maxDate]="maxToDate"
            inputId="fromDateSearch"
            class="calendarContainer"
          ></p-calendar>
          <div
            *ngIf="
              searchForm.controls.fromDate.errors &&
              !!searchForm.controls.fromDate.value &&
              !!searchForm.controls.toDate.value
            "
            class="from-date-error-message"
          >
            <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon>
            <p>Start date must be the same or before the end date</p>
          </div>
          <div
            *ngIf="
              searchForm.controls.fromDate.errors &&
              searchForm.controls.fromDate.dirty &&
              searchForm.controls.fromDate.value === null
            "
            class="from-date-error-message"
          >
            <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon>
            <p>Must provide a valid date</p>
          </div>
        </div>
        <div class="custom-search-calendar-wrapper">
          <label for="toDateSearch" class="search-calendar-label"> To Date </label>
          <p-calendar
            formControlName="toDate"
            dateFormat="mm/dd/yy"
            [showIcon]="true"
            [maxDate]="maxToDate"
            inputId="toDateSearch"
          ></p-calendar>
          <div
            *ngIf="
              searchForm.controls.toDate.errors &&
              searchForm.controls.toDate.dirty &&
              searchForm.controls.toDate.value === null
            "
            class="from-date-error-message"
          >
            <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon>
            <p>Must provide a valid date</p>
          </div>
        </div>
      </form>
      <div class="custom-search-button-wrapper">
        <p-button label="Search" (onClick)="search()" [disabled]="!searchForm.valid || searchForm.pristine"></p-button>
      </div>
    </div>
  </p-panel>
</div>
