import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Routes } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@shared';
import { I18NLanguageType, I18nService } from '@app/i18n';
import { SubdomainService } from '@shared/services/subdomain.service';
import { SignalrTargetsEnum, SubdomainTypes } from '@shared/enums';
import { ConfigurationService } from '@ep/shared';
import { WindowService } from '@app/resources/services/window.service';
import { SignalRService } from '@app/resources/services/signalr.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly signalRService = inject(SignalRService);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private configurationService: ConfigurationService,
    private subdomainService: SubdomainService,
    private windowService: WindowService
  ) {}

  ngOnInit() {
    this.setFavicon();
    this.signalRService.startConnection();

    // Setup translations
    this.i18nService.init(
      (this.configurationService.defaultLanguage ?? environment.defaultLanguage) as I18NLanguageType,
      (this.configurationService.supportedLanguages ?? environment.supportedLanguages) as I18NLanguageType[]
    );

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // Handle unsupported routes
    onNavigationEnd
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        filter((event) => event.url.includes('#') && !this.isValidRoute(event.urlAfterRedirects)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.router.navigate(['/not-found']);
      });
  }

  private isValidRoute(url: string): boolean {
    return this.isUrlInRoutes(url, this.router.config);
  }

  get isMobile() {
    return this.windowService.isMobileScreen;
  }

  private isUrlInRoutes(url: string, routes: Routes): boolean {
    for (const route of routes) {
      if (route.path === url) {
        return true;
      }

      if (route.children) {
        if (this.isUrlInRoutes(url, route.children)) {
          return true;
        }
      }
    }

    return false;
  }

  private setFavicon(): void {
    const link: HTMLLinkElement | null = document.querySelector('#favicon');
    const domain = this.subdomainService.getDomain();

    if (link) {
      switch (domain) {
        case SubdomainTypes.EagleProcessing:
          link.href = 'assets/favicons/eagleFavicon.ico';
          break;
        case SubdomainTypes.EnrollAndPay:
          link.href = 'assets/favicons/enrollAndPayFavicon.ico';
          break;
        default:
          link.href = 'assets/favicons/enrollAndPayFavicon.ico';
      }
    }
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
