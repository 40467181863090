<div *appLoading="isLoading" class="full-height-width">
  <div *ngIf="!isCompleted; else completionPage" class="full-height-width">
    <div id="stepper-wrapper" data-cy="enrollment-stepper" *ngIf="enrollmentForm && !isSubmittingEnrollment">
      <p-steps [model]="items" [activeIndex]="activeIndex" id="enrollment-steps" data-cy="enrollment-steps"></p-steps>
      <div id="enrollment-step-card-wrapper" data-cy="enrollment-stepper-content">
        <p-card id="enrollment-step-card" data-cy="enrollment-step-card">
          <ng-template pTemplate="header">
            <div id="enrollment-card-header" data-cy="enrollment-step-card-header">
              <h3>{{ getStepHeader() }}</h3>
            </div>
          </ng-template>
          <div [ngSwitch]="activeIndex" id="enrollment-step-wrapper">
            <app-merchant-account-step *ngSwitchCase="0" data-cy="enrollment-step-account"></app-merchant-account-step>
            <app-merchant-choose-program-step
              *ngSwitchCase="1"
              data-cy="enrollment-step-choose-program"
            ></app-merchant-choose-program-step>
            <app-merchant-configure-program-step
              *ngSwitchCase="2"
              data-cy="enrollment-step-configure-program"
            ></app-merchant-configure-program-step>
            <app-merchant-choose-offer-step
              *ngSwitchCase="3"
              data-cy="enrollment-step-choose-offer"
            ></app-merchant-choose-offer-step>
            <app-merchant-configure-offer-step
              *ngSwitchCase="4"
              data-cy="enrollment-step-configure-offer"
            ></app-merchant-configure-offer-step>
            <app-merchant-review-question-step
              *ngSwitchCase="5"
              data-cy="enrollment-step-review-questions"
            ></app-merchant-review-question-step>
            <app-merchant-account-step *ngSwitchDefault></app-merchant-account-step>
          </div>
          <ng-template pTemplate="footer">
            <div
              [ngClass]="{
                'enrollment-card-footer-1': activeIndex !== 0,
                'enrollment-card-footer-2': activeIndex === 0
              }"
              data-cy="enrollment-step-card-footer"
            >
              <p-button
                *ngIf="activeIndex !== 0"
                (onClick)="goToPreviousStep()"
                [style]="{ height: '100%' }"
                [loading]="isSaving"
                size="large"
                id="enrollment-back-button"
                data-cy="enrollment-step-back-button"
              >
                Back
              </p-button>
              <p-button
                *ngIf="activeIndex !== lastIndex"
                (onClick)="goToNextStep()"
                [style]="{ height: '100%' }"
                [disabled]="(isStepInvalid$ | async) !== false"
                [loading]="isSaving"
                size="large"
                id="enrollment-next-button"
                data-cy="enrollment-step-next-button"
              >
                Next
              </p-button>
              <p-button
                *ngIf="activeIndex === lastIndex"
                (onClick)="submitEnrollment()"
                [style]="{ height: '100%' }"
                [disabled]="enrollmentForm.status !== 'VALID'"
                [loading]="isSaving"
                size="large"
                id="enrollment-submit-button"
                data-cy="enrollment-step-submit-button"
              >
                Submit
              </p-button>
            </div>
          </ng-template>
        </p-card>
      </div>
    </div>
    <div *ngIf="isSubmittingEnrollment" class="enrollment-submission-wrapper">
      <app-enrollment-submission [enrollmentSubmissionObservable]="submitEnrollment$"></app-enrollment-submission>
    </div>
    <p-confirmDialog
      key="enrollment-submission-confirmation"
      [style]="{ 'max-width': '400px' }"
      data-cy="enrollment-submission-confirmation-dialog"
    >
    </p-confirmDialog>
  </div>
  <ng-template #completionPage>
    <app-enrollment-complete></app-enrollment-complete>
  </ng-template>
</div>
