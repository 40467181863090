import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Subscription, tap } from 'rxjs';
import { OfferReminder } from '@app/areas/offers/utils';
import { OfferTextReminderEnum, OfferTextReminderMap } from '@shared/enums';

const DEFAULT_OFFER_REMINDER: OfferReminder = {
  key: `${OfferTextReminderEnum.ONE_HOUR_BEFORE}`,
  value: OfferTextReminderMap[OfferTextReminderEnum.ONE_HOUR_BEFORE],
};

@Component({
  selector: 'app-offer-sms-reminder-control',
  templateUrl: './offer-sms-reminder-control.component.html',
  styleUrls: ['./offer-sms-reminder-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, DropdownModule, NgIf, NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OfferSmsReminderControlComponent,
      multi: true,
    },
  ],
})
export class OfferSmsReminderControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  protected reminder = new FormControl<OfferReminder>(DEFAULT_OFFER_REMINDER, {
    validators: [Validators.required],
    nonNullable: true,
  });

  protected reminders = Object.entries(OfferTextReminderMap).map((reminder) => {
    return {
      key: reminder[0],
      value: reminder[1],
    };
  });
  onChange: any = (value: string) => {};
  onTouch: any = () => {};

  value: OfferReminder = DEFAULT_OFFER_REMINDER;

  private subs = new Subscription();
  private isFocused = false;

  ngOnInit(): void {
    this.subs.add(
      this.reminder.valueChanges
        .pipe(
          tap((value) => {
            this.value = value;
            this.onChange(this.value);
            this.onTouch();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected onFocusIn() {
    if (!this.isFocused) {
      this.isFocused = true;
    }
  }

  protected onFocusOut() {
    this.isFocused = false;
  }

  protected get isFocusedOut() {
    return !this.isFocused;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.reminder.disable({ emitEvent: false }) : this.reminder.enable({ emitEvent: false });
  }

  setValue(value?: OfferReminder | null) {
    this.value = value ?? DEFAULT_OFFER_REMINDER;
  }

  writeValue(value?: OfferReminder): void {
    this.setValue(value ?? null);
    this.reminder.setValue(value ?? DEFAULT_OFFER_REMINDER, { emitEvent: false });
  }
}
