import { Component, inject } from '@angular/core';
import { LoyaltyProgramCashbackFormComponent } from '@app/enroll/forms/loyalty-program-cashback-form/loyalty-program-cashback-form.component';
import { LoyaltyProgramPointsFormComponent } from '@app/enroll/forms/loyalty-program-points-form/loyalty-program-points-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EnrollmentFormService } from '@app/enroll/services/enrollment-form.service';
import { LoyaltyProgramsTypesEnum } from '@shared/enums';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loyalty-program-form',
  templateUrl: './loyalty-program-form.component.html',
  styleUrls: ['./loyalty-program-form.component.scss'],
  standalone: true,
  imports: [LoyaltyProgramCashbackFormComponent, LoyaltyProgramPointsFormComponent, ReactiveFormsModule, NgIf],
})
export class LoyaltyProgramFormComponent {
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  protected loyaltyProgramForm = this.enrollmentFormService.getLoyaltyProgramForm();

  get cashBackForm() {
    return this.loyaltyProgramForm.controls.CashBack;
  }

  get pointsForm() {
    return this.loyaltyProgramForm.controls.Points;
  }

  get isCashBack() {
    return (
      this.enrollmentFormService.getLoyaltyProgramForm().controls.Selected.value === LoyaltyProgramsTypesEnum.CashBack
    );
  }
}
