import { EmployeeAccessTypes, LoyaltyProgramsTypesEnum } from '@shared/enums';
import { TimeZonesEnum } from '@ep/shared';

interface ApplicationProfileEmployee {
  $id: number;
  EmployeeId: number;
  NameFirst: string;
  NameLast: string;
  AccessType: EmployeeAccessTypes;
}

interface ApplicationProfileLoyaltyProgram {
  LoyaltyProgramId: number;
  Name: string;
  LoyaltyProgramType: LoyaltyProgramsTypesEnum;
  ReceiveAmount: number;
}

interface ApplicationProfileMerchant {
  MerchantId: number;
  Name: string;
  LoyaltyProgram: ApplicationProfileLoyaltyProgram;
}

interface ApplicationProfileLocation {
  LocationId: number;
  Name: string;
  Address: string;
  UtcOffsetHour: number;
  PhoneNumber: number;
  TimeZoneType: TimeZonesEnum;
}

export interface ApplicationProfile {
  Employee: ApplicationProfileEmployee;
  Merchant: ApplicationProfileMerchant;
  Location: ApplicationProfileLocation;
}
