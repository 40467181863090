import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

type DashboardCustomSearchFormGroup = FormGroup<{
  fromDate: FormControl<string | null>;
  toDate: FormControl<string | null>;
}>;

@Injectable()
export class DashboardFormService {
  private readonly fb = inject(FormBuilder);

  form: DashboardCustomSearchFormGroup = this.fb.group({
    fromDate: this.fb.control<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
    toDate: this.fb.control<string | null>(null, { nonNullable: true, validators: [Validators.required] }),
  });
}
