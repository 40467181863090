import { Component, inject, OnInit } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { NgIf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { WindowService } from '@app/resources/services/window.service';
import { EnrollmentOfferInstantFormGroup } from '@app/enroll/services';
import { PercentageControlComponent, PriceControlComponent } from '@ep/shared';
import {
  ENROLLMENT_INSTANT_OFFER_CALCULATED_MAX_REWARD_HELP,
  ENROLLMENT_INSTANT_OFFER_CALCULATED_PERCENTAGE_HELP,
} from '@app/enroll/utils/constants';

type InstantOfferControlHelpType = 'percentage' | 'max-reward';

@Component({
  selector: 'app-enrollment-offer-instant-form',
  templateUrl: './enrollment-offer-instant-form.component.html',
  styleUrls: ['./enrollment-offer-instant-form.component.scss'],
  imports: [
    DialogModule,
    NgIf,
    PaginatorModule,
    PercentageControlComponent,
    ReactiveFormsModule,
    PercentageControlComponent,
    PriceControlComponent,
  ],
  standalone: true,
})
export class EnrollmentOfferInstantFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  private readonly windowService = inject(WindowService);

  protected instantOfferForm: EnrollmentOfferInstantFormGroup | null = null;
  protected isDialogVisible = false;

  protected percentageHelpText = ENROLLMENT_INSTANT_OFFER_CALCULATED_PERCENTAGE_HELP;
  protected maxRewardHelpText = ENROLLMENT_INSTANT_OFFER_CALCULATED_MAX_REWARD_HELP;
  private helpIndex: InstantOfferControlHelpType = 'percentage';
  private helpDialog: { [index: string]: string } = {
    percentage: this.percentageHelpText,
    ['max-reward']: this.maxRewardHelpText,
  };

  ngOnInit() {
    this.instantOfferForm = this.controlContainer.control as EnrollmentOfferInstantFormGroup;
  }

  get isXSmallScreen() {
    return this.windowService.isXSmallScreen;
  }

  protected showHelp(controlHelp: InstantOfferControlHelpType) {
    this.helpIndex = controlHelp;
    this.isDialogVisible = true;
  }

  protected getHelpDialog() {
    return this.helpDialog[this.helpIndex];
  }
}
