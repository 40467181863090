<div style="display: flex; width: 100%">
  <span style="width: 100%">
    <label class="offer-reminder-label"> Text Reminder </label>
    <p-dropdown
      [ngClass]="{ 'ng-pending': !isFocusedOut }"
      [formControl]="reminder"
      [options]="reminders"
      (focusout)="onFocusOut()"
      (input)="onFocusIn()"
      class="offer-reminder w-full p-inputtext p-component full-height-width"
      optionLabel="value"
      placeholder="Select a reminder"
      data-cy="offer-reminder-control"
    ></p-dropdown>
  </span>
</div>
