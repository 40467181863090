<div class="generic-failure-wrapper">
  <div class="circle-loader failed">
    <svg
      class="crossmark animateElement"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      data-cy="generic-failure"
    >
      <path class="cross__path cross__path--right animateElement" fill="none" d="M16,16 l20,20" />
      <path class="cross__path cross__path--left animateElement" fill="none" d="M16,36 l20,-20" />
    </svg>
  </div>
  <div class="contact-customer-support-message">
    <h3>Contact Customer Support</h3>
    <p style="font-size: 18px; font-weight: 500">
      Something went wrong. Contact customer support for more help: (866) 942-5500
    </p>
  </div>
</div>
