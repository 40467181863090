<div *ngIf="bonusOfferForm">
  <form [formGroup]="bonusOfferForm" class="offer-bonus-form" data-cy="offer-bonus-form">
    <div class="offer-bonus-form-row">
      <ep-percentage-control
        formControlName="RewardPercentage"
        label="Bonus Reward"
        data-cy="offer-bonus-reward-percentage"
        class="offer-bonus-control-width"
      ></ep-percentage-control>
      <p *ngIf="!isXSmallScreen; else infoPercentageReward" class="offer-bonus-control-info-width">
        <i>{{ percentageHelpText }}</i>
      </p>
      <ng-template #infoPercentageReward>
        <i
          class="pi pi-info-circle help-icon"
          (click)="showHelp('percentage')"
          data-cy="offer-bonus-reward-percentage-help"
        ></i>
      </ng-template>
    </div>
    <div class="offer-bonus-form-row">
      <ep-price-control
        formControlName="MaxRewardAmount"
        label="Max Reward Amount"
        data-cy="offer-bonus-max-reward-amount"
        class="offer-bonus-control-width"
      ></ep-price-control>
      <p *ngIf="!isXSmallScreen; else infoMaxRewardAmount" class="offer-bonus-control-info-width">
        <i>{{ maxRewardHelpText }}</i>
      </p>
      <ng-template #infoMaxRewardAmount>
        <i
          class="pi pi-info-circle help-icon"
          (click)="showHelp('max-reward')"
          data-cy="offer-bonus-max-reward-help"
        ></i>
      </ng-template>
    </div>
  </form>
  <p-dialog
    [(visible)]="isDialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ 'max-width': '400px' }"
    data-cy="offer-bonus-form-dialog"
  >
    <p class="m-0 offer-bonus-form-dialog-content" data-cy="offer-bonus-help-dialog-content">
      <i>{{ getHelpDialog() }}</i>
    </p>
  </p-dialog>
</div>
