import { Component, OnInit } from '@angular/core';
import { ChooseEnrollmentOfferFormComponent, ChooseLoyaltyProgramFormComponent } from '@app/enroll/forms';

@Component({
  selector: 'app-merchant-choose-offer-step',
  templateUrl: './merchant-choose-offer-step.component.html',
  styleUrls: ['./merchant-choose-offer-step.component.scss'],
  imports: [ChooseLoyaltyProgramFormComponent, ChooseEnrollmentOfferFormComponent],
  standalone: true,
})
export class MerchantChooseOfferStepComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
