import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoyaltyProgramState } from '@app/resources/ngrx/states';
import { selectLoyaltyProgramEntities } from '@app/resources/ngrx/adapters';
import { isNotNullOrUndefined } from '@ep/shared';

export const selectLoyaltyProgramState = createFeatureSelector<LoyaltyProgramState>('loyaltyProgram');
export const getLoyaltyProgramEntities = createSelector(selectLoyaltyProgramState, selectLoyaltyProgramEntities);
export const selectCurrentLoyaltyProgram = createSelector(getLoyaltyProgramEntities, (loyaltyProgramEntities) => {
  const entries = Object.entries(loyaltyProgramEntities).filter(isNotNullOrUndefined);
  if (entries.length === 0) return null;
  return entries[0][1]; // We should only have one loyalty program
});
