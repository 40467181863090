import { createActionGroup, props } from '@ngrx/store';
import { NavigationExtras, Params } from '@angular/router';

enum RouterActionTypes {
  NAVIGATE_BY_URL = 'Navigate By Url',
  NAVIGATE_BY_URL_WITH_LOCATION = 'Navigate By Url With Location',
  NAVIGATE_WITH_QUERY_PARAMS = 'Navigate With Query Params',
}

export const RouterActions = createActionGroup({
  source: 'Router',
  events: {
    [RouterActionTypes.NAVIGATE_BY_URL]: props<{ payload: { path: string; extras?: NavigationExtras } }>(),
    [RouterActionTypes.NAVIGATE_BY_URL_WITH_LOCATION]: props<{
      payload: { path: string; extras?: NavigationExtras };
    }>(),
    [RouterActionTypes.NAVIGATE_WITH_QUERY_PARAMS]: props<{ payload: { route?: string; queryParams?: Params } }>(),
  },
});
