<div *ngIf="offerDetailsForm" [formGroup]="offerDetailsForm">
  <div class="form-segment-header">
    <h5>Offer Details</h5>
    <p class="segment-subheader">Specify the constraints of your offer</p>
  </div>
  <div style="margin-top: 10px">
    <ep-simple-text-control
      formControlName="Name"
      label="Offer Name"
      data-cy="offer-form-name-control"
    ></ep-simple-text-control>
  </div>
  <div style="margin-top: 10px">
    <ep-simple-text-control
      formControlName="Location"
      label="Location"
      data-cy="offer-form-location-control"
    ></ep-simple-text-control>
  </div>
  <div style="margin-top: 25px">
    <ep-percentage-control
      formControlName="DiscountPercentage"
      label="Discount Percentage"
      data-cy="offer-form-discount-control"
    ></ep-percentage-control>
    <p
      *ngIf="
        (offerDetailsSubHeader$ | async) !== null &&
        offerDetailsForm.controls.DiscountPercentage.dirty &&
        !offerDetailsForm.controls.DiscountPercentage.errors
      "
      style="font-size: 12px !important; position: absolute"
    >
      <i> {{ offerDetailsSubHeader$ | async }} </i>
    </p>
  </div>
</div>
