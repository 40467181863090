import { LoginTypes } from '../enums/login-types.enum';

export interface ConfirmChangePassword {
  $id: string;
  employeeId?: string;
  loginType: LoginTypes;
  newPasswordA: string;
  newPasswordB: string;
  representativeId: number;
  passwordResetId: string;
}
