<div *ngIf="contactForm" class="enrollment-contact-form-wrapper" data-cy="enrollment-account-contact-form">
  <h5>Contact Information</h5>
  <form [formGroup]="contactForm" id="enrollment-contact-form">
    <ep-simple-text-control
      class="width-48"
      formControlName="FirstName"
      label="First Name"
      data-cy="account-contact-first-name"
    ></ep-simple-text-control>
    <ep-simple-text-control
      class="width-48"
      formControlName="LastName"
      label="Last Name"
      data-cy="account-contact-last-name"
    ></ep-simple-text-control>
    <app-enrollment-email-control
      class="width-48"
      formControlName="Email"
      data-cy="account-contact-email"
    ></app-enrollment-email-control>
    <ep-international-phone-control
      class="width-48"
      formControlName="Phone"
      data-cy="account-contact-phone"
    ></ep-international-phone-control>
    <app-enrollment-terminal-control
      class="width-48"
      formControlName="TerminalLogin"
      data-cy="account-contact-terminal"
    ></app-enrollment-terminal-control>
  </form>
</div>
