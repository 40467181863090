<div class="date-time-control-wrapper">
  <div class="calendar-date">
    <span>
      <label htmlFor="date-control" class="date-label"> {{ label }} </label>
      <p-calendar
        inputId="date-control"
        [formControl]="date"
        [showIcon]="true"
        [minDate]="useMinDate ? minDate || defaultMinDate : defaultMinDate"
        [maxDate]="maxDate || defaultMaxDate"
        [required]="true"
        [readonlyInput]="readonly"
        [iconDisplay]="'input'"
        placeholder="Select Date"
        data-cy="custom-date-control"
      >
        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
          <p-button class="calendar-clock-icon-button" [disabled]="date.disabled" (onClick)="clickCallBack($event)">
            <fa-icon
              [icon]="icons['faCalendarDays']"
              class="calendar-clock-icon pointer-events-none"
              size="xl"
              [inverse]="true"
              (click)="clickCallBack($event)"
            >
            </fa-icon>
          </p-button>
        </ng-template>
      </p-calendar>
    </span>
    <p *ngIf="date.dirty && date.errors" class="date-control-error" data-cy="custom-offer-date-control-error">
      <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> {{ displayDateErrorMessage() }}
    </p>
  </div>
  <div class="calendar-time">
    <span>
      <label htmlFor="time-control" class="time-label"> Time </label>
      <p-calendar
        inputId="time-control"
        [formControl]="time"
        [showTime]="true"
        [timeOnly]="true"
        hourFormat="12"
        [showIcon]="true"
        [required]="true"
        [readonlyInput]="readonly"
        [iconDisplay]="'input'"
        placeholder="Select Time"
        data-cy="custom-time-control"
      >
        <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
          <p-button class="calendar-clock-icon-button" [disabled]="time.disabled" (onClick)="clickCallBack($event)">
            <fa-icon
              [icon]="icons['faClock']"
              class="calendar-clock-icon pointer-events-none"
              size="xl"
              [inverse]="true"
              (click)="clickCallBack($event)"
            >
            </fa-icon>
          </p-button>
        </ng-template>
      </p-calendar>
    </span>
    <p *ngIf="time.dirty && time.errors" class="date-control-error" data-cy="custom-offer-time-control-error">
      <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> {{ displayTimeErrorMessage() }}
    </p>
  </div>
</div>
