import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OfferTextReminderEnum, OfferTextReminderMap } from '@shared/enums';
import { OfferValidator } from '@app/areas/offers/validators';
import { initializeReminder, OfferReminder } from '@app/areas/offers/utils';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { Offer } from '@app/resources/services';

export type OfferDateControl = FormControl<OfferDate>;

export enum WhenOfferDateEnum {
  NOW,
  CUSTOM,
}
export interface OfferDate {
  when: WhenOfferDateEnum;
  date?: Date | null;
}

type OfferDetailsFormControls = {
  Name: FormControl<string | null>;
  Location: FormControl<string | null>;
  DiscountPercentage: FormControl<number | null>;
};
type OfferDurationFormControls = {
  StartDate: OfferDateControl;
  EndDate: OfferDateControl;
};
type OfferScheduledSMSFormControls = {
  Reminder: FormControl<OfferReminder>;
  SMSDate: OfferDateControl;
  SMSBody: FormControl<string | null>;
};

export type OfferDetailsFormGroup = FormGroup<OfferDetailsFormControls>;
export type OfferDurationFormGroup = FormGroup<OfferDurationFormControls>;
export type OfferScheduledSmsFormGroup = FormGroup<OfferScheduledSMSFormControls>;

export type OfferFormGroup = FormGroup<{
  Details: OfferDetailsFormGroup;
  Duration: OfferDurationFormGroup;
  ScheduledSMS: OfferScheduledSmsFormGroup;
}>;

export interface OfferDuration {
  startDate?: Date | string;
  endDate?: Date | string;
}

interface SetControlValueOptions {
  onlySelf?: boolean | undefined;
  emitEvent?: boolean | undefined;
  emitModelToViewChange?: boolean | undefined;
  emitViewToModelChange?: boolean | undefined;
}

@Injectable()
export class OfferFormService {
  private readonly fb = inject(FormBuilder);
  private readonly offerValidator = new OfferValidator(inject(TimezoneService));

  offerForm: OfferFormGroup = this.fb.group({
    Details: this.fb.group<OfferDetailsFormControls>({
      Name: this.fb.control<string | null>(null, {
        validators: [Validators.required],
      }),
      Location: this.fb.control<string | null>(null, {
        validators: [Validators.required],
      }),
      DiscountPercentage: this.fb.control<number | null>(null, {
        validators: [Validators.required, Validators.pattern('[0-9]{1,3}'), Validators.min(0), Validators.max(100)],
      }),
    }),
    Duration: this.fb.group<OfferDurationFormControls>({
      StartDate: this.fb.control<OfferDate>(
        {
          when: WhenOfferDateEnum.CUSTOM,
          date: null,
        } as OfferDate,
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      EndDate: this.fb.control<OfferDate>(
        {
          value: {
            when: WhenOfferDateEnum.CUSTOM,
            date: null,
          },
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
    }),
    ScheduledSMS: this.fb.group<OfferScheduledSMSFormControls>({
      Reminder: this.fb.control<OfferReminder>(
        {
          key: `${OfferTextReminderEnum.ONE_HOUR_BEFORE}`,
          value: OfferTextReminderMap[OfferTextReminderEnum.ONE_HOUR_BEFORE],
        },
        { nonNullable: true, validators: [Validators.required] }
      ),
      SMSDate: this.fb.control<OfferDate>(
        {
          value: {
            when: WhenOfferDateEnum.CUSTOM,
            date: null,
          },
          disabled: true,
        },
        {
          nonNullable: true,
          validators: [Validators.required],
        }
      ),
      SMSBody: this.fb.control<string | null>(
        { value: null, disabled: true },
        { validators: [Validators.required, Validators.pattern('.{1,160}')] }
      ),
    }),
  });

  initializeForm(location: string, offer?: Partial<Offer>) {
    this.resetForm();
    this.offerForm.controls.Duration.controls.EndDate.disable();
    this.offerForm.controls.ScheduledSMS.controls.SMSDate.disable();

    if (offer) {
      this.setOfferDetails(
        { name: offer.namePrivate, location: location, discount: offer.receiveAmount },
        { emitEvent: false }
      );
      this.setOfferDuration(
        {
          startDateTime: {
            when: WhenOfferDateEnum.CUSTOM,
            date: offer.startDateTime ? new Date(offer.startDateTime) : null,
          },
          endDateTime: {
            when: WhenOfferDateEnum.CUSTOM,
            date: offer.endDateTime ? new Date(offer.endDateTime) : null,
          },
        },
        { emitEvent: false }
      );
      this.setOfferScheduledSMS(
        {
          reminder:
            offer.startDateTime && offer.sendTime
              ? initializeReminder({
                  startDateTime: offer.startDateTime,
                  sendTime: offer.sendTime,
                })
              : null,
          smsDate: {
            when: WhenOfferDateEnum.CUSTOM,
            date: offer.sendTime ? new Date(offer.sendTime) : null,
          },
          smsBody: JSON.parse(offer.display ?? `{"en-US": ""}`)['en-US'],
        },
        { emitEvent: false }
      );
    } else this.setOfferDetails({ location: location }, { emitEvent: false });
    this.offerForm.markAsPristine();
    this.offerForm.markAsUntouched();
    this.setAsyncValidators();
  }

  public setAsyncValidators() {
    this.clearAsyncValidators();
    this.offerForm.controls.Duration.controls.StartDate.setAsyncValidators([
      this.offerValidator.offerDurationStartDateValidatorAsync(),
    ]);

    this.offerForm.controls.Duration.controls.EndDate.setAsyncValidators([
      this.offerValidator.offerDurationEndDateValidatorAsync(this.offerForm.controls.Duration.controls.StartDate),
    ]);

    this.offerForm.controls.ScheduledSMS.controls.SMSDate.setAsyncValidators([
      this.offerValidator.offerSMSDateValidatorAsync(this.offerForm.controls.Duration.controls.StartDate),
    ]);

    this.offerForm.controls.ScheduledSMS.controls.Reminder.setAsyncValidators([
      this.offerValidator.offerSMSReminderValidatorAsync(this.offerForm.controls.Duration.controls.StartDate),
    ]);
  }

  private clearAsyncValidators() {
    for (const field in this.offerForm.controls) {
      const control = this.offerForm.get(field);
      if (control) {
        for (const subField in (control as FormGroup).controls) {
          if (subField === 'Name') continue;
          const subControl = (control as FormGroup).get(subField);
          subControl?.clearAsyncValidators();
        }
      }
    }
  }

  disableForm(disable: boolean) {
    if (disable) this.offerForm.disable();
    else this.offerForm.enable();
  }

  setOfferDetails(data: { name?: string; location: string; discount?: number }, options?: SetControlValueOptions) {
    if (data.name) this.getOfferDetails(this.offerForm).controls.Name.setValue(data.name, options);
    if (data.location) this.getOfferDetails(this.offerForm).controls.Location.setValue(data.location, options);
    if (data.discount)
      this.getOfferDetails(this.offerForm).controls.DiscountPercentage.setValue(data.discount, options);
  }

  setOfferDuration(data: { startDateTime?: OfferDate; endDateTime?: OfferDate }, options?: SetControlValueOptions) {
    if (data.startDateTime)
      this.getOfferDuration(this.offerForm).controls.StartDate.setValue(data.startDateTime, options);
    if (data.endDateTime) this.getOfferDuration(this.offerForm).controls.EndDate.setValue(data.endDateTime, options);
  }

  setOfferScheduledSMS(
    data: {
      reminder?: OfferReminder | null;
      smsDate?: OfferDate;
      smsBody?: string | null;
      saveAsTemplate?: boolean | null;
    },
    options?: SetControlValueOptions
  ) {
    if (data.reminder) this.getOfferScheduledSMS(this.offerForm).controls.Reminder.setValue(data.reminder, options);
    if (data.smsDate) {
      this.getOfferScheduledSMS(this.offerForm).controls.SMSDate.setValue(data.smsDate, options);
    }
    if (data.smsBody) this.getOfferScheduledSMS(this.offerForm).controls.SMSBody.setValue(data.smsBody, options);
  }

  getOfferForm() {
    return this.offerForm;
  }

  getOfferDetails(offerForm: OfferFormGroup) {
    return offerForm.controls.Details;
  }

  getOfferDuration(offerForm: OfferFormGroup) {
    return offerForm.controls.Duration;
  }

  getOfferScheduledSMS(offerForm: OfferFormGroup) {
    return offerForm.controls.ScheduledSMS;
  }

  resetForm() {
    this.offerForm.reset({}, { emitEvent: false });
  }
}
