import { Component, inject, Input, OnInit } from '@angular/core';
import { ControlContainer, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { OfferScheduledSmsFormGroup } from '@app/areas/offers/services/offer.form.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OfferDateTimeControlComponent, OfferSmsReminderControlComponent } from '@app/areas/offers/controls';
import { OfferTextReminderMap } from '@shared/enums';
import { Observable } from 'rxjs';
import moment from 'moment/moment';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-offer-scheduled-sms-form',
  templateUrl: './offer-scheduled-sms-form.component.html',
  styleUrls: ['./offer-scheduled-sms-form.component.scss'],
  imports: [
    AsyncPipe,
    DropdownModule,
    InputTextareaModule,
    NgIf,
    OfferDateTimeControlComponent,
    ReactiveFormsModule,
    OfferSmsReminderControlComponent,
    FaIconComponent,
  ],
  standalone: true,
})
export class OfferScheduledSmsFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  private readonly timezoneService = inject(TimezoneService);
  protected scheduledSmsForm: OfferScheduledSmsFormGroup | null = null;
  protected icons = FONT_AWESOME_ICONS;

  @Input() minDate: Date = moment()
    .add(this.timezoneService.getLocationOffset() - this.timezoneService.getClientOffset(), 'hours')
    .toDate();
  @Input() maxDate$: Observable<Date> | null = null;
  protected reminders = Object.entries(OfferTextReminderMap).map((reminder) => {
    return {
      key: reminder[0],
      value: reminder[1],
    };
  });

  ngOnInit() {
    this.scheduledSmsForm = this.controlContainer.control as OfferScheduledSmsFormGroup;
  }

  protected getScheduledSMSErrorMessage(errors: ValidationErrors | null) {
    if (!errors || errors['required']) return;

    return errors['invalidTime']
      ? '(Please choose a time between 8 am - 9 pm)'
      : 'SMS date must be between now and the offer start date';
  }
}
