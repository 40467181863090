import { EmailService } from '@app/resources/services/email.service';
import { inject } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, Observable, of, startWith, take, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class EnrollmentCustomValidators {
  private readonly emailService = inject(EmailService);

  public checkIfEmailIsUniqueValidatorAsync(
    emailValue: string | null
  ): (x: any) => Observable<ValidationErrors | null> {
    return (emailControl: AbstractControl<any, any>): Observable<ValidationErrors | null> => {
      const emailCheck = emailControl.valueChanges.pipe(
        startWith(emailControl.value),
        debounceTime(100),
        distinctUntilChanged(),
        switchMap((email) => {
          if (!email || email === '') return of(null);
          return this.emailService.findUnique(encodeURIComponent(email)).pipe(
            catchError(() => of(null)),
            tap((doesEmailExist) => {
              emailControl.setErrors(doesEmailExist ? { isNotUnique: true } : null);
              if (doesEmailExist) emailValue = null;
            }),
            map((doesEmailExist) => {
              return doesEmailExist ? { isNotUnique: true } : null;
            })
          );
        }),
        catchError(() => of(null)),
        take(1)
      );

      if (!emailControl.valueChanges) return of(null);

      return emailCheck.pipe(take(1));
    };
  }
}
