<div
  *ngIf="reviewQuestionsForm"
  class="enrollment-review-questions-form-array-wrapper"
  data-cy="enrollment-review-questions-form-array"
>
  <h3>How Review Questions Work</h3>
  <p *ngIf="!isXSmallScreen; else reviewQuestionHelp">
    When a transaction has been completed, the consumer will be presented with a thumbs up/thumbs down question that can
    be answered then on the terminal. These questions are optional and not required for enrollment.
  </p>
  <ng-template #reviewQuestionHelp>
    <i class="pi pi-info-circle help-icon" (click)="showHelp()" data-cy="review-question-help"></i>
  </ng-template>
  <form [formGroup]="reviewQuestionsForm" id="enrollment-review-question-form">
    <p-panel header="Questions To Ask">
      <div *ngIf="questions.length > 0" formArrayName="Questions">
        <div *ngFor="let questionControl of questions.controls; let i = index" class="review-question-control">
          <ep-simple-text-control
            style="width: 95%"
            [formControl]="questionControl"
            [attr.data-cy]="'review-question-control-' + i"
          ></ep-simple-text-control>
          <p-button
            (onClick)="removeQuestion(i)"
            [style]="{ height: '100%' }"
            size="large"
            class="enrollment-remove-review-question-button"
            icon="pi pi-trash"
            [attr.data-cy]="'enrollment-remove-review-question-button-' + i"
          ></p-button>
        </div>
      </div>
      <div id="review-question-actions" *ngIf="questions.length === 0">
        <p-button
          (onClick)="addQuestion()"
          [style]="{ height: '100%' }"
          size="large"
          id="enrollment-add-review-question-button"
          data-cy="enrollment-add-review-question-button"
        >
          Add Question
        </p-button>
      </div>
    </p-panel>
  </form>
  <p-dialog
    [(visible)]="isDialogVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ 'max-width': '400px' }"
    data-cy="review-question-form-dialog"
  >
    <p class="m-0 review-question-form-dialog-content" data-cy="review-question-help-dialog-content">
      When a transaction has been completed, the consumer will be presented with a thumbs up/thumbs down question that
      can be answered then on the terminal or later when they view their e-receipt. These questions are optional and not
      required for enrollment.
    </p>
  </p-dialog>
</div>
