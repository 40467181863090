<div class="offer-card-wrapper">
  <form *ngIf="offerForm" [formGroup]="offerForm" class="offer-form">
    <app-offer-details-form formGroupName="Details"></app-offer-details-form>
    <app-offer-duration-form
      formGroupName="Duration"
      [minDate]="minDate"
      [minEndDate$]="minEndDate$"
    ></app-offer-duration-form>
    <app-offer-scheduled-sms-form
      formGroupName="ScheduledSMS"
      [minDate]="minDate"
      [maxDate$]="maxDate$"
    ></app-offer-scheduled-sms-form>
    <app-offer-sms-body-form formGroupName="ScheduledSMS"></app-offer-sms-body-form>
  </form>
</div>
