import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { AsyncPipe, NgIf } from '@angular/common';
import { Subscription, tap } from 'rxjs';
import { CalendarModule } from 'primeng/calendar';
import moment from 'moment';
import { TimezoneService } from '@app/resources/services/timezone.service';
import { OfferDate, WhenOfferDateEnum } from '@app/areas/offers/services/offer.form.service';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaDuotoneIconComponent, FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-offer-date-time-control',
  templateUrl: './offer-date-time-control.component.html',
  styleUrls: ['./offer-date-time-control.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    InputNumberModule,
    AsyncPipe,
    NgIf,
    CalendarModule,
    FaIconComponent,
    FaDuotoneIconComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OfferDateTimeControlComponent,
      multi: true,
    },
  ],
})
export class OfferDateTimeControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly timezoneService = inject(TimezoneService);

  @Input() label: string = 'Date';
  @Input() useMinDate: boolean = false;
  @Input() minDate: Date | null = this.timezoneService
    .moment()
    .add(this.timezoneService.getLocationClientOffset())
    .toDate();
  @Input() maxDate: Date | null = null;
  @Input() shouldResetStartDay = false;
  @Input() shouldResetEndDay = false;
  @Input() shouldResetNow = false;
  @Input() readonly: boolean = false;
  protected date = new FormControl<Date | null>(null, { validators: [Validators.required] });
  protected time = new FormControl<Date | null>({ value: null, disabled: true }, { validators: [Validators.required] });

  protected defaultMinDate = this.timezoneService.moment().add(this.timezoneService.getLocationClientOffset()).toDate();
  protected defaultMaxDate = this.timezoneService.moment().add(1, 'years').toDate();
  private isNow = false;
  protected icons = FONT_AWESOME_ICONS;

  private subs = new Subscription();
  onChange: any = (value: Date) => {};
  onTouch: any = () => {};
  value: OfferDate = {
    when: WhenOfferDateEnum.CUSTOM,
    date: null,
  };

  ngOnInit(): void {
    this.subs.add(
      this.date.valueChanges
        .pipe(
          tap((value) => {
            if (value) {
              if (this.date.enabled) {
                this.time.enable({ emitEvent: false });
              } else if (this.date.disabled) {
                this.time.disable({ emitEvent: false });
              }

              const date = this.timezoneService.moment(value).second(0).millisecond(0);
              const now = this.timezoneService
                .moment()
                .add(this.timezoneService.getLocationClientOffset(), 'hours')
                .second(0)
                .millisecond(0);

              const newValue = this.resetDate({
                date: date,
                now: now,
                time: this.time.value,
                shouldResetStartDay: this.shouldResetStartDay,
                shouldResetEndDay: this.shouldResetEndDay,
                shouldResetNow: this.shouldResetNow,
              });
              this.setValue(this.isNow ? WhenOfferDateEnum.NOW : WhenOfferDateEnum.CUSTOM, newValue);
              this.time.setValue(this.value.date ?? null, { emitEvent: false });
            } else {
              this.setValue(WhenOfferDateEnum.CUSTOM);
            }
            this.onChange(this.value);
            this.onTouch();
          })
        )
        .subscribe()
    );

    this.subs.add(
      this.time.valueChanges
        .pipe(
          tap((value) => {
            if (value) {
              const currentDate = this.date.value
                ? this.timezoneService.moment(this.date.value)
                : this.value.date
                ? this.timezoneService.moment(this.value.date)
                : this.timezoneService.moment(value);

              const now = this.timezoneService
                .moment()
                .add(this.timezoneService.getLocationClientOffset(), 'hours')
                .second(0)
                .millisecond(0);
              const newValue = this.resetDate({
                date: currentDate,
                now: now,
                time: value,
              });

              this.setValue(this.isNow ? WhenOfferDateEnum.NOW : WhenOfferDateEnum.CUSTOM, newValue);
              this.date.setValue(this.value.date ?? null, { emitEvent: false });
            } else {
              this.setValue(WhenOfferDateEnum.CUSTOM);
            }
            this.onChange(this.value);
            this.onTouch();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.date.disable({ emitEvent: false });
      this.time.disable({ emitEvent: false });
    } else {
      this.date.enable({ emitEvent: false });
      this.time.enable({ emitEvent: false });
    }
  }

  setValue(when: WhenOfferDateEnum, value?: Date | null) {
    this.value = {
      when: when,
      date: value ?? null,
    };
  }

  writeValue(value: OfferDate): void {
    if (!!value.date) {
      this.setValue(WhenOfferDateEnum.CUSTOM, value.date);
      this.date.setValue(value.date, { emitEvent: false });
      this.time.setValue(value.date, { emitEvent: false });
      if (this.time.disabled && this.date.enabled) this.time.enable({ emitEvent: false });
    } else {
      this.setValue(WhenOfferDateEnum.CUSTOM);
      this.date.setValue(null, { emitEvent: false });
      this.time.setValue(null, { emitEvent: false });
    }
  }
  displayDateErrorMessage() {
    return 'Must enter a date';
  }

  displayTimeErrorMessage() {
    return 'Must provide a time';
  }

  private resetDate(data: {
    now: moment.Moment;
    date: moment.Moment;
    time?: Date | string | null;
    shouldResetStartDay?: boolean;
    shouldResetEndDay?: boolean;
    shouldResetNow?: boolean;
  }) {
    let value = data.date.toDate();
    this.isNow = false;
    const [dateMonth, dateDay] = this.getMonthAndDayFromDate(value);
    const [nowMonth, nowDay] = this.getMonthAndDayFromDate(data.now.toDate());
    if (data.shouldResetStartDay && (dateMonth !== nowMonth || (dateMonth === nowMonth && dateDay !== nowDay))) {
      value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    } else if (data.shouldResetEndDay) {
      value.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    } else if (data.shouldResetNow) {
      this.isNow = true;
      const now = data.now.toDate();
      value.set({ hour: now.getHours(), minute: now.getMinutes(), second: 0, millisecond: 0 });
    } else if (data.time) {
      const time = new Date(data.time);
      value.set({ hour: time.getHours(), minute: time.getMinutes(), second: 0, millisecond: 0 });
    }

    return value;
  }

  private getMonthAndDayFromDate(date: Date) {
    return [date.getMonth(), date.getDate()];
  }
}
