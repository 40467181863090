import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CredentialsService } from '@shared/services/credentials.service';
import { CredentialsActions } from '@app/resources/ngrx/actions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class CredentialsEffects {
  private readonly action$ = inject(Actions);
  private readonly credentialsService = inject(CredentialsService);

  updateCredentials$ = createEffect(() =>
    this.action$.pipe(
      ofType(CredentialsActions.updateCredentials),
      tap(({ payload }) => {
        this.credentialsService.setCredentials(payload.credentials);
      }),
      map(({ payload }) => CredentialsActions.updateCredentialsSuccess({ payload }))
    )
  );

  clearCredentials$ = createEffect(() =>
    this.action$.pipe(
      ofType(CredentialsActions.clearCredentials),
      tap(() => {
        this.credentialsService.clearCredentials();
      }),
      map(() => CredentialsActions.clearCredentialsSuccess())
    )
  );
}
