<div>
  <span>
    <label class="enrollment-terminal-label"> Terminal Login ID </label>
    <input
      type="text"
      pattern="\d*"
      maxlength="6"
      (keydown)="keyPressOnlyNumbers($event)"
      [ngClass]="{ 'ng-pending': !isFocusedOut }"
      class="enrollment-terminal w-full p-inputtext p-component full-height-width"
      [formControl]="terminal"
      (focusout)="onFocusOut()"
      data-cy="enrollment-terminal-control"
    />
  </span>
  <p
    *ngIf="terminal.dirty && terminal.errors && isFocusedOut"
    class="enrollment-terminal-control-error"
    data-cy="enrollment-terminal-control-error"
  >
    <fa-icon [icon]="icons['faTriangleExclamation']" style="color: #ff0000"></fa-icon> Please enter a 6-digit terminal
    login ID
  </p>
</div>
