import { createReducer, on } from '@ngrx/store';
import { AuthenticationActions } from '@app/resources/ngrx/actions';
import { AuthenticationState } from '@app/resources/ngrx/states/authentication.state';

export const initialAuthenticationState: AuthenticationState = {
  loggedIn: false,
  showTermsAndConditions: false,
  error: null,
};

export const _authenticationReducer = createReducer(
  initialAuthenticationState,
  on(AuthenticationActions.authenticationLoginSuccess, (state) => ({
    loggedIn: true,
    showTermsAndConditions: state.showTermsAndConditions,
    error: null,
  })),
  on(AuthenticationActions.authenticationLoginWithTCSuccess, (state) => ({
    loggedIn: true,
    showTermsAndConditions: state.showTermsAndConditions,
    error: null,
  })),
  on(AuthenticationActions.authenticationLogoutSuccess, (state) => ({
    ...state,
    authentication: {
      loggedIn: false,
      showTermsAndConditions: false,
      error: null,
    },
  })),
  on(AuthenticationActions.authenticationShowTermsAndConditions, (state, { payload }) => ({
    loggedIn: false,
    showTermsAndConditions: payload.showTC,
    error: null,
  })),
  on(AuthenticationActions.authenticationLoginError, (state, { payload }) => ({
    loggedIn: false,
    showTermsAndConditions: false,
    error: `#${Math.random().toString(36).slice(2, 7)}: ${payload.error}`,
  }))
);
