export enum OfferStatusTypes {
  PendingApproval = 1,
  Declined = 2,
  Pending = 3,
  Active = 4,
  PrimaryEarn = 5,
  PrimaryEnrollment = 6,
  PrimarySpend = 7,
  Suspended = 8,
  Deleted = 9,
  PendingNoText = 10,
}
