import { ChangeDetectorRef, Component, inject, Type } from '@angular/core';
import { DashboardApiService } from '@app/areas/dashboard/services/dashboard.api.service';
import { TimezoneService } from '@app/resources/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  DashboardOverviewPanelComponent,
  OverviewPanelData,
} from '@app/areas/dashboard/components/dashboard-overview-panel/dashboard-overview-panel.component';
import { filter } from 'rxjs/operators';
import { ColorsEnum } from '@shared/enums/colors.enum';
import { AsyncPipe, NgIf } from '@angular/common';
import { ViewCardConfig } from '@app/areas/dashboard/components/view-card/view-card.component';
import { isNotNullOrUndefined, LoadingSpinnerDirective } from '@ep/shared';
import { DashboardChartJSData } from '@app/areas/dashboard/components/dashboard-graph/dashboard-graph.component';
import { DashboardLoyaltyProgramPanelComponent } from '@app/areas/dashboard/components/dashboard-loyalty-program-panel/dashboard-loyalty-program-panel.component';
import { DashboardCustomSearchComponent } from '@app/areas/dashboard/components/dashboard-custom-search/dashboard-custom-search.component';
import {
  getDailyLoyaltyOverviewData,
  getDailySaleOverviewData,
  getDashboardData,
  getLoyaltyProgramData,
  getPrefilledChartJSData,
} from './utils';

export interface DashboardOverviewData {
  TotalSpend: number;
  AverageSpend: number;
  NonMemberSpend: number;
  AverageNonMemberSpend: number;
  MemberSpend: number;
  AverageMemberSpend: number;
  RewardsTotalSpend: number;
  RewardsTotalOrderSpend: number;
  MemberChecks: number;
  EnrollmentCount: number;
  EnrollmentConversion: number;
  EnrollmentTotalSpend: number;
  EnrollmentAverageSpend: number;
  NewEnrollmentTotalSpend: number;
  NewEnrollmentAverageTotalSpend: number;
  EarnedRewardsTotal: number;
  TotalNonMemberOrderCount: number;
  TotalMemberOrderCount: number;
  TotalEnrollmentOrderCount: number;
  TotalOrderCount: number;
  TotalQuestionAnswerCount: number;
  TotalQuestionAnswerScore: number;
  TotalQuestionAnswerAverage: number;
}

export const AbstractDashboardDatePanelViewComponentImports: (any[] | Type<any>)[] = [
  DashboardOverviewPanelComponent,
  LoadingSpinnerDirective,
  DashboardLoyaltyProgramPanelComponent,
  AsyncPipe,
  NgIf,
  DashboardCustomSearchComponent,
];

/**
 * @class AbstractDashboardDatePanelViewComponent
 *
 * This abstract class represents a base component for dashboard date panel views.
 *
 * @template T - The type of the overview panel data.
 */
@Component({ template: '' })
export abstract class AbstractDashboardDatePanelViewComponent {
  readonly dashboardApiService = inject(DashboardApiService);
  readonly timezoneService = inject(TimezoneService);
  readonly cdr = inject(ChangeDetectorRef);

  private _showChartData = false;
  private _showCustomSearch = false;

  private readonly _loyaltyProgramDataViewCardConfig$: BehaviorSubject<ViewCardConfig[]> = new BehaviorSubject<
    ViewCardConfig[]
  >(getLoyaltyProgramData());
  private readonly _dailySalesOverview$: BehaviorSubject<OverviewPanelData[]> = new BehaviorSubject<
    OverviewPanelData[]
  >(getDailySaleOverviewData());
  private readonly _dailyLoyaltyOverview$: BehaviorSubject<OverviewPanelData[]> = new BehaviorSubject<
    OverviewPanelData[]
  >(getDailyLoyaltyOverviewData());
  private readonly _dashboardGraphData$: BehaviorSubject<DashboardChartJSData> =
    new BehaviorSubject<DashboardChartJSData>({
      labels: [],
      datasets: [
        getPrefilledChartJSData('Total Sales', ColorsEnum.LIGHT_BLUE),
        getPrefilledChartJSData('Member Sales', ColorsEnum.MOSSY_GREEN),
        getPrefilledChartJSData('Non-Member Sales', ColorsEnum.BLUE_GREY),
      ],
    });

  protected loyaltyProgramDataViewCardConfig$ = this._loyaltyProgramDataViewCardConfig$
    .asObservable()
    .pipe(filter(isNotNullOrUndefined));
  protected dailySalesOverview$ = this._dailySalesOverview$.asObservable().pipe(filter(isNotNullOrUndefined));
  protected dailyLoyaltyOverview$ = this._dailyLoyaltyOverview$.asObservable().pipe(filter(isNotNullOrUndefined));
  protected dashboardGraphData$ = this._dashboardGraphData$.asObservable().pipe(filter(isNotNullOrUndefined));

  subs = new Subscription();

  set showChartData(value: boolean) {
    this._showChartData = value;
  }

  set showCustomSearch(value: boolean) {
    this._showCustomSearch = value;
  }

  get showChartData() {
    return this._showChartData;
  }

  get showCustomSearch() {
    return this._showCustomSearch;
  }

  /**
   * Updates the dashboard data with new data.
   *
   * @param data Optional parameter containing the new data to be updated. If not provided, the method will use the default data.
   */
  updateDashboardData(data?: any) {
    const { dashboardOverviewData, dashboardChartData } = getDashboardData(data);

    // Pass in new data to behavior subjects
    this._dailySalesOverview$.next(getDailySaleOverviewData(dashboardOverviewData));
    this._dailyLoyaltyOverview$.next(getDailyLoyaltyOverviewData(dashboardOverviewData));
    this._loyaltyProgramDataViewCardConfig$.next(getLoyaltyProgramData(dashboardOverviewData));
    this._dashboardGraphData$.next(dashboardChartData);
  }
}
