import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Employee } from '@app/resources/services';

enum EmployeeActionTypes {
  UPSERT_EMPLOYEE_SUCCESS = 'Upsert Employee Success',
  UPSERT_EMPLOYEE_ERROR = 'Upsert Employee Error',
  UPSERT_EMPLOYEES_SUCCESS = 'Upsert Employees Success',
  UPSERT_EMPLOYEES_ERROR = 'Upsert Employees Error',
  LOAD_EMPLOYEES = 'Load Employees',
  LOAD_EMPLOYEES_SUCCESS = 'Load Employees Success',
  LOAD_EMPLOYEES_ERROR = 'Load Employees Error',
  SELECT_EMPLOYEE = 'Employee By Id',
}

export const EmployeeActions = createActionGroup({
  source: 'Employee',
  events: {
    [EmployeeActionTypes.LOAD_EMPLOYEES]: emptyProps(),
    [EmployeeActionTypes.LOAD_EMPLOYEES_SUCCESS]: props<{
      payload: { employees: Employee[] };
    }>(),
    [EmployeeActionTypes.LOAD_EMPLOYEES_ERROR]: props<{ payload: { error: string } }>(),
    [EmployeeActionTypes.UPSERT_EMPLOYEE_SUCCESS]: props<{
      payload: { employee: Employee };
    }>(),
    [EmployeeActionTypes.UPSERT_EMPLOYEE_ERROR]: props<{ payload: { error: string } }>(),
    [EmployeeActionTypes.UPSERT_EMPLOYEES_SUCCESS]: props<{
      payload: { employees: Employee[] };
    }>(),
    [EmployeeActionTypes.UPSERT_EMPLOYEES_ERROR]: props<{ payload: { error: string } }>(),
    [EmployeeActionTypes.SELECT_EMPLOYEE]: props<{ payload: { employeeId: number } }>(),
  },
});
