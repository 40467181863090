import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { combineLatest, Subscription, tap } from 'rxjs';
import { EnrollmentCustomValidators } from '@app/enroll/validators/enrollment.validators';
import { filter } from 'rxjs/operators';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FONT_AWESOME_ICONS } from '@shared/icons';

@Component({
  selector: 'app-enrollment-email-control',
  templateUrl: './enrollment-email-control.component.html',
  styleUrls: ['./enrollment-email-control.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, InputTextModule, KeyFilterModule, AsyncPipe, NgClass, NgIf, FaIconComponent],
  providers: [
    EnrollmentCustomValidators,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EnrollmentEmailControlComponent,
      multi: true,
    },
  ],
})
export class EnrollmentEmailControlComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private readonly enrollmentValidators = new EnrollmentCustomValidators();
  onChange: any = (value: string) => {};
  onTouch: any = () => {};

  value: string | null = null;

  private subs = new Subscription();
  private isFocused = false;
  protected email = new FormControl<string | null>(null, {
    validators: [Validators.required, Validators.email],
    asyncValidators: [this.enrollmentValidators.checkIfEmailIsUniqueValidatorAsync(this.value)],
    updateOn: 'blur',
  });
  protected icons = FONT_AWESOME_ICONS;

  ngOnInit(): void {
    /**
     * Since we update the control value onBlur, the asyncValidator only runs once.
     * This means that if the validator says there is an error, the value of the custom
     * control will still update even if the value is "invalid". We need to track status
     * changes on the control, as well, to make sure that we only update our custom
     * control value when the value is present and VALID.
     */
    this.subs.add(
      combineLatest([this.email.valueChanges, this.email.statusChanges.pipe(filter((status) => status !== 'PENDING'))])
        .pipe(
          tap(([value, status]) => {
            if (!!value && status === 'VALID') this.value = value;
            else this.value = null;
            this.onChange(this.value);
            this.onTouch();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  protected onFocusIn() {
    if (!this.isFocused) {
      this.isFocused = true;
    }
  }

  protected onFocusOut() {
    this.isFocused = false;
  }

  protected get isFocusedOut() {
    return !this.isFocused;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.email.disable({ emitEvent: false }) : this.email.enable({ emitEvent: false });
  }

  setValue(value?: string | null) {
    this.value = value ?? null;
  }

  writeValue(value?: string): void {
    this.setValue(value ?? null);
    this.email.setValue(value ?? null);
    if (!!value) {
      this.email.markAsTouched();
      this.email.markAsDirty();
      this.email.updateValueAndValidity();
    }
  }

  displayEmailErrorMessage() {
    if (this.email.errors && (this.email.errors['email'] || this.email.errors['required'])) {
      return 'Must enter a valid email';
    } else if (this.email.errors && this.email.errors['isNotUnique']) {
      return 'Email is already in use';
    }
    return 'Invalid field';
  }

  doesEmailControlHaveErrors() {
    if (this.email.hasError('isNotUnique') && this.isFocusedOut) return true;
    return this.email.dirty && this.email.errors && this.isFocusedOut;
  }
}
