import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { TransactionsComponent } from './transactions.component';
import { AccessGuard } from '@app/@shared/guards/access.guard';
import { AccessType } from '@app/@shared/enums/access-types.enum';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/transactions',
      component: TransactionsComponent,
      canActivate: [AccessGuard],
      data: { title: 'Enroll And Pay', requiredAccessTypes: [AccessType.OrderAdmin] },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionsRoutingModule {}
