import { Component, EventEmitter, HostListener, inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IframeEventTypes } from '@app/@shared/enums/iframe-event-types.enum';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { IframeManagerService } from '@app/@shared/services/iframe-manager.service';
import { environment } from '@env/environment';
import { ConfigurationService } from '@ep/shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { AuthenticationActions } from '@app/resources/ngrx/actions';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() closeClicked = new EventEmitter<void>();

  private readonly store = inject(Store);

  // Icons
  faBars = faBars;

  items!: MenuItem[];
  applicationProfile!: ApplicationProfile;
  fullUserName!: string;
  isMobileScreen: boolean = false;

  constructor(
    private router: Router,
    private applicationProfileService: ApplicationProfileService,
    private iframeManagerService: IframeManagerService,
    private configurationService: ConfigurationService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobileScreen = window.innerWidth <= 550;
  }

  ngOnInit() {
    this.iframeManagerService
      .onMessageReceived()
      .pipe(
        untilDestroyed(this),
        filter(
          (event) =>
            event.origin ===
              (this.configurationService.merchantPortalUrlLegacy ?? environment.merchantPortalUrlLegacy) &&
            event.data === IframeEventTypes.LegacyAppTokenExpired
        )
      )
      .subscribe(() => {
        this.onLogoutClicked();
      });

    this.onResize({});

    this.items = [
      {
        label: 'Change Password',
        icon: 'pi pi-user-edit',
        command: () => {
          this.onChangePasswordClicked();
        },
      },
      {
        label: 'Log Out',
        icon: 'pi pi-sign-out',
        command: () => {
          this.onLogoutClicked();
        },
      },
    ];

    this.applicationProfileService.applicationProfile$
      .pipe(untilDestroyed(this))
      .subscribe((applicationProfile: ApplicationProfile) => {
        this.applicationProfile = applicationProfile;
        this.fullUserName = `${this.applicationProfile.Employee.NameFirst} ${this.applicationProfile.Employee.NameLast}`;
      });
  }

  onLogoutClicked(): void {
    this.store.dispatch(AuthenticationActions.authenticationLogout());
  }

  onChangePasswordClicked(): void {
    this.iframeManagerService.setShouldShowIframe(false);
    this.router.navigate([`${this.applicationProfile.Location.LocationId}/change-password`]);
  }

  onCloseClicked(event?: any): void {
    this.closeClicked.emit(event);
  }
}
