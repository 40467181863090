import { inject, Pipe, PipeTransform } from '@angular/core';
import { Offer } from '@app/resources/services';
import moment from 'moment/moment';
import { TimezoneService } from '@app/resources/services/timezone.service';
/*
 * Formats an offer date object to '(Day of WeeK) (Month) (Day), (Year) (Time)'
 * Takes an offer argument and the key of the datetime field
 *
 * Example:
 *   {{ offer | offerFormattedDate: 'startDateTime' }}
 *   formats to: Monday, December 4th, 2023 8:00 AM
 */
@Pipe({
  standalone: true,
  name: 'offerFormattedDate',
})
export class OfferFormattedDatePipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}
  transform(offer: Offer, dateField: keyof Offer): string {
    if (!!offer && !!offer[dateField] && typeof offer[dateField] === 'object') {
      switch (dateField) {
        case 'startDateTime':
        case 'endDateTime':
          return `${moment(offer[dateField]).format('dddd, MMMM DD, YYYY h:mm a')}`;
        case 'sendTime':
          return `${this.timezoneService
            .moment(offer[dateField])
            .subtract(this.timezoneService.getLocationClientOffset(offer[dateField]), 'hours')
            .subtract(this.timezoneService.getDSTTime(offer[dateField]), 'hours')
            .format('dddd, MMMM DD, YYYY h:mm a')}`;
        default:
          return 'N/A';
      }
    }
    return 'N/A';
  }
}
