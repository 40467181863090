import { createActionGroup, props } from '@ngrx/store';
import { ApplicationProfile } from '@shared/models';

enum ApplicationProfileActionTypes {
  LOAD_APPLICATION_PROFILE_SUCCESS = 'Load ApplicationProfiles Success',
  LOAD_APPLICATION_PROFILE_ERROR = 'Load ApplicationProfiles Error',
}

export const ApplicationProfileActions = createActionGroup({
  source: 'ApplicationProfile',
  events: {
    [ApplicationProfileActionTypes.LOAD_APPLICATION_PROFILE_SUCCESS]: props<{
      payload: { profile: ApplicationProfile };
    }>(),
    [ApplicationProfileActionTypes.LOAD_APPLICATION_PROFILE_ERROR]: props<{ payload: { error: string } }>(),
  },
});
