import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractDashboardDatePanelViewComponent,
  AbstractDashboardDatePanelViewComponentImports,
} from '@app/areas/dashboard/components/abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component';
import { startWith, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DashboardFormService } from '@app/areas/dashboard/services/dashboard.form.service';
import { DashboardApiService } from '@app/areas/dashboard/services/dashboard.api.service';
import moment from 'moment/moment';

@Component({
  selector: 'app-dashboard-custom-panel-view',
  templateUrl: '../abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component.html',
  styleUrls: ['../abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component.scss'],
  imports: AbstractDashboardDatePanelViewComponentImports,
  standalone: true,
  providers: [DashboardApiService],
})
export class DashboardCustomPanelViewComponent
  extends AbstractDashboardDatePanelViewComponent
  implements OnInit, OnDestroy
{
  private readonly dashboardFormService = inject(DashboardFormService);

  ngOnInit() {
    // One-off -> don't need to worry about mem leaks
    this.showCustomSearch = true;
    this.showChartData = true;
    this.subs.add(
      this.dashboardFormService.form.valueChanges
        .pipe(
          startWith(this.dashboardFormService.form.value),
          switchMap((value) => {
            let fromDate = moment(value.fromDate).format('YYYY-MM-DD');
            let toDate = moment(value.toDate).format('YYYY-MM-DD');
            return this.dashboardApiService.getCustomDailyOverviewInfo$(fromDate, toDate);
          }),
          tap((data) => this.updateDashboardData(data))
        )
        .subscribe(() => this.cdr.detectChanges())
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
