import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractDashboardDatePanelViewComponent,
  AbstractDashboardDatePanelViewComponentImports,
} from '@app/areas/dashboard/components/abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component';
import { tap } from 'rxjs';
import _ from 'lodash';

@Component({
  selector: 'app-dashboard-today-panel-view',
  templateUrl: '../abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component.html',
  styleUrls: ['../abstract-dashboard-date-panel-view/abstract-dashboard-date-panel-view.component.scss'],
  imports: AbstractDashboardDatePanelViewComponentImports,
  standalone: true,
})
export class DashboardTodayPanelViewComponent
  extends AbstractDashboardDatePanelViewComponent
  implements OnInit, OnDestroy
{
  private fromDate = this.timezoneService.moment().format('YYYY-MM-DD');
  ngOnInit() {
    this.subs.add(
      this.dashboardApiService.dailySaleOverview$
        .pipe(
          tap((data) => {
            let d = _.cloneDeep(data);
            const index = d.findIndex((x) => x.DailyDate === this.fromDate);
            if (index !== 0) {
              d = d.splice(index);
            }
            this.updateDashboardData(d);
          })
        )
        .subscribe(() => this.cdr.detectChanges())
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
