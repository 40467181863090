<div class="loyalty-overview-panel-column-data">
  <div class="loyalty-overview-panel-row-data">
    <ep-view-card class="loyalty-panel-data" [config]="configs[0]"></ep-view-card>
    <ep-view-card class="loyalty-panel-data" [config]="configs[1]"></ep-view-card>
  </div>
  <div class="loyalty-overview-panel-row-data">
    <ep-view-card class="loyalty-panel-data" [config]="configs[2]"></ep-view-card>
    <ep-view-card class="loyalty-panel-data" [config]="configs[3]"></ep-view-card>
  </div>
</div>
