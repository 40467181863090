import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnrollComponent } from './enroll.component';
import { EnrollRoutingModule } from './enroll-routing.module';
import { AuthContainerModule, IframeManagerModule, LoadingSpinnerModule } from '@ep/shared';
import {
  EnrollmentStepperComponent,
  MerchantAccountStepComponent,
  MerchantChooseProgramStepComponent,
  MerchantConfigureProgramStepComponent,
  MerchantChooseOfferStepComponent,
  MerchantConfigureOfferStepComponent,
} from '@app/enroll/components';
import {
  MerchantContactFormComponent,
  MerchantBusinessFormComponent,
  MerchantAccountFormComponent,
  LoyaltyProgramFormComponent,
  LoyaltyProgramPointsFormComponent,
  LoyaltyProgramCashbackFormComponent,
  ChooseLoyaltyProgramFormComponent,
  ChooseEnrollmentOfferFormComponent,
  ConfigureEnrollmentOfferFormComponent,
  EnrollmentOfferInstantFormComponent,
  EnrollmentOfferBonusFormComponent,
} from '@app/enroll/forms';
import { EnrollmentEmailControlComponent, EnrollmentTerminalControlComponent } from '@app/enroll/controls';
import { ReactiveFormsModule } from '@angular/forms';
import { EnrollmentAPIService, EnrollmentFormService } from '@app/enroll/services';
import { DialogModule } from 'primeng/dialog';
import { MerchantReviewQuestionStepComponent } from './components/merchant-review-question-step/merchant-review-question-step.component';
import { ReviewQuestionFormComponent } from './forms/review-question-form/review-question-form.component';
import { EnrollmentReviewFormComponent } from './forms/enrollment-review-form/enrollment-review-form.component';
import { EnrollmentSubmissionComponent } from './components/enrollment-submission/enrollment-submission.component';
import { EnrollmentCompleteComponent } from './components/enrollment-complete/enrollment-complete.component';

@NgModule({
  declarations: [EnrollComponent],
  imports: [
    CommonModule,
    EnrollRoutingModule,
    IframeManagerModule,
    AuthContainerModule,
    LoadingSpinnerModule,
    EnrollmentStepperComponent,
    MerchantAccountStepComponent,
    MerchantAccountFormComponent,
    MerchantContactFormComponent,
    MerchantBusinessFormComponent,
    ConfigureEnrollmentOfferFormComponent,
    EnrollmentEmailControlComponent,
    EnrollmentTerminalControlComponent,
    MerchantConfigureProgramStepComponent,
    MerchantChooseProgramStepComponent,
    LoyaltyProgramPointsFormComponent,
    LoyaltyProgramCashbackFormComponent,
    ChooseLoyaltyProgramFormComponent,
    MerchantChooseOfferStepComponent,
    MerchantConfigureOfferStepComponent,
    ChooseEnrollmentOfferFormComponent,
    EnrollmentOfferInstantFormComponent,
    EnrollmentOfferBonusFormComponent,
    LoyaltyProgramFormComponent,
    ReactiveFormsModule,
    DialogModule,
    MerchantReviewQuestionStepComponent,
    ReviewQuestionFormComponent,
    EnrollmentReviewFormComponent,
    EnrollmentSubmissionComponent,
    EnrollmentCompleteComponent,
  ],
  exports: [MerchantAccountStepComponent],
  providers: [EnrollmentAPIService, EnrollmentFormService],
})
export class EnrollModule {}
