import { Component, inject, OnInit } from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { OfferScheduledSmsFormGroup } from '@app/areas/offers/services/offer.form.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-offer-sms-body-form',
  templateUrl: './offer-sms-body-form.component.html',
  styleUrls: ['./offer-sms-body-form.component.scss'],
  imports: [ReactiveFormsModule, NgIf, InputTextareaModule, AsyncPipe],
  standalone: true,
})
export class OfferSmsBodyFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  protected scheduledSmsForm: OfferScheduledSmsFormGroup | null = null;

  ngOnInit() {
    this.scheduledSmsForm = this.controlContainer.control as OfferScheduledSmsFormGroup;
  }
}
