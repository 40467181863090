import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Employee } from '@app/resources/services';

const sortEmployeeById = (a: Employee, b: Employee) => {
  return a.EmployeeId - b.EmployeeId;
};

export const employeeAdapter: EntityAdapter<Employee> = createEntityAdapter<Employee>({
  sortComparer: sortEmployeeById,
  selectId: (e) => e?.EmployeeId ?? 0,
});

export const {
  selectIds: selectEmployeeIds,
  selectEntities: selectEmployeeEntities,
  selectAll: selectAllEmployees,
  selectTotal: employeesCount,
} = employeeAdapter.getSelectors();
