export enum OfferTextReminderEnum {
  NOW,
  ONE_HOUR_BEFORE,
  ONE_DAY_BEFORE,
  ONE_WEEK_BEFORE,
  CUSTOM,
}

export const OfferTextReminderMap: { [key in OfferTextReminderEnum]: string } = {
  [OfferTextReminderEnum.NOW]: <any>'Now',
  [OfferTextReminderEnum.ONE_HOUR_BEFORE]: <any>'1 hour before offer',
  [OfferTextReminderEnum.ONE_DAY_BEFORE]: <any>'1 day before offer',
  [OfferTextReminderEnum.ONE_WEEK_BEFORE]: <any>'1 week before offer',
  [OfferTextReminderEnum.CUSTOM]: <any>'Custom',
};
