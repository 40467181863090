import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Device, DeviceTracking } from '@app/resources/services';

enum DeviceActionTypes {
  UPSERT_DEVICE_SUCCESS = 'Upsert Device Success',
  UPSERT_DEVICE_ERROR = 'Upsert Device Error',
  UPDATE_DEVICE_TRACKINGS_SUCCESS = 'Update Device Trackings Success',
  UPSERT_DEVICES_SUCCESS = 'Upsert Devices Success',
  UPSERT_DEVICES_ERROR = 'Upsert Devices Error',
  LOAD_DEVICES = 'Load Devices',
  LOAD_DEVICES_SUCCESS = 'Load Devices Success',
  LOAD_DEVICES_ERROR = 'Load Devices Error',
  SELECT_DEVICE = 'Device By Id',
}

export const DeviceActions = createActionGroup({
  source: 'Device',
  events: {
    [DeviceActionTypes.LOAD_DEVICES]: emptyProps(),
    [DeviceActionTypes.LOAD_DEVICES_SUCCESS]: props<{
      payload: { devices: Device[] };
    }>(),
    [DeviceActionTypes.LOAD_DEVICES_ERROR]: props<{ payload: { error: string } }>(),
    [DeviceActionTypes.UPSERT_DEVICE_SUCCESS]: props<{
      payload: { device: Device };
    }>(),
    [DeviceActionTypes.UPSERT_DEVICE_ERROR]: props<{ payload: { error: string } }>(),
    [DeviceActionTypes.UPSERT_DEVICES_SUCCESS]: props<{
      payload: { devices: Device[] };
    }>(),
    [DeviceActionTypes.UPSERT_DEVICES_ERROR]: props<{ payload: { error: string } }>(),
    [DeviceActionTypes.SELECT_DEVICE]: props<{ payload: { deviceId: number } }>(),
    [DeviceActionTypes.UPDATE_DEVICE_TRACKINGS_SUCCESS]: props<{ payload: { deviceTrackings: DeviceTracking[] } }>(),
  },
});
