import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ViewCardComponent, ViewCardConfig } from '@ep/shared';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-dashboard-loyalty-program-panel',
  templateUrl: './dashboard-loyalty-program-panel.component.html',
  styleUrls: ['./dashboard-loyalty-program-panel.component.scss'],
  standalone: true,
  imports: [DividerModule, NgForOf, NgIf, AsyncPipe, ViewCardComponent],
})
export class DashboardLoyaltyProgramPanelComponent implements OnInit {
  @Input() data$!: Observable<ViewCardConfig[]>;

  private readonly cdr = inject(ChangeDetectorRef);
  protected configs: ViewCardConfig[] = [];

  ngOnInit() {
    this.data$.subscribe((data) => {
      this.configs = data;
      this.cdr.detectChanges();
    });
  }
}
