<div *ngIf="offer" id="offer-details-card-wrapper">
  <p-card data-cy="offer-details-card">
    <ng-template pTemplate="header">
      <div id="offer-details-card-header" data-cy="offer-details-header">
        <h3 [pTooltip]="offer.namePrivate" [tooltipPosition]="'top'">{{ offer.namePrivate }}</h3>
        <app-offer-details-header-buttons
          *ngIf="!isSmallScreen; else smallOfferDetailButtonAction"
          [isPendingTextNotSent]="isPendingTextNotSent"
          (duplicateOfferEvent)="duplicateOffer()"
          (editOfferEvent)="editOffer()"
          data-cy="offer-details-header-buttons"
        ></app-offer-details-header-buttons>
        <ng-template #smallOfferDetailButtonAction>
          <div class="overlay-table-header-actions">
            <p-overlayPanel
              #op
              [style]="{ 'min-height': '100px', display: 'flex', 'align-items': 'center' }"
              data-cy="offer-details-overlay"
            >
              <app-offer-details-header-buttons
                [isPendingTextNotSent]="isPendingTextNotSent"
                (duplicateOfferEvent)="duplicateOffer()"
                (editOfferEvent)="editOffer()"
                data-cy="offer-details-header-buttons-overlay"
              ></app-offer-details-header-buttons>
            </p-overlayPanel>
            <p-button (click)="op.toggle($event)" icon="pi pi-bars" data-cy="offer-details-overlay-actions"></p-button>
          </div>
        </ng-template>
      </div>
    </ng-template>
    <div id="offer-details-card-info">
      <div class="offer-details-card-info-row">
        <p class="offer-details-card-info-row-header"><b>Status</b></p>
        <p-tag [value]="offer | offerStatus" [severity]="offer | offerStatusSeverity" [rounded]="true"> </p-tag>
      </div>
      <div class="offer-details-card-info-row">
        <p class="offer-details-card-info-row-header"><b>Discount</b></p>
        <p>{{ getOfferDiscountAmount$() | async }}</p>
      </div>
      <div class="offer-details-card-info-row">
        <p class="offer-details-card-info-row-header"><b>Offer Duration</b></p>
        <p>{{ offer | offerFormattedDate : 'startDateTime' }} - {{ offer | offerFormattedDate : 'endDateTime' }}</p>
      </div>
      <div class="offer-details-card-info-row">
        <p class="offer-details-card-info-row-header"><b>Recipients</b></p>
        <p>Everyone</p>
      </div>
      <div class="offer-details-card-info-row">
        <p class="offer-details-card-info-row-header"><b>Text Schedule</b></p>
        <p>{{ offer | offerFormattedReminder }}</p>
        <p>{{ offer | offerFormattedDate : 'sendTime' }}</p>
      </div>
      <div class="offer-details-card-info-row">
        <p class="offer-details-card-info-row-header"><b>Text Content</b></p>
        <p>{{ offer | offerSmsBody }}</p>
      </div>
    </div>
  </p-card>
</div>
<p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
