export interface ApiResponse<T> extends BaseApiResponse {
  PageNumber: number;
  DisplayCount: number;
  TotalRecordCount: number;
  Collection: T[];
}

export interface BaseApiResponse {
  ErrorCode: number;
  ErrorMessage: string[];
  ErrorMessageCodes: any[];
  InvalidDatas: any[];
  ResponseType: number;
  ResponseMessages: any[];
  Errors: any;
}
