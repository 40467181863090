import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LoyaltyProgramMetaData } from '@app/resources/services';
export const loyaltyProgramAdapter: EntityAdapter<LoyaltyProgramMetaData> = createEntityAdapter<LoyaltyProgramMetaData>(
  {
    sortComparer: false, // we only have one loyalty program. If we need multiple, add a comparator function
    selectId: (e) => e.LoyaltyProgramId,
  }
);

export const selectLoyaltyProgramEntities = loyaltyProgramAdapter.getSelectors().selectEntities;
