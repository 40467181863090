import { Component, Input } from '@angular/core';
import { CardModule } from 'primeng/card';
import { OfferMetadata } from '@app/resources/services';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-offer-analytics-card',
  templateUrl: './offer-analytics-card.component.html',
  styleUrls: ['./offer-analytics-card.component.scss'],
  standalone: true,
  imports: [CardModule, CurrencyPipe],
})
export class OfferAnalyticsCardComponent {
  @Input() offerMetadata: OfferMetadata = {
    NumberOfTextsSent: 0,
    PercentUsed: 0,
    OfferRevenue: 0,
    AverageSpend: 0,
  };
}
