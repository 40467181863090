import { Pipe, PipeTransform } from '@angular/core';
import { mapOfferStatusToValue } from '@app/areas/offers/utils';
import { Offer } from '@app/resources/services';
/*
 * Returns a string value based on an offer display value
 *
 * Example:
 *   offer => { ..., display: '{ "en-US": "hello" }' }
 *   {{ offer | offerSmsBody }}
 *   returns: 'hello'
 */
@Pipe({
  standalone: true,
  name: 'offerSmsBody',
})
export class OfferSmsBodyPipe implements PipeTransform {
  transform(value: Offer): string {
    return JSON.parse(value.display ?? `{ "en-US": "" }`)['en-US'];
  }
}
