<div *ngIf="businessForm" class="enrollment-business-form-wrapper" data-cy="enrollment-account-business-form">
  <h5>Business Information</h5>
  <form [formGroup]="businessForm" id="enrollment-business-form">
    <ep-simple-text-control
      class="width-48"
      formControlName="BusinessName"
      label="Business Name"
      data-cy="account-business-name"
    ></ep-simple-text-control>
    <ep-international-phone-control
      class="width-48"
      formControlName="Phone"
      data-cy="account-business-phone"
    ></ep-international-phone-control>
    <ep-simple-text-control
      class="width-48"
      formControlName="Address"
      label="Address"
      errorMessage="Please enter an address"
      data-cy="account-business-address"
    ></ep-simple-text-control>
    <ep-time-zone-control
      class="width-48"
      formControlName="TimeZone"
      data-cy="account-business-time-zone"
    ></ep-time-zone-control>
  </form>
</div>
