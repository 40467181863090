import { inject, Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, Observable, of } from 'rxjs';
import { QuestionStatusTypes } from '@shared/enums';
import { I18NLanguageEnum } from '@app/i18n';
import { EnumDictionary } from '@shared/types';
import { SignalRService } from '@app/resources/services/signalr.service';

export type QuestionDisplay = EnumDictionary<I18NLanguageEnum, string>;

export interface Question {
  QuestionId: number;
  Display: string;
  StatusType: QuestionStatusTypes;
}

export interface QuestionResponse {
  questionId: number;
  display: QuestionDisplay | string;
  statusType: QuestionStatusTypes;
}

export interface QuestionsResponse {
  $id: number;
  $values: QuestionResponse[];
}

export interface CreateQuestionRequest {
  Display: string;
  StatusType: QuestionStatusTypes;
}

export interface UpdateQuestionRequest extends Question {}

export interface UpsertQuestionRequest {
  QuestionId?: number | null;
  Display?: string | null;
  StatusType?: QuestionStatusTypes | null;
}

export interface UpsertManyQuestionsRequest {
  Questions: UpsertQuestionRequest[];
  ConnectionId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends BaseApiService {
  private readonly questionEndpoint = '/v2/Questions';
  private readonly signalRService = inject(SignalRService);

  findAll(): Observable<QuestionsResponse | null> {
    return this.get<QuestionsResponse>(`${this.questionEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  findUnique(questionId: number): Observable<QuestionResponse | null> {
    return this.get<QuestionResponse>(`${this.questionEndpoint}/${questionId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  create(newQuestion: CreateQuestionRequest) {
    return this.post<QuestionResponse>(`${this.questionEndpoint}`, {
      ...newQuestion,
      ConnectionId: this.signalRService.getConnectionId(),
    });
  }

  update(updatedQuestion: UpdateQuestionRequest) {
    return this.patch<QuestionResponse>(`${this.questionEndpoint}/${updatedQuestion.QuestionId}`, {
      ...updatedQuestion,
      ConnectionId: this.signalRService.getConnectionId(),
    });
  }

  upsertMany(upsertManyQuestions: UpsertManyQuestionsRequest) {
    return this.put<QuestionsResponse>(`${this.questionEndpoint}`, {
      ...upsertManyQuestions,
      ConnectionId: this.signalRService.getConnectionId(),
    });
  }
}
