import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LoginRequest } from '@shared/models';

enum AuthenticationActionTypes {
  LOGIN = 'Authentication Login',
  LOGIN_SUCCESS = 'Authentication Login Success',
  LOGOUT = 'Authentication Logout',
  LOGOUT_SUCCESS = 'Authentication Logout Success',
  LOGOUT_ERROR = 'Authentication Logout Error',
  LOGIN_WITH_TC = 'Authentication Login With TC',
  LOGIN_WITH_TC_SUCCESS = 'Authentication Login With TC Success',
  LOGIN_ERROR = 'Authentication Login Error',
  SHOW_TERMS_AND_CONDITIONS = 'Authentication Show Terms And Conditions',
}

export const AuthenticationActions = createActionGroup({
  source: 'Authentication',
  events: {
    [AuthenticationActionTypes.LOGIN]: props<{
      payload: { request: LoginRequest };
    }>(),
    [AuthenticationActionTypes.LOGIN_SUCCESS]: emptyProps(),
    [AuthenticationActionTypes.LOGOUT]: emptyProps(),
    [AuthenticationActionTypes.LOGOUT_ERROR]: props<{ payload: { error: string } }>(),
    [AuthenticationActionTypes.LOGOUT_SUCCESS]: emptyProps(),
    [AuthenticationActionTypes.LOGIN_WITH_TC]: props<{
      payload: { request: LoginRequest };
    }>(),
    [AuthenticationActionTypes.LOGIN_WITH_TC_SUCCESS]: emptyProps(),
    [AuthenticationActionTypes.SHOW_TERMS_AND_CONDITIONS]: props<{ payload: { showTC: boolean } }>(),
    [AuthenticationActionTypes.LOGIN_ERROR]: props<{ payload: { error: string } }>(),
  },
});

export type AuthenticationLoginSuccessActionReturnType = ReturnType<
  typeof AuthenticationActions.authenticationLoginSuccess
>;
export type AuthenticationLoginErrorReturnType = ReturnType<typeof AuthenticationActions.authenticationLoginError>;
