import { Pipe, PipeTransform } from '@angular/core';
import { initializeReminder } from '@app/areas/offers/utils';
import { Offer } from '@app/resources/services';
/*
 * Returns a reminder string based on the offer startDateTime and offer sendTime
 * Takes an offer argument
 *
 * Example:
 *   offer => { ..., startDateTime: '2023-12-14 00:00:00.000', sendTime: '2023-12-13 17:00:00.000'}
 *   {{ offer | offerFormattedReminder }}
 *   returns: '1 hour before offer is valid'
 */
@Pipe({
  standalone: true,
  name: 'offerFormattedReminder',
})
export class OfferFormattedReminderPipe implements PipeTransform {
  transform(offer: Offer): string {
    const reminder = initializeReminder({
      startDateTime: offer.startDateTime,
      sendTime: offer.sendTime!,
    });

    switch (reminder.key) {
      case '1':
        return '1 hour before offer is valid';
      case '2':
        return '1 day before offer is valid';
      case '3':
        return '1 week before offer is valid';
      default:
        return 'Custom text schedule';
    }
  }
}
