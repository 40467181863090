export enum AccessType {
  DeviceAdmin = 'DeviceAdmin',
  EmployeeAdmin = 'EmployeeAdmin',
  LocationAdmin = 'LocationAdmin',
  OfferAdmin = 'OfferAdmin',
  ConsumerAccess = 'ConsumerAccess',
  ConsumerReward = 'ConsumerReward',
  SaleOverview = 'SaleOverview',
  OrderAdmin = 'OrderAdmin',
  ProductAdmin = 'ProductAdmin',
  LoyaltyProgramAdmin = 'LoyaltyProgramAdmin',
  QuestionAdmin = 'QuestionAdmin',
  BillingAdmin = 'BillingAdmin',
  SendReceiveMessages = 'SendReceiveMessages',
  TabletopAdmin = 'TabletopAdmin',
  ReportAdmin = 'ReportAdmin',
  PayAtTableFull = 'PayAtTableFull',
  PayAtTablePartial = 'PayAtTablePartial',
}
