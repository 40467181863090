import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  // UI Screen Checks.
  isXSmallScreen: boolean = false;
  isSmallScreen: boolean = false;
  isMobileScreen: boolean = false;
  shouldShowFullWidth: boolean = true;

  readonly MOBILE_BREAKPOINT = 550;
  readonly XSMALL_SCREEN_BREAKPOINT = 720;
  readonly SMALL_SCREEN_BREAKPOINT = 1200;

  constructor() {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isMobileScreen = window.innerWidth <= this.MOBILE_BREAKPOINT;
    this.isSmallScreen = window.innerWidth <= this.SMALL_SCREEN_BREAKPOINT;
    this.isXSmallScreen = window.innerWidth <= this.XSMALL_SCREEN_BREAKPOINT;
    this.shouldShowFullWidth = !this.isSmallScreen;
  }

  setShowFullWidth(showFullWidth: boolean) {
    this.shouldShowFullWidth = showFullWidth;
  }
}
