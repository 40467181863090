import moment from 'moment';
import { OfferDuration } from '@app/areas/offers/services/offer.form.service';
import { OfferStatusTypes, OfferTextReminderEnum, OfferTextReminderMap } from '@shared/enums';
import { SpecialOffer } from '@app/areas/offers/services/offer.api.service';
import { Offer } from '@app/resources/services';

export function getDayAbsDiff(date1: Date, date2: Date) {
  return Math.abs(moment(date1).startOf('day').diff(moment(date2).startOf('day'), 'days'));
}

export function isAfterNinePm(date: Date) {
  return (date.getHours() === 21 && date.getMinutes() > 0) || date.getHours() >= 22;
}

export function isDateWithinSMSWindow(date: Date) {
  return (date.getHours() == 21 && date.getMinutes() == 0) || (date.getHours() < 21 && date.getHours() >= 8);
}

/**
 * Sorting function that sorts an array of SpecialOffer objects by status type and then most recent start datetime values
 * @param a {SpecialOffer}
 * @param b {SpecialOffer}
 */
export function sortByStatusThenStartDate(a: Offer, b: Offer) {
  if (a.statusType - b.statusType !== 0) return a.statusType - b.statusType;
  return a.startDateTime < b.startDateTime ? -1 : a.startDateTime > b.startDateTime ? 1 : 0;
}

/**
 * Returns the formatted sms message to be sent out for a merchant's offer
 * @param merchantName {string} the ame of the merchant
 * @param merchantAddress {string} the merchant's location's address
 * @param dateRange {OfferDuration} the start datetime and end datetime of the offer
 * @param offerName {string} the name of the offer
 * @param discount {number} the discount percentage of the offer
 */
export function getDefaultSMS(
  merchantName: string,
  merchantAddress: string,
  dateRange: OfferDuration,
  offerName: string,
  discount: number
) {
  return `Join ${merchantName} at ${merchantAddress} ${getDates(
    dateRange
  )} for our ${offerName} offer to receive ${discount}% off on purchases.`;
}

export interface OfferReminder {
  key: string;
  value: string;
}

/**
 * Returns a formatted datetime string for the offer duration to be sent out in the sms text.
 *
 * @param dateRange {OfferDuration} an object with the offer start datetime and the offer end datetime
 */
export function getDates(dateRange: OfferDuration) {
  const start = dateRange.startDate ? new Date(dateRange.startDate) : new Date();
  const end = dateRange.endDate ? new Date(dateRange.endDate) : new Date();

  const startHour = start.getHours();
  const startMinute = start.getMinutes();
  const endHour = end.getHours();
  const endMinute = end.getMinutes();

  if (start.isSameDate(end)) {
    if (startHour === 0 && startMinute === 0 && endHour === 23 && endMinute === 59) {
      return `on ${moment(start).format('MM/DD')}`;
    } else if (endHour === 23 && endMinute === 59) {
      return `at ${moment(start).format('h:mm a [on] MM/DD')}`;
    } else if (startHour === 0 && startMinute === 0) {
      return `on ${moment(start).format('MM/DD')} until ${moment(end).format('h:mm a')}`;
    } else {
      return `from ${moment(start).format('h:mm a')} to ${moment(end).format('h:mm a')} on ${moment(start).format(
        'MM/DD'
      )}`;
    }
  } else {
    if (startHour === 0 && startMinute === 0 && endHour === 23 && endMinute === 59) {
      return `from ${moment(start).format('MM/DD')} to ${moment(end).format('MM/DD')}`;
    } else {
      return `from ${moment(start).format('MM/DD h:mm a')} to ${moment(end).format('MM/DD h:mm a')}`;
    }
  }
}

/**
 * Returns the correct reminder object based on the offer start datetime and the offer sms enqueue time.
 *
 * @param data an object that contains the offer start date time and the offer sms enqueue time
 */
export function initializeReminder(data: { startDateTime?: Date | string; sendTime?: Date | string }) {
  if (data.startDateTime && data.sendTime) {
    const start = data.startDateTime ? new Date(data.startDateTime) : new Date();
    const enqueue = data.sendTime ? new Date(data.sendTime) : new Date();

    const diffDays = getDayAbsDiff(start, enqueue);
    // Day of the week, e.g. Monday (1), Tuesday (2), ...., Sunday(7)
    const diffMinutes = moment(start).diff(moment(enqueue), 'minutes');

    if (moment(enqueue).isBefore(start)) {
      if (
        diffDays === 7 &&
        ((start.getHours() === enqueue.getHours() && start.getMinutes() === enqueue.getMinutes()) ||
          (start.getHours() < 8 && enqueue.getHours() === 8 && enqueue.getMinutes() === 0) ||
          (isAfterNinePm(start) && enqueue.getHours() === 8 && enqueue.getMinutes() === 0))
      ) {
        return {
          key: `${OfferTextReminderEnum.ONE_WEEK_BEFORE}`,
          value: `${OfferTextReminderMap[OfferTextReminderEnum.ONE_WEEK_BEFORE]}`,
        };
      } else if (
        (diffDays === 1 && start.getHours() === enqueue.getHours() && start.getMinutes() === enqueue.getMinutes()) ||
        (start.getHours() <= 8 && enqueue.getHours() === 8 && enqueue.getMinutes() === 0) ||
        (diffDays === 0 && enqueue.getHours() === 8 && enqueue.getMinutes() === 0 && isAfterNinePm(start))
      ) {
        return {
          key: `${OfferTextReminderEnum.ONE_DAY_BEFORE}`,
          value: `${OfferTextReminderMap[OfferTextReminderEnum.ONE_DAY_BEFORE]}`,
        };
      } else if (
        (diffDays === 1 && start.getHours() <= 8 && enqueue.getHours() === 21 && enqueue.getMinutes() === 0) ||
        (diffDays === 0 &&
          ((start.getHours() >= 22 && enqueue.getHours() == 21 && enqueue.getMinutes() == 0) ||
            (9 <= start.getHours() &&
              ((start.getHours() === 22 && start.getMinutes() === 0) || start.getHours() < 22) &&
              diffMinutes === 60)))
      ) {
        return {
          key: `${OfferTextReminderEnum.ONE_HOUR_BEFORE}`,
          value: `${OfferTextReminderMap[OfferTextReminderEnum.ONE_HOUR_BEFORE]}`,
        };
      }
    }
    return {
      key: `${OfferTextReminderEnum.CUSTOM}`,
      value: `${OfferTextReminderMap[OfferTextReminderEnum.CUSTOM]}`,
    };
  }

  return {
    key: `${OfferTextReminderEnum.CUSTOM}`,
    value: `${OfferTextReminderMap[OfferTextReminderEnum.CUSTOM]}`,
  };
}

export const mapOfferStatusToValue = (offer: Offer) => {
  if (offer.statusType === OfferStatusTypes.Pending) {
    return 'Pending (Text Sent)';
  } else if (offer.statusType === OfferStatusTypes.PendingNoText) {
    return 'Pending (Text Not Sent)';
  } else if (offer.statusType === OfferStatusTypes.Active) {
    return 'Active';
  } else if (offer.statusType === OfferStatusTypes.Deleted) {
    return 'Cancelled';
  } else return 'Completed';
};

export const getOfferStatusSeverity = (offer: Offer) => {
  if (offer.statusType === OfferStatusTypes.Pending || offer.statusType === OfferStatusTypes.PendingNoText) {
    return 'warning';
  } else if (offer.statusType === OfferStatusTypes.Active) {
    return 'success';
  } else if (offer.statusType === OfferStatusTypes.Suspended) {
    return 'info';
  } else return 'danger';
};
