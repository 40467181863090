import { Component, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { IframeManagerComponent } from '@ep/shared';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WindowService } from '@app/resources/services/window.service';

@UntilDestroy()
@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.scss'],
})
export class EnrollComponent implements OnInit {
  private readonly windowService = inject(WindowService);
  @ViewChild('iframeManager', { static: true }) iframeManager!: IframeManagerComponent;

  // Component State.
  isLoading: boolean = false;

  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowService.checkScreenSize();
  }

  constructor(private subdomainService: SubdomainService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.subdomainType = this.subdomainService.getDomain();

    this.isLoading = false;
  }
}
