import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { DashboardComponent } from './dashboard.component';
import { AccessType } from '@app/@shared/enums/access-types.enum';
import { AccessGuard } from '@app/@shared/guards/access.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/dashboard',
      component: DashboardComponent,
      canActivate: [AccessGuard],
      data: { title: 'Enroll And Pay', requiredAccessTypes: [AccessType.SaleOverview] },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
