import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MessagingActions } from '@app/resources/ngrx/actions';
import { MessageService } from 'primeng/api';

@Injectable()
export class MessagingEffects {
  private readonly action$ = inject(Actions);
  private readonly messageService = inject(MessageService);

  createMessage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(MessagingActions.createMessage),
        tap(({ payload }) => {
          this.messageService.add(payload.message);
        })
      ),
    {
      dispatch: false,
    }
  );
}
