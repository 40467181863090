import { Component, inject, OnInit } from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { EnrollmentAccountBusinessFormGroup } from '@app/enroll/services/enrollment-form.service';
import { NgIf } from '@angular/common';
import { InternationalPhoneControlComponent, SimpleTextControlComponent, TimeZoneControlComponent } from '@ep/shared';

@Component({
  selector: 'app-merchant-business-form',
  templateUrl: './merchant-business-form.component.html',
  styleUrls: ['./merchant-business-form.component.scss'],
  standalone: true,

  imports: [
    ReactiveFormsModule,
    NgIf,
    SimpleTextControlComponent,
    InternationalPhoneControlComponent,
    TimeZoneControlComponent,
  ],
})
export class MerchantBusinessFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  protected businessForm: EnrollmentAccountBusinessFormGroup | null = null;

  ngOnInit() {
    this.businessForm = this.controlContainer.control as EnrollmentAccountBusinessFormGroup;
  }
}
