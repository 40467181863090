<div class="content-wrapper">
  <p-card header="Change your password below" subheader="New password must be at least 8 characters">
    <form [formGroup]="fgChangePassword" (keyup.enter)="onChangePasswordClicked()">
      <div class="form-group mb-4">
        <div class="flex flex-column gap-2">
          <label class="fw-semibold" htmlFor="OldPassword">Old Password:</label>
          <p-password
            formControlName="OldPassword"
            [toggleMask]="true"
            [feedback]="false"
            [class.is-invalid]="
              fgChangePassword.get('OldPassword')?.invalid &&
              (fgChangePassword.get('OldPassword')?.dirty || fgChangePassword.get('OldPassword')?.touched)
            "
            data-cy="change-password-old"
          ></p-password>
          <div class="invalid-feedback" *ngIf="fgChangePassword.get('OldPassword')?.errors?.['required']">
            Old password is required
          </div>
          <div class="invalid-feedback" *ngIf="fgChangePassword.get('OldPassword')?.errors?.['minlength']">
            Old password must be at least 8 characters
          </div>
        </div>
      </div>

      <div class="form-group mb-4">
        <div class="flex flex-column gap-2">
          <label class="fw-semibold" htmlFor="NewPasswordA">New Password:</label>
          <p-password
            formControlName="NewPasswordA"
            [toggleMask]="true"
            [feedback]="false"
            [class.is-invalid]="
              fgChangePassword.get('NewPasswordA')?.invalid &&
              (fgChangePassword.get('NewPasswordA')?.dirty || fgChangePassword.get('NewPasswordA')?.touched)
            "
            data-cy="change-password-new-A"
          ></p-password>
          <div class="invalid-feedback" *ngIf="fgChangePassword.get('NewPasswordA')?.errors?.['required']">
            New password is required
          </div>
          <div class="invalid-feedback" *ngIf="fgChangePassword.get('NewPasswordA')?.errors?.['minlength']">
            New password must be at least 8 characters
          </div>
        </div>
      </div>

      <div class="form-group mb-4">
        <div class="flex flex-column gap-2">
          <label class="fw-semibold" htmlFor="NewPasswordB">Reenter New Password:</label>
          <p-password
            formControlName="NewPasswordB"
            [toggleMask]="true"
            [feedback]="false"
            [class.is-invalid]="fgChangePassword.hasError('passwordsNotMatch')"
            data-cy="change-password-new-B"
          ></p-password>
          <div class="invalid-feedback" *ngIf="fgChangePassword.hasError('passwordsNotMatch')">
            Passwords do not match
          </div>
        </div>
      </div>

      <div class="mb-4">
        <p-button
          label="CHANGE PASSWORD"
          [loading]="isLoading"
          (onClick)="onChangePasswordClicked()"
          data-cy="change-password-submit"
        ></p-button>
      </div>
    </form>
  </p-card>
</div>
