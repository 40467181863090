import { ColorsEnum, EmployeeAccessTypes } from '@shared/enums';
import { AdminCardConfig } from '@app/areas/admin/admin.component';

export const CARD_CONFIGS: AdminCardConfig[] = [
  {
    config: {
      header: 'Devices',
      subheader: 'Configure',
      pathToImg: '/assets/images/handshake.png',
      backgroundColor: ColorsEnum.LIGHT_BLUE,
    },
    accessType: EmployeeAccessTypes.DeviceAdmin,
    path: 'devices',
  },
  {
    config: {
      header: 'Locations',
      subheader: 'Configure',
      pathToImg: '/assets/images/line-graph.png',
      backgroundColor: ColorsEnum.MOSSY_GREEN,
    },
    accessType: EmployeeAccessTypes.LocationAdmin,
    path: 'locations',
  },
  {
    config: {
      header: 'Employees',
      subheader: 'Configure',
      pathToImg: '/assets/images/thumbs-up.png',
      backgroundColor: ColorsEnum.DOC_PURPLE,
    },
    accessType: EmployeeAccessTypes.EmployeeAdmin,
    path: 'employees',
  },
  {
    config: {
      header: 'Loyalty Program',
      subheader: 'Configure',
      pathToImg: '/assets/images/star.png',
      backgroundColor: ColorsEnum.BLUE_GREY,
    },
    accessType: EmployeeAccessTypes.LoyaltyProgramAdmin,
    path: 'loyalty-program',
  },
  {
    config: {
      header: 'Questions',
      subheader: 'Configure',
      pathToImg: '/assets/images/handshake.png',
      backgroundColor: ColorsEnum.LIGHT_BLUE,
    },
    accessType: EmployeeAccessTypes.QuestionAdmin,
    path: 'questions',
  },
  {
    config: {
      header: 'Billing',
      subheader: 'Configure',
      pathToImg: '/assets/images/handshake.png',
      backgroundColor: ColorsEnum.MOSSY_GREEN,
    },
    accessType: EmployeeAccessTypes.BillingAdmin,
    path: 'billing',
  },
];
