import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '@app/@shared';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ConfirmResetPasswordComponent } from './confirm-reset-password/confirm-reset-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { AuthContainerModule, LoadingSpinnerModule } from '@ep/shared';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    SharedModule,
    ButtonModule,
    PasswordModule,
    InputTextModule,
    CardModule,
    AuthContainerModule,
    CheckboxModule,
    LoadingSpinnerModule,
  ],
  declarations: [LoginComponent, ResetPasswordComponent, ConfirmResetPasswordComponent, ImpersonateComponent],
})
export class AuthModule {}
