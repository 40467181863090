import { createActionGroup, props } from '@ngrx/store';
import { LoyaltyProgramMetaData } from '@app/resources/services';
import { Update } from '@ngrx/entity';

enum LoyaltyProgramActionTypes {
  UPDATE_LOYALTY_PROGRAM = 'Update Loyalty Program',
  UPDATE_LOYALTY_PROGRAM_SUCCESS = 'Update Loyalty Program Success',
  UPDATE_LOYALTY_PROGRAM_ERROR = 'Update Loyalty Program Error',
  LOAD_LOYALTY_PROGRAM = 'Load Loyalty Program',
  LOAD_LOYALTY_PROGRAM_SUCCESS = 'Load Loyalty Program Success',
  LOAD_LOYALTY_PROGRAM_ERROR = 'Load Loyalty Program Error',
  SELECT_LOYALTY_PROGRAM = 'Loyalty Program By Id',
}

export const LoyaltyProgramActions = createActionGroup({
  source: 'Loyalty Program',
  events: {
    [LoyaltyProgramActionTypes.LOAD_LOYALTY_PROGRAM]: props<{ payload: { loyaltyProgram: LoyaltyProgramMetaData } }>(),
    [LoyaltyProgramActionTypes.LOAD_LOYALTY_PROGRAM_SUCCESS]: props<{
      payload: { loyaltyProgram: LoyaltyProgramMetaData };
    }>(),
    [LoyaltyProgramActionTypes.LOAD_LOYALTY_PROGRAM_ERROR]: props<{ payload: { error: string } }>(),
    [LoyaltyProgramActionTypes.UPDATE_LOYALTY_PROGRAM]: props<{
      payload: { loyaltyProgram: LoyaltyProgramMetaData };
    }>(),
    [LoyaltyProgramActionTypes.UPDATE_LOYALTY_PROGRAM_SUCCESS]: props<{
      payload: { loyaltyProgram: Update<LoyaltyProgramMetaData> };
    }>(),
    [LoyaltyProgramActionTypes.UPDATE_LOYALTY_PROGRAM_ERROR]: props<{ payload: { error: string } }>(),
    [LoyaltyProgramActionTypes.SELECT_LOYALTY_PROGRAM]: props<{ payload: { loyaltyProgramId: number } }>(),
  },
});
