import { LoginTypes } from '../enums/login-types.enum';

export interface LoginRequest {
  userName: string;
  password: string;
  clientAccessIds: string[];
  employeeId?: number;
  loginType?: LoginTypes;
  referenceIdent: string;
}
