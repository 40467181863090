import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, Observable, of } from 'rxjs';
import { EmployeeAccessTypes } from '@shared/enums';

export interface Employee {
  EmployeeId: number;
  NameFirst: string;
  NameLast: string;
  EmailAddress: string;
  PhoneNumber: string;
  AccessType: EmployeeAccessTypes;
  Pin?: string | null;
}

export interface EmployeeResponse {
  employeeId: number;
  nameFirst: string;
  nameLast: string;
  emailAddress: string;
  phoneNumber: string;
  accessType: EmployeeAccessTypes;
  pin: string;
}

export interface EmployeesResponse {
  $id: number;
  $values: EmployeeResponse[];
}

export interface CreateEmployeeRequest {
  NameLast: string;
  NameFirst: string;
  EmailAddress: string;
  PhoneNumber: string;
  AccessType?: EmployeeAccessTypes | null;
  Pin?: string | null;
}

export interface UpdateEmployeeRequest extends Employee {
  ConnectionId?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseApiService {
  private readonly employeeEndpoint = '/v2/Employees';

  findAll(): Observable<EmployeesResponse | null> {
    return this.get(`${this.employeeEndpoint}`);
  }

  findUnique(employeeId: number): Observable<EmployeeResponse | null> {
    return this.get<EmployeeResponse>(`${this.employeeEndpoint}/${employeeId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  create(newEmployee: CreateEmployeeRequest) {
    return this.post<EmployeeResponse>(`${this.employeeEndpoint}`, {
      ...newEmployee,
    });
  }

  update(updatedEmployee: UpdateEmployeeRequest) {
    // This shouldn't be a POST, but legacy is dumb and uses a POST for an update
    return this.patch<EmployeeResponse>(`${this.employeeEndpoint}/${updatedEmployee.EmployeeId}`, {
      ...updatedEmployee,
    });
  }
}
