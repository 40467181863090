<div *ngIf="scheduledSmsForm" [formGroup]="scheduledSmsForm">
  <div class="form-segment-header">
    <h5>Text Message Content</h5>
    <p class="segment-subheader">This is the message members will see when receiving an offer text</p>
    <p style="font-size: 11px !important">
      <i>(Text message will auto-populate when offer form has been filled out)</i>
    </p>
  </div>
  <div style="margin-top: 10px">
    <textarea
      rows="3"
      cols="50"
      pInputTextarea
      formControlName="SMSBody"
      [readOnly]="true"
      data-cy="offer-sms-body-control"
      [maxlength]="160"
    ></textarea>
    <p
      *ngIf="!!scheduledSmsForm.controls.SMSBody.value && !!scheduledSmsForm.controls.SMSBody.errors"
      style="font-size: 12px !important; color: red"
    >
      <i> Message limited to 160 characters. Please modify offer details to update the message size. </i>
    </p>
  </div>
</div>
