import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { PanelModule } from 'primeng/panel';
import { CalendarModule } from 'primeng/calendar';
import { DashboardFormService } from '@app/areas/dashboard/services/dashboard.form.service';
import {
  AbstractControl,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TimezoneService } from '@app/resources/services';
import { NgIf } from '@angular/common';
import { Subscription } from 'rxjs';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FONT_AWESOME_ICONS } from '@shared/icons';

@Component({
  selector: 'app-dashboard-custom-search',
  templateUrl: './dashboard-custom-search.component.html',
  styleUrls: ['./dashboard-custom-search.component.scss'],
  imports: [PanelModule, CalendarModule, ReactiveFormsModule, NgIf, FaIconComponent],
  standalone: true,
})
export class DashboardCustomSearchComponent implements OnInit, OnDestroy {
  private readonly dashboardFormService = inject(DashboardFormService);
  private readonly timezoneService = inject(TimezoneService);
  private readonly fb = inject(NonNullableFormBuilder);

  protected searchForm = this.fb.group(
    {
      fromDate: this.fb.control<string | null>(null, { validators: [Validators.required] }),
      toDate: this.fb.control<string | null>(null, { validators: [Validators.required] }),
    },
    {
      validators: [this.dateComparisonValidator()],
    }
  );
  protected maxToDate = new Date(this.timezoneService.moment().format('MM/DD/YYYY'));
  protected icons = FONT_AWESOME_ICONS;

  private subs = new Subscription();

  ngOnInit(): void {
    this.subs.add(
      this.searchForm.controls.fromDate.valueChanges.subscribe((fromDate) => {
        if (this.searchForm.controls.toDate.value === null)
          this.searchForm.controls.toDate.setValue(fromDate, { emitEvent: false });
      })
    );

    this.subs.add(
      this.searchForm.controls.toDate.valueChanges.subscribe((toDate) => {
        if (this.searchForm.controls.fromDate.value === null)
          this.searchForm.controls.fromDate.setValue(toDate, { emitEvent: false });
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  search() {
    if (this.searchForm.valid) {
      this.dashboardFormService.form.patchValue(this.searchForm.value);
    }

    this.searchForm.markAsPristine();
    this.searchForm.markAsUntouched();
  }

  private dateComparisonValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const fromDateControl = group.get('fromDate');
      const toDateControl = group.get('toDate');
      if (fromDateControl === null || toDateControl === null)
        throw new Error('Cannot find custom search form controls');

      let errors: ValidationErrors | null = null;
      if (
        !!fromDateControl.value &&
        !!toDateControl.value &&
        new Date(fromDateControl.value).getTime() > new Date(toDateControl.value).getTime()
      ) {
        errors = { invalidDateRange: true };
        fromDateControl.setErrors({ invalidDateRange: true });
      }
      return errors;
    };
  }
}
