import { Pipe, PipeTransform } from '@angular/core';
import { mapOfferStatusToValue } from '@app/areas/offers/utils';
import { Offer } from '@app/resources/services';
/*
 * Returns a string value based on an offer status type
 * Takes an offer argument
 *
 * Example:
 *   offer => { ..., offerStatusType: OfferStatusTypes.Cancelled }
 *   {{ offer | offerStatus }}
 *   returns: 'Cancelled'
 */
@Pipe({
  standalone: true,
  name: 'offerStatus',
})
export class OfferStatusPipe implements PipeTransform {
  transform(value: Offer): string {
    return mapOfferStatusToValue(value);
  }
}
