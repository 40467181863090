<div id="offer-analytics-card-wrapper">
  <p-card>
    <ng-template pTemplate="header">
      <div id="offer-analytics-card-header">
        <h3>Offer Analytics</h3>
      </div>
    </ng-template>
    <div id="offer-analytics-card-info">
      <div class="offer-analytics-card-info-row">
        <p class="offer-analytics-card-info-row-header"><b>Number of text messages sent</b></p>
        <p class="offer-analytics-card-info-row-body">{{ offerMetadata.NumberOfTextsSent }}</p>
      </div>
      <div class="offer-analytics-card-info-row">
        <p class="offer-analytics-card-info-row-header"><b>Percent of enrolled consumers who used offer</b></p>
        <p class="offer-analytics-card-info-row-body">{{ offerMetadata.PercentUsed }}%</p>
      </div>
      <div class="offer-analytics-card-info-row">
        <p class="offer-analytics-card-info-row-header"><b>Revenue generated with offer</b></p>
        <p class="offer-analytics-card-info-row-body">{{ offerMetadata.OfferRevenue | currency }}</p>
      </div>
      <div class="offer-analytics-card-info-row">
        <p class="offer-analytics-card-info-row-header"><b>Average spend per transaction with offer</b></p>
        <p class="offer-analytics-card-info-row-body">{{ offerMetadata.AverageSpend | currency }}</p>
      </div>
    </div>
  </p-card>
</div>
