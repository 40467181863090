import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { BaseApiService } from '@ep/shared';
import { DailySaleOverviewResponse } from '@shared/models/daily-sale-overview.model';
import { SignalRService } from '@app/resources/services/signalr.service';

export interface LoyaltyProgram {
  Name: string;
  JoinMessage: string;
}

export interface LoyaltyProgramPointsMetadata {
  ForEvery: number;
  EarnPoints: number;
  RewardGoal: number;
  RewardAmount: number;
}

export interface LoyaltyProgramCashBackMetadata {
  Percentage: number;
}

export interface LoyaltyProgramProgramMetadata {
  Points?: LoyaltyProgramPointsMetadata | null;
  CashBack?: LoyaltyProgramCashBackMetadata | null;
  ProgramType: number;
}

export interface LoyaltyProgramEnrollmentMetadata {
  ReceiveAmount: number;
  MaxReceiveAmount: number;
  IsInstantReward: boolean;
}

export interface LoyaltyProgramMetaData {
  LoyaltyProgramId: number;
  Information: LoyaltyProgram;
  Program: LoyaltyProgramProgramMetadata;
  Enrollment: LoyaltyProgramEnrollmentMetadata;
}

export interface LoyaltyProgramResponse {
  loyaltyProgramId: number;
  name: string;
  joinMessage: string;
}

export interface LoyaltyProgramPointsMetadataResponse {
  forEvery: number;
  earnPoints: number;
  rewardGoal: number;
  rewardAmount: number;
}

export interface LoyaltyProgramCashBackMetadataResponse {
  percentage: number;
}

export interface LoyaltyProgramProgramMetadataResponse {
  points?: LoyaltyProgramPointsMetadataResponse | null;
  cashBack?: LoyaltyProgramCashBackMetadataResponse | null;
  programType: number;
}

export interface LoyaltyProgramEnrollmentMetadataResponse {
  receiveAmount: number;
  maxReceiveAmount: number;
  isInstantReward: boolean;
}

export interface LoyaltyProgramMetaDataResponse {
  loyaltyProgramId: number;
  information: LoyaltyProgramResponse;
  program: LoyaltyProgramProgramMetadataResponse;
  enrollment: LoyaltyProgramEnrollmentMetadataResponse;
}

export interface GetDailyDashboardDataRequest {
  FromDate: string;
  ToDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramService extends BaseApiService {
  private readonly loyaltyProgramEndpoint = `/v2/LoyaltyPrograms`;
  private readonly dailyEndpoint = `${this.loyaltyProgramEndpoint}/daily`;

  private readonly signalRService = inject(SignalRService);

  findDaily(request: GetDailyDashboardDataRequest): Observable<DailySaleOverviewResponse | null> {
    return this.get<DailySaleOverviewResponse>(`${this.dailyEndpoint}`, {
      fromDate: request.FromDate,
      toDate: request.ToDate,
    }).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  findMetaData(): Observable<LoyaltyProgramMetaDataResponse | null> {
    return this.get<LoyaltyProgramMetaDataResponse>(`${this.loyaltyProgramEndpoint}/metadata`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  update(updatedLoyaltyProgram: Partial<LoyaltyProgramMetaData>): Observable<LoyaltyProgramMetaDataResponse | null> {
    return this.patch<LoyaltyProgramMetaDataResponse>(`${this.loyaltyProgramEndpoint}`, {
      ...updatedLoyaltyProgram,
      ConnectionId: this.signalRService.getConnectionId(),
    });
  }
}
