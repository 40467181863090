import { Component } from '@angular/core';
import { EnrollmentReviewFormComponent } from '@app/enroll/forms/enrollment-review-form/enrollment-review-form.component';

@Component({
  selector: 'app-merchant-review-question-step',
  templateUrl: './merchant-review-question-step.component.html',
  styleUrls: ['./merchant-review-question-step.component.scss'],
  imports: [EnrollmentReviewFormComponent],
  standalone: true,
})
export class MerchantReviewQuestionStepComponent {}
