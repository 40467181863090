import { Component, inject, OnInit } from '@angular/core';
import { ControlContainer, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnrollmentAccountContactFormGroup } from '@app/enroll/services/enrollment-form.service';
import { NgIf } from '@angular/common';
import { EnrollmentEmailControlComponent, EnrollmentTerminalControlComponent } from '@app/enroll/controls';
import { InternationalPhoneControlComponent, SimpleTextControlComponent } from '@ep/shared';

@Component({
  selector: 'app-merchant-contact-form',
  templateUrl: './merchant-contact-form.component.html',
  styleUrls: ['./merchant-contact-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    EnrollmentEmailControlComponent,
    SimpleTextControlComponent,
    NgIf,
    EnrollmentTerminalControlComponent,
    InternationalPhoneControlComponent,
  ],
})
export class MerchantContactFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  protected contactForm: EnrollmentAccountContactFormGroup | null = null;

  ngOnInit() {
    this.contactForm = this.controlContainer.control as EnrollmentAccountContactFormGroup;
  }
}
