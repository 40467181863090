import { Component, inject, OnInit } from '@angular/core';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { EnrollmentLPPointsFormGroup } from '@app/enroll/services/enrollment-form.service';
import { NgIf } from '@angular/common';
import { WindowService } from '@app/resources/services/window.service';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { PositiveIntegerControlComponent, PriceControlComponent, SimpleTextControlComponent } from '@ep/shared';
import { ENROLLMENT_LOYALTY_PROGRAM_NAME_HELP } from '@app/enroll/utils/constants';

type PointsControlHelpType = 'name' | 'qualifier' | 'earn' | 'goal' | 'reward';

@Component({
  selector: 'app-loyalty-program-points-form',
  templateUrl: './loyalty-program-points-form.component.html',
  styleUrls: ['./loyalty-program-points-form.component.scss'],
  imports: [
    NgIf,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    SimpleTextControlComponent,
    PriceControlComponent,
    PositiveIntegerControlComponent,
  ],
  standalone: true,
})
export class LoyaltyProgramPointsFormComponent implements OnInit {
  private readonly controlContainer = inject(ControlContainer);
  private readonly windowService = inject(WindowService);
  protected pointsForm: EnrollmentLPPointsFormGroup | null = null;
  protected isDialogVisible = false;

  protected nameHelpText = ENROLLMENT_LOYALTY_PROGRAM_NAME_HELP;
  private helpIndex: PointsControlHelpType = 'name';
  private helpDialog: { [index: string]: string } = {
    name: this.nameHelpText,
    qualifier:
      'This is the spend interval amount that is used to calculate the points earned. (e.g., for every $10 spent, receive 1 point)',
    earn: 'This is the number of points that the customer earns when they meet spend interval "For Every"',
    goal: 'This is the points threshold required for an enrolled member to redeem their reward',
    reward: 'This is the amount that is rewarded after the customer has reached the points goal',
  };

  ngOnInit() {
    this.pointsForm = this.controlContainer.control as EnrollmentLPPointsFormGroup;
  }

  get isXSmallScreen() {
    return this.windowService.isXSmallScreen;
  }

  protected showHelp(controlHelp: PointsControlHelpType) {
    this.helpIndex = controlHelp;
    this.isDialogVisible = true;
  }

  protected getHelpDialog() {
    return this.helpDialog[this.helpIndex];
  }
}
