import { inject, Injectable } from '@angular/core';
import { EnrollmentService, UpdateEnrollmentRequest } from '@app/resources/services/enrollment.service';

@Injectable()
export class EnrollmentAPIService {
  private readonly enrollmentService = inject(EnrollmentService);

  getEnrollmentData(enrollmentId: string) {
    return this.enrollmentService.find(enrollmentId);
  }

  updateEnrollment(updateEnrollmentRequest: UpdateEnrollmentRequest) {
    return this.enrollmentService.update(updateEnrollmentRequest);
  }

  completeEnrollment(enrollmentId: string) {
    return this.enrollmentService.complete(enrollmentId);
  }
}
