import { Component, inject } from '@angular/core';
import { EnrollmentFormService } from '@app/enroll/services/enrollment-form.service';
import { ReactiveFormsModule } from '@angular/forms';
import { LoyaltyProgramsTypesEnum } from '@shared/enums';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { FONT_AWESOME_ICONS } from '@shared/icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-choose-loyalty-program-form',
  templateUrl: './choose-loyalty-program-form.component.html',
  styleUrls: ['./choose-loyalty-program-form.component.scss'],
  imports: [ReactiveFormsModule, RadioButtonModule, NgForOf, PanelModule, CardModule, NgIf, NgClass, FaIconComponent],
  standalone: true,
})
export class ChooseLoyaltyProgramFormComponent {
  private readonly enrollmentFormService = inject(EnrollmentFormService);
  protected loyaltyProgramForm = this.enrollmentFormService.getLoyaltyProgramForm();
  protected availablePrograms = [
    {
      name: 'Cash Back',
      value: LoyaltyProgramsTypesEnum.CashBack,
      description: `
        As loyalty customers spend at your business, they can earn cash rewards on purchases.
        The amount earned on each transaction can then be available to use on future purchases.
      `,
      selector: 'cash-back',
    },
    {
      name: 'Points',
      value: LoyaltyProgramsTypesEnum.Points,
      description: `
        As loyalty customers spend at your business, they can earn points on purchases. Once they earn a certain amount of points,
        they can redeem those points in the form of a dollar amount off of a future purchase. (e.g., Once a member earns 100 points, they get $10 off on their next visit)
      `,
      selector: 'points',
    },
  ];
  protected icons = FONT_AWESOME_ICONS;

  get selectedProgram() {
    return this.loyaltyProgramForm.controls.Selected.value;
  }

  protected isCashBackProgram(value: LoyaltyProgramsTypesEnum) {
    return value === LoyaltyProgramsTypesEnum.CashBack;
  }

  protected updateSelectedProgram(value: LoyaltyProgramsTypesEnum) {
    this.loyaltyProgramForm.controls.Selected.setValue(value);
    if (value === LoyaltyProgramsTypesEnum.CashBack) {
      this.loyaltyProgramForm.controls.CashBack.enable({ emitEvent: false });
      this.loyaltyProgramForm.controls.Points.disable();
    } else if (value === LoyaltyProgramsTypesEnum.Points) {
      this.loyaltyProgramForm.controls.Points.enable({ emitEvent: false });
      this.loyaltyProgramForm.controls.CashBack.disable();
    }
  }
}
